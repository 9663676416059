/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { Box, Button, FlexContainer, theme } from "@nordcloud/gnui";
import { Maybe } from "~/tools";
import { useCloudAccountSort } from "../hooks";
import { SearchSortOrder } from "../types";
import { ActiveFilters } from "./ActiveFilters";
import { CloudAccountSort } from "./CloudAccountSort";
import { CloudAccountsSearchBox } from "./CloudAccountsSearchBox";

type Props = {
  queryChargeType: string | undefined;
  order: SearchSortOrder | undefined;
  chargeTypes: string[];
  searchInput?: Maybe<string>;
  showSort?: boolean;
  showBillingDataButton?: boolean;
  onSetSearchInput: (input: string) => void;
  openFiltersSidebar: () => void;
  openBillingDataSidebar: () => void;
  openAddNewAccountSidebar: () => void;
};

export function CloudAccountFilterBox({
  showSort,
  queryChargeType,
  order,
  chargeTypes,
  searchInput,
  showBillingDataButton,
  onSetSearchInput,
  openFiltersSidebar,
  openBillingDataSidebar,
  openAddNewAccountSidebar,
}: Props) {
  const {
    chargeType: selectedChargeType,
    sortDirection,
    onSortSelect,
    onSortOrderChange,
  } = useCloudAccountSort({ chargeType: queryChargeType, order });

  return (
    <Box boxStyle="lightGrey" spacing="spacing04">
      <FlexContainer
        justifyContent="space-between"
        alignContent="center"
        gap={theme.spacing.spacing05}
      >
        <When condition={showSort}>
          <CloudAccountSort
            sortField={selectedChargeType}
            sortDirection={sortDirection}
            chargeTypes={chargeTypes}
            onSortSelect={onSortSelect}
            onSortOrderChange={onSortOrderChange}
          />
        </When>
        <div
          css={{
            flexGrow: 1,
          }}
        >
          <CloudAccountsSearchBox
            searchInput={searchInput ?? ""}
            onSetSearchInput={onSetSearchInput}
          />
        </div>
        <Button severity="low" icon="filter" onClick={openFiltersSidebar}>
          Filters
        </Button>
        <div
          css={{
            height: "2.25rem",
            borderRight: `1px solid ${theme.color.border.border01}`,
          }}
        />
        <FlexContainer gap={theme.spacing.spacing06}>
          <When condition={showBillingDataButton}>
            <Button
              severity="low"
              icon="costSettings"
              onClick={openBillingDataSidebar}
            >
              Billing Data
            </Button>
          </When>
          <Button icon="plus" onClick={openAddNewAccountSidebar}>
            Add new Account
          </Button>
        </FlexContainer>
      </FlexContainer>
      <ActiveFilters />
    </Box>
  );
}
