/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCloudAccountCostsByChargeTypeQuery } from "~/generated/graphql";
import { Maybe } from "~/tools";
import {
  CloudProviderType,
  MovedCloudAccountTotalCosts,
  Provider,
} from "./types";
import { getTotalCost } from "./utils";

export function useGetMovedCosts(
  query: Maybe<string>,
  status: string[]
): { data: MovedCloudAccountTotalCosts; loading: boolean } {
  const { data: movedToK8sFromAws, loading: isLoadingK8sToAws } =
    useCloudAccountCostsByChargeTypeQuery({
      variables: {
        query,
        filter: {
          provider: [Provider.Aws],
          providerType: [CloudProviderType.Kubernetes],
          status,
        },
      },
    });
  const { data: movedToOcFromAws, loading: isLoadingOcFromAws } =
    useCloudAccountCostsByChargeTypeQuery({
      variables: {
        query,
        filter: {
          provider: [Provider.Aws],
          providerType: [CloudProviderType.Openshift],
          status,
        },
      },
    });

  const { data: movedToK8sFromGcp, loading: isLoadingK8sFromGcp } =
    useCloudAccountCostsByChargeTypeQuery({
      variables: {
        query,
        filter: {
          provider: [Provider.Gcp],
          providerType: [CloudProviderType.Kubernetes],
          status,
        },
      },
    });
  const { data: movedToOcFromGcp, loading: isLoadingOcFromGcp } =
    useCloudAccountCostsByChargeTypeQuery({
      variables: {
        query,
        filter: {
          provider: [Provider.Gcp],
          providerType: [CloudProviderType.Openshift],
          status,
        },
      },
    });

  const { data: movedToK8sFromAzure, loading: isLoadingK8sFromAzure } =
    useCloudAccountCostsByChargeTypeQuery({
      variables: {
        query,
        filter: {
          provider: [Provider.Azure],
          providerType: [CloudProviderType.Kubernetes],
          status,
        },
      },
    });
  const { data: movedToOcFromAzure, loading: isLoadingOcFromAzure } =
    useCloudAccountCostsByChargeTypeQuery({
      variables: {
        query,
        filter: {
          provider: [Provider.Azure],
          providerType: [CloudProviderType.Openshift],
          status,
        },
      },
    });

  const { data: movedToK8sFromIBMCloud, loading: isLoadingK8sFromIBMCloud } =
    useCloudAccountCostsByChargeTypeQuery({
      variables: {
        query,
        filter: {
          provider: [Provider.Ibmcloud],
          providerType: [CloudProviderType.Kubernetes],
          status,
        },
      },
    });
  const { data: movedToOcFromIBMCloud, loading: isLoadingOcFromIBMCloud } =
    useCloudAccountCostsByChargeTypeQuery({
      variables: {
        query,
        filter: {
          provider: [Provider.Ibmcloud],
          providerType: [CloudProviderType.Openshift],
          status,
        },
      },
    });

  const movedToK8sFromAwsTotalCost = getTotalCost(
    movedToK8sFromAws?.cloudAccountCostsByChargeType?.costsByChargeType
  );
  const movedToOcFromAwsTotalCost = getTotalCost(
    movedToOcFromAws?.cloudAccountCostsByChargeType?.costsByChargeType
  );
  const movedToK8sFromGcpTotalCost = getTotalCost(
    movedToK8sFromGcp?.cloudAccountCostsByChargeType?.costsByChargeType
  );
  const movedToOcFromGcpTotalCost = getTotalCost(
    movedToOcFromGcp?.cloudAccountCostsByChargeType?.costsByChargeType
  );
  const movedToK8sFromAzureTotalCost = getTotalCost(
    movedToK8sFromAzure?.cloudAccountCostsByChargeType?.costsByChargeType
  );
  const movedToOcFromAzureTotalCost = getTotalCost(
    movedToOcFromAzure?.cloudAccountCostsByChargeType?.costsByChargeType
  );
  const movedToK8sFromIBMCloudTotalCost = getTotalCost(
    movedToK8sFromIBMCloud?.cloudAccountCostsByChargeType?.costsByChargeType
  );
  const movedToOcFromIBMCloudTotalCost = getTotalCost(
    movedToOcFromIBMCloud?.cloudAccountCostsByChargeType?.costsByChargeType
  );

  return {
    data: {
      movedToK8sFromAwsTotalCost,
      movedToOcFromAwsTotalCost,
      movedToK8sFromGcpTotalCost,
      movedToOcFromGcpTotalCost,
      movedToK8sFromAzureTotalCost,
      movedToOcFromAzureTotalCost,
      movedToK8sFromIBMCloudTotalCost,
      movedToOcFromIBMCloudTotalCost,
    },
    loading:
      isLoadingK8sToAws ||
      isLoadingOcFromAws ||
      isLoadingK8sFromGcp ||
      isLoadingOcFromGcp ||
      isLoadingK8sFromAzure ||
      isLoadingOcFromAzure ||
      isLoadingK8sFromIBMCloud ||
      isLoadingOcFromIBMCloud,
  };
}
