/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { When } from "react-if";
import styled from "styled-components";
import { FlexContainer, SVGIcon, Switch, Text, theme } from "@nordcloud/gnui";
import { inflect } from "~/tools";
import { AccordionProps, ApplicationOption, EnvironmentOption } from "./types";

export function AppEnvSelectionAccordion({
  data,
  onAppToggle,
  onEnvToggle,
  selectedApps,
  selectedEnvs,
  disabled,
}: AccordionProps) {
  return (
    <>
      {data.map((application) => (
        <AccordionRow
          key={application.id}
          application={application}
          selectedApps={selectedApps}
          selectedEnvs={selectedEnvs}
          disabled={disabled}
          onAppToggle={onAppToggle}
          onEnvToggle={onEnvToggle}
        />
      ))}
    </>
  );
}

type AccordionRowProps = {
  application: ApplicationOption;
  selectedApps: ApplicationOption[];
  selectedEnvs: EnvironmentOption[];
  onAppToggle: (appNid: string) => void;
  onEnvToggle: (environment: EnvironmentOption) => void;
  disabled: boolean;
};

function AccordionRow({
  application,
  onAppToggle,
  onEnvToggle,
  selectedApps,
  selectedEnvs,
  disabled,
}: AccordionRowProps) {
  const [isOpen, setIsOpen] = useState(false);

  const { environments } = application;
  const envCount = environments.length;

  const isAppChecked = selectedApps.some(({ nid }) => nid === application.nid);

  return (
    <>
      <FlexContainer
        columnGap={theme.spacing.spacing04}
        padding={`0 ${theme.spacing.spacing03}`}
        css={{ borderTop: `1px solid ${theme.color.border.input}` }}
      >
        <StyledSVGIcon
          isOpen={isOpen}
          disabled={environments.length === 0}
          name="chevronDown"
          onClick={() => {
            setIsOpen((prevState) => !prevState);
          }}
        />
        <FlexContainer
          direction="column"
          alignItems="flex-start"
          p={`${theme.spacing.spacing02} 0`}
          css={{ width: "100%" }}
        >
          <Text mb={0} weight="medium">
            {application.name}
          </Text>
          <Text mb={0} size="sm" color={theme.color.text.text02}>
            {`${envCount} ${inflect("environment")(envCount)}`}
          </Text>
        </FlexContainer>
        <Switch
          checked={isAppChecked}
          disabled={disabled}
          onChange={() => {
            onAppToggle(application.nid);
          }}
        />
      </FlexContainer>
      <When condition={isOpen}>
        <EnvironmentList
          application={application}
          selectedEnvs={selectedEnvs}
          isAppChecked={isAppChecked}
          disabled={disabled}
          onEnvToggle={onEnvToggle}
        />
      </When>
    </>
  );
}

type EnvListProps = {
  application: ApplicationOption;
  selectedEnvs: EnvironmentOption[];
  isAppChecked: boolean;
  disabled: boolean;
  onEnvToggle: (environment: EnvironmentOption) => void;
};

function EnvironmentList({
  application,
  selectedEnvs,
  onEnvToggle,
  isAppChecked,
  disabled,
}: EnvListProps) {
  return (
    <>
      {application.environments.map((env) => {
        const checked =
          isAppChecked || selectedEnvs.some(({ nid }) => nid === env.nid);

        return (
          <StyledFlexContainer key={env.id}>
            <Text mb={0} pl={theme.spacing.spacing07} css={{ width: "100%" }}>
              {env.name}
            </Text>
            <Switch
              checked={checked}
              disabled={disabled}
              onChange={() => {
                onEnvToggle(env);
              }}
            />
          </StyledFlexContainer>
        );
      })}
    </>
  );
}

const StyledSVGIcon = styled(SVGIcon)<{ isOpen: boolean; disabled?: boolean }>`
  cursor: pointer;
  transition: transform 0.1s linear;
  transform: ${({ isOpen }) => (isOpen ? "rotate(0deg)" : "rotate(-90deg)")};
  visibility: ${({ disabled = false }) => (disabled ? "hidden" : "visible")};
`;

const StyledFlexContainer = styled(FlexContainer)`
  column-gap: ${theme.spacing.spacing04};
  border-top: 1px solid ${theme.color.border.input};
  padding: ${theme.spacing.spacing02} ${theme.spacing.spacing03};
  background-color: ${theme.color.background.ui03};
`;
