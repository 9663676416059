/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState, useMemo } from "react";
import { useToggle } from "react-use";
import {
  ReservationsProvider,
  ReservationsRecommendationTerm,
  ReservationsAndCommitmentsCoverageOfferingType,
  ReservationsAndCommitmentsCoverageServiceUsageType,
  ReservationsAndCommitmentsLookbackPeriod,
  useGetReservationsAndCommitmentsRecommendationsLazyQuery,
} from "~/generated/graphql";
import { percentageFormat, getMinMax } from "~/components/Charts/utils";
import { useCurrency } from "~/hooks";
import {
  formatMoney,
  convertToPercent,
  isNotNil,
  getLastItem,
  formatMoneyWithoutSymbol,
} from "~/tools";
import { BreakevenPointData } from "../types";
import { getDefaultPaymentOption } from "../utils";

type RecommendationDetailsProps = {
  resourceType: string;
  region: string;
  platform: string;
  provider: ReservationsProvider;
  term: ReservationsRecommendationTerm;
  offeringType: ReservationsAndCommitmentsCoverageOfferingType;
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType;
  timePeriod: ReservationsAndCommitmentsLookbackPeriod;
};

export function useGetRecommendationDetails({
  resourceType,
  region,
  platform,
  provider,
  offeringType,
  serviceUsageType,
  timePeriod,
}: RecommendationDetailsProps) {
  const defaultPaymentOption = getDefaultPaymentOption(provider);
  const { currency, baseCurrency } = useCurrency();
  const hasCurrencyConversion = currency !== baseCurrency;
  const [paymentOption, setPaymentOption] = useState(defaultPaymentOption);
  const [termOption, setTermOption] = useState(
    ReservationsRecommendationTerm.OneYear
  );

  const [isRecommendationSettingsOpened, toggleIsRecommendationSettingsOpened] =
    useToggle(false);

  const hideEdit = () => {
    toggleIsRecommendationSettingsOpened(false);
  };

  const [getSidebarDetails, { data, loading, error }] =
    useGetReservationsAndCommitmentsRecommendationsLazyQuery({
      variables: {
        resourceType,
        region,
        platform,
        provider,
        paymentOption,
        term: termOption,
        offeringType,
        serviceUsageType,
        lookbackPeriod: timePeriod,
      },
      fetchPolicy: "cache-and-network",
    });
  const details = data?.getReservationsAndCommitmentsRecommendations;

  const recommendationDetails = useMemo(
    () => ({
      instanceType: details?.instanceType ?? "",
      upfrontCost: formatMoney(details?.upfrontCost, currency),
      yearlySavings: formatMoney(details?.yearlySavings, currency),
      monthlySavings: formatMoney(details?.monthlySavings, currency),
      recurringMonthlyCost: formatMoney(
        details?.recurringMonthlyCost,
        currency
      ),
      monthlySavingsPercentage: percentageFormat(
        convertToPercent(details?.monthlySavingsPercentage ?? "0")
      ),
      expectedUtilization: percentageFormat(details?.expectedUtilization ?? 0),
      numberOfInstances: (details?.numberOfInstances ?? 0).toString(),
      startSavings: Number(details?.startSavings ?? "0"),
      breakEvenPointXAxis: Number(details?.breakEvenPointXAxis ?? "0"),
      breakEvenPointYAxis: Number(
        formatMoneyWithoutSymbol(
          details?.breakEvenPointYAxis ?? "0",
          currency,
          hasCurrencyConversion
        )
      ),
    }),

    [details, currency, hasCurrencyConversion]
  );

  const breakevenTimeSeries: BreakevenPointData[] = useMemo(
    () =>
      details?.timeSeries?.filter(isNotNil).map((item) => ({
        onDemandCost: formatMoneyWithoutSymbol(
          item?.onDemandCost ?? "0",
          currency,
          hasCurrencyConversion
        ).toString(),
        reservedInstanceCost: formatMoneyWithoutSymbol(
          item?.riCost ?? "0",
          currency,
          hasCurrencyConversion
        ).toString(),
        label: item?.label ?? "0",
      })) ?? [],
    [details, currency, hasCurrencyConversion]
  );

  const highestOnDemandCost = useMemo(
    () =>
      getLastItem(
        getMinMax(
          breakevenTimeSeries?.map((item) =>
            Number(
              formatMoneyWithoutSymbol(
                item?.onDemandCost ?? "0",
                currency,
                hasCurrencyConversion
              )
            )
          ) ?? []
        )
      ),
    [breakevenTimeSeries, currency, hasCurrencyConversion]
  );

  const highestLabel = useMemo(
    () =>
      getLastItem(
        getMinMax(
          breakevenTimeSeries?.map((item) => Number(item?.label ?? "0")) ?? []
        )
      ),
    [breakevenTimeSeries]
  );

  const options = {
    paymentOption,
    termOption,
    updateTermOption: setTermOption,
    updatePaymentOption: setPaymentOption,
  };

  return {
    recommendationDetails,
    sidebarLoading: loading,
    sidebarError: error,
    breakevenTimeSeries,
    highestOnDemandCost,
    highestLabel,
    isRecommendationSettingsOpened,
    serviceUsageType,
    options,
    toggleIsRecommendationSettingsOpened,
    hideEdit,
    getSidebarDetails,
    details,
  };
}
