/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useReducer } from "react";
import { When } from "react-if";
import { Button, FlexContainer, Sidebar, theme } from "@nordcloud/gnui";
import { useQueryState } from "~/hooks";
import { isNotEmpty } from "~/tools";
import { FilterWrapper } from "~/views/estate/components/Filters";
import {
  CloudAccountsQueryState,
  Provider,
  ProviderFilterValues,
  ProviderStatus,
} from "../types";
import { AccountStatusCheckboxGroup } from "./AccountStatusCheckboxGroup";
import { ProviderCheckboxGroup } from "./ProviderCheckboxGroup";
import {
  getProviderFilterValues,
  getProviderReducer,
  getStatusFilterValues,
  getStatusReducer,
} from "./utils";

type Props = {
  isFiltersSidebarOpen: boolean;
  onApplyFilters: (
    status: ProviderStatus,
    providerValues: ProviderFilterValues
  ) => void;
  onHandleClose: () => void;
  onClearFilters: () => void;
};

export function CloudAccountsFilterSidebar({
  isFiltersSidebarOpen,
  onApplyFilters,
  onHandleClose,
  onClearFilters,
}: Props) {
  const {
    state: { status, provider, providerFilter },
  } = useQueryState<CloudAccountsQueryState>();

  const statusValuesReducer = getStatusReducer(status);
  const providerValuesReducer = getProviderReducer(providerFilter);

  const [statusValues, updateStatusValues] = useReducer(
    statusValuesReducer,
    getStatusFilterValues(status)
  );

  const [providerValues, updateProviderValues] = useReducer(
    providerValuesReducer,
    getProviderFilterValues(providerFilter)
  );

  useEffect(() => {
    updateStatusValues("setValuesFromQuery");
    updateProviderValues("setValuesFromQuery");
  }, [isFiltersSidebarOpen]);

  const clearFilters = () => {
    updateStatusValues("clearFilterCheckboxes");
    updateProviderValues("clearFilterCheckboxes");
    onClearFilters();
  };

  const selectedStatusValues = Object.entries(statusValues)
    .filter(([_, value]) => value === true)
    .map(([key]) => key);
  const selectedProvidersValues = Object.entries(providerValues)
    .filter(([_, value]) => value === true)
    .map(([key]) => key);

  const isResetDisabled =
    Object.values(statusValues).every((value) => value === false) &&
    Object.values(providerValues).every((value) => value === false);

  return (
    <Sidebar
      title="Filters"
      isOpen={isFiltersSidebarOpen}
      onClick={onHandleClose}
    >
      <FlexContainer
        direction="column"
        alignItems="start"
        rowGap={theme.spacing.spacing04}
      >
        <FilterWrapper
          isExpandedInitially
          name="Account Onboarding Status"
          selectedOptions={
            isNotEmpty(selectedStatusValues) ? selectedStatusValues : undefined
          }
          onClear={() => {
            updateStatusValues("clearFilterCheckboxes");
          }}
        >
          <AccountStatusCheckboxGroup
            provider={provider}
            statusValues={statusValues}
            updateStatusValues={updateStatusValues}
          />
        </FilterWrapper>

        <When
          condition={[Provider.Kubernetes, Provider.Openshift].includes(
            provider
          )}
        >
          <FilterWrapper
            isExpandedInitially
            name="Cloud Provider"
            selectedOptions={
              selectedProvidersValues.length > 0
                ? selectedProvidersValues
                : undefined
            }
            onClear={() => {
              updateProviderValues("clearFilterCheckboxes");
            }}
          >
            <ProviderCheckboxGroup
              providerValues={providerValues}
              updateProviderValues={updateProviderValues}
            />
          </FilterWrapper>
        </When>

        <FlexContainer>
          <Button
            mr={theme.spacing.spacing02}
            icon="checkmark"
            onClick={() => onApplyFilters(statusValues, providerValues)}
          >
            Apply filters
          </Button>
          <Button
            severity="low"
            disabled={isResetDisabled}
            onClick={clearFilters}
          >
            Clear filters &amp; reload
          </Button>
        </FlexContainer>
      </FlexContainer>
    </Sidebar>
  );
}
