/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { If, Then, Else } from "react-if";
import { useToggle } from "react-use";
import { Button } from "@nordcloud/gnui";
import { useAwsBillingDataQuery } from "~/generated/graphql";
import { UniversalWrap } from "~/components";
import { SUCCESS_TEXT, ERROR_TEXT } from "~/constants";
import { showError, showSimpleInfo } from "~/services/toast";
import { BillingDataList } from "../components/BillingDataList";
import { BillingDataListItem } from "../components/BillingDataListItem";
import { useDeleteAwsBillingData } from "../hooks/useDeleteAwsBillingData";
import { AddBillingData } from "./AddBillingDataForm";

export function AwsBillingDataContent() {
  const [isAddBillingDataFormOpen, toggleIsAddBillingDataForm] =
    useToggle(false);

  const { data, loading, error } = useAwsBillingDataQuery();

  const billingData =
    data?.awsBillingData.map((item) => ({
      displayName: item?.displayName ?? "",
      nid: item?.nid ?? "",
      accountId: item?.accountId ?? "",
    })) ?? [];

  const [removeAwsBillingData] = useDeleteAwsBillingData();

  const handleDelete = async (interactionId: string) => {
    try {
      await removeAwsBillingData({ accountId: interactionId });
      showSimpleInfo(SUCCESS_TEXT.billingDataRemoved);
    } catch {
      showError(ERROR_TEXT.default);
    }
  };

  return (
    <UniversalWrap loaderProps={{ loading }} errorProps={{ error }}>
      <BillingDataList>
        <ul>
          {billingData.map(({ displayName, accountId, nid }) => (
            <BillingDataListItem
              key={accountId}
              interactionId={accountId}
              name={displayName}
              accountId={accountId}
              nid={nid}
              onDelete={handleDelete}
            />
          ))}
        </ul>
      </BillingDataList>
      <If condition={isAddBillingDataFormOpen}>
        <Then>
          <AddBillingData close={toggleIsAddBillingDataForm} />
        </Then>
        <Else>
          <Button icon="plus" onClick={toggleIsAddBillingDataForm}>
            Add Billing Data
          </Button>
        </Else>
      </If>
    </UniversalWrap>
  );
}
