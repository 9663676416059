/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

enum EstateGroupBy {
  NONE = "None",
}

export const estateGroupByOptions = Object.values(EstateGroupBy).map(
  (item) => ({
    value: item,
    label: item,
  })
);
