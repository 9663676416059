/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  FlexContainer,
  theme,
  Text,
  Button,
  Box,
  Spacer,
  Upload,
} from "@nordcloud/gnui";
import { Provider } from "~/generated/graphql";
import { ExternalLink, FormGroup } from "~/components";
import { showError } from "~/services/toast";
import { isEmpty } from "~/tools";
import { CopyBox } from "../../AddAccount/components/CopyBox";
import {
  ERROR_READ_FILE,
  readFile,
  hasFiles,
  GoogleJsonData,
} from "../../AddAccount/GcpAccountForm";
import { GcpFormField } from "./constants";
import { StepContainer } from "./StepContainer";
import { UpdateHandlerVariables } from "./types";
import { gcpCredentialsSchema } from "./validators";

type Props = {
  onClose: () => void;
  onSubmit: (variables: UpdateHandlerVariables<Provider.Gcp>) => void;
};

export function GcpUpdateCredentialsForm({ onClose, onSubmit }: Props) {
  const [filename, setFilename] = useState("");
  const formMethods = useForm<UpdateHandlerVariables<Provider.Gcp>>({
    resolver: yupResolver(gcpCredentialsSchema),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = formMethods;

  const handleUpload = async ({ key }: { key: FileList }) => {
    try {
      const json = await readFile<GoogleJsonData>(key[0]);
      const input: UpdateHandlerVariables<Provider.Gcp> = {
        key: JSON.stringify(json),
      };

      onSubmit(input);
    } catch {
      showError(ERROR_READ_FILE);
      setFilename("");
    }
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    callback: (event: unknown) => void
  ) => {
    const fileList = event.target.files;
    if (fileList instanceof FileList && hasFiles(fileList)) {
      setFilename(fileList[0].name);
    }

    callback(fileList);
  };

  const placeholder = isEmpty(filename)
    ? "Upload service account key json file"
    : filename;

  return (
    <FormProvider {...formMethods}>
      <form
        css={{ width: "100%" }}
        id="AZURE-cred-form"
        onSubmit={handleSubmit(handleUpload)}
      >
        <Text color={theme.color.text.text02} size="sm">
          To on-board GCP Organisation, please follow the next steps:
        </Text>
        <FlexContainer
          css={{ width: "100%" }}
          alignItems="flex-start"
          direction="column"
          gap={theme.spacing.spacing03}
        >
          <StepContainer
            stepNumber={1}
            title={
              <Text tag="div">
                Sign in to the{" "}
                <ExternalLink href="https://console.cloud.google.com/">
                  Google Cloud Platform
                </ExternalLink>
              </Text>
            }
          />
          <StepContainer
            stepNumber={2}
            title={
              <Text tag="div">
                Create a project for IBM&nbsp;Multicloud&nbsp;Accelerator
              </Text>
            }
          />
          <StepContainer
            stepNumber={3}
            title={
              <Text tag="div">
                Go to the{" "}
                <ExternalLink href="https://console.cloud.google.com/storage/browser">
                  Cloud Storage
                </ExternalLink>{" "}
                and Create Bucket with label:
              </Text>
            }
          >
            <Box mt={theme.spacing.spacing02} boxStyle="grey">
              <ul>
                <li>
                  <Text tag="div" weight="medium">
                    Key: nordcloud-purpose
                  </Text>
                </li>
                <li>
                  <Text tag="div" weight="medium">
                    Value: scanner
                  </Text>
                </li>
              </ul>
            </Box>
          </StepContainer>
          <StepContainer
            stepNumber={4}
            title={
              <Text>
                Go to the{" "}
                <ExternalLink href="https://console.cloud.google.com/iam-admin/serviceaccounts">
                  Service Accounts
                </ExternalLink>{" "}
                and create new Service Account
              </Text>
            }
          />
          <StepContainer
            stepNumber={5}
            title={
              <Text tag="div">
                Go to the Service Account details and create new Key
              </Text>
            }
          >
            <FormGroup error={errors[GcpFormField.KEY]}>
              <div css={{ marginTop: theme.spacing.spacing02 }}>
                <Controller
                  name={GcpFormField.KEY}
                  control={control}
                  render={({ field: { onChange } }) => (
                    <Upload
                      id={GcpFormField.KEY}
                      placeholder={placeholder}
                      onChange={(e) => handleInputChange(e, onChange)}
                    />
                  )}
                />
              </div>
            </FormGroup>
          </StepContainer>
          <StepContainer
            stepNumber={6}
            title={
              <Text tag="div">
                Go to the{" "}
                <ExternalLink href="https://console.cloud.google.com/marketplace/product/google/iamcredentials.googleapis.com?pli=1">
                  IAM
                </ExternalLink>{" "}
                and on project level add following roles to the Service Account
              </Text>
            }
          >
            <FlexContainer
              mt={theme.spacing.spacing02}
              direction="column"
              gap={theme.spacing.spacing01}
            >
              <CopyBox>Service Account Token Creator</CopyBox>
              <CopyBox>Service Usage Admin</CopyBox>
              <CopyBox>Storage Object Creator</CopyBox>
            </FlexContainer>
          </StepContainer>
          <StepContainer
            stepNumber={7}
            title={
              <Text tag="div">
                Switch to the organisation level and add following roles to the
                Service Account
              </Text>
            }
          >
            <FlexContainer
              mt={theme.spacing.spacing02}
              direction="column"
              gap={theme.spacing.spacing01}
            >
              <CopyBox>Cloud Asset Viewer</CopyBox>
              <CopyBox>Viewer</CopyBox>
              <CopyBox>Organization Viewer</CopyBox>
            </FlexContainer>
          </StepContainer>
          <StepContainer
            stepNumber={8}
            title={
              <Text tag="div">
                Make sure that the following APIs are enabled on the project
                level:
              </Text>
            }
          >
            <ul
              css={{
                listStyleType: "initial",
                marginLeft: theme.spacing.spacing04,
                paddingTop: theme.spacing.spacing04,
              }}
            >
              <li>
                <ExternalLink href="https://console.cloud.google.com/marketplace/product/google/iamcredentials.googleapis.com">
                  IAM Service Account Credentials API
                </ExternalLink>
              </li>
              <li>Service Usage API</li>
              <li>Storage API</li>
              <li>Cloud Asset API</li>
              <li>Cloud Resource Manager API</li>
              <li>Recommender API</li>
            </ul>
          </StepContainer>
        </FlexContainer>
        <Spacer height={theme.spacing.spacing06} />
        <FlexContainer gap={theme.spacing.spacing04}>
          <Button type="submit" icon="checkmark">
            Apply
          </Button>
          <Button severity="low" onClick={onClose}>
            Cancel
          </Button>
        </FlexContainer>
      </form>
    </FormProvider>
  );
}
