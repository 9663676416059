/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ApolloCache } from "@apollo/client";
import {
  CloudAccountsQuery,
  useAccountSpendsQuery,
  useCloudAccountSavingsCostsQuery,
  useCloudAccountsQuery,
} from "~/generated/graphql";
import { Maybe, isNotNil } from "~/tools";
import { CloudProviderType, ContactPeople, Person, Provider } from "../types";
import { isPossibleToUpdateCredentials } from "../utils";

export function useCloudAccountDetails(nid: string) {
  const {
    data,
    client: { cache },
    loading: isAccountsLoading,
    error: accountQueryError,
  } = useCloudAccountsQuery({
    variables: {
      limit: 1,
      filter: {
        nid,
      },
    },
  });

  const accounts = data?.cloudAccounts?.accounts ?? [];

  const {
    displayName = "",
    name = "",
    providerId = "",
    provider,
    providerType,
    description,
    contacts,
    chargeTypeCosts,
    owner,
  } = accounts[0] ?? {};

  const { data: accountSpendsData } = useAccountSpendsQuery({
    variables: { accountProviderId: providerId },
    fetchPolicy: "no-cache",
  });
  const {
    currentMonthForecastValue,
    currentMonthValue,
    previousMonthValue,
    currentMonthCo2e,
  } = accountSpendsData?.accountSpends ?? {};

  const { data: accountSavingsCostsData } = useCloudAccountSavingsCostsQuery({
    variables: { cloudProviderId: providerId },
    fetchPolicy: "no-cache",
  });
  const { cost: savings, co2e } =
    accountSavingsCostsData?.cloudAccountSavingCosts ?? {};

  const accountName = displayName || name || "";

  const showUpdateCredentials = isPossibleToUpdateCredentials(
    providerType,
    provider
  );

  const contactPeople: ContactPeople = mapContacts(contacts ?? []);

  const tags = [
    {
      color: "success",
      text: "Account",
    },
  ];

  const hideChargeTypes =
    [Provider.Hybrid].includes(provider) &&
    isNotNil(providerType) &&
    [CloudProviderType.Kubernetes, CloudProviderType.Openshift].includes(
      providerType
    );

  return {
    account: accounts[0],
    accountSpends: {
      currentMonthForecastValue: currentMonthForecastValue ?? 0,
      currentMonthValue: currentMonthValue ?? 0,
      previousMonthValue: previousMonthValue ?? 0,
      currentMonthCo2e: currentMonthCo2e ?? 0,
    },
    accountSavingsCosts: {
      cost: Number(savings ?? 0),
      co2e: Number(co2e ?? 0),
    },
    accountName,
    tags,
    provider,
    providerId,
    providerType,
    isAccountsLoading,
    accountQueryError,
    showUpdateCredentials,
    ownerData: owner
      ? { id: owner.id, name: owner.name, email: owner.email }
      : null,
    accountDescription: description ?? "",
    contactPeople,
    contacts,
    chargeTypeCosts: hideChargeTypes
      ? []
      : (chargeTypeCosts ?? []).map(({ chargeType, cost }) => ({
          chargeType,
          cost,
        })),
    cloudAccountCache: cache as ApolloCache<CloudAccountsQuery>,
  };
}

function mapContacts(contacts: Maybe<Person>[]) {
  return contacts.map((contact) => ({
    id: contact?.id ?? "",
    name: contact?.name ?? "",
    email: contact?.email ?? "",
  }));
}
