import { getUserLocalesInternal } from "~/tools";

export function formatTotalCosts(value: number, currency: string) {
  // example: 123345 -> 123.3k
  // "en-US" is used to retrieve universal abbreviation symbols e.g.
  // k - thousand
  // m - milion
  // b - bilion
  const abbreviated = new Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumFractionDigits: 1,
  })
    .format(value)
    .toLowerCase();

  // return value:
  // [
  //   { type: "integer", value: "3" },
  //   { type: "group", value: "." },
  //   { type: "integer", value: "500" },
  //   { type: "decimal", value: "," },
  //   { type: "fraction", value: "00" },
  //   { type: "literal", value: " " },
  //   { type: "currency", value: "€" },
  // ];
  const withCurrencyFormatParts = new Intl.NumberFormat(
    getUserLocalesInternal(),
    {
      style: "currency",
      currency,
      currencyDisplay: "narrowSymbol",
    }
  ).formatToParts(value);

  const currencyIndex = withCurrencyFormatParts.findIndex(
    (part) => part.type === "currency"
  );

  // Currency position varies based on user locale, it should be preserved
  return currencyIndex === 0
    ? `${withCurrencyFormatParts[currencyIndex].value}${abbreviated}`
    : `${abbreviated} ${withCurrencyFormatParts[currencyIndex].value}`;
}
