/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Maybe, isEmpty, isNil } from "~/tools";
import { HYBRID_PROVIDERS } from "./constants";
import {
  CloudSummaryTabData,
  CloudAccountsSortField,
  CloudProviderType,
  Provider,
  StatusValues,
  CloudAccountStatus,
  MovedCloudAccountTotalCosts,
  ProvidersForFiltering,
} from "./types";

export function getProviderName(name: string) {
  switch (name.toUpperCase()) {
    case Provider.Aws:
      return "AWS";
    case Provider.Azure:
      return "Azure";
    case Provider.Gcp:
      return "Google Cloud";
    case Provider.Ibmcloud:
      return "IBMCloud";
    case Provider.Kubernetes:
      return "Kubernetes";
    case Provider.Openshift:
      return "OpenShift";
    case Provider.Vmware:
      return "VMware";
    default:
      return name;
  }
}

export const getAccountFilters = (
  provider: Provider,
  providerFilter?: ProvidersForFiltering[]
) => {
  switch (provider) {
    case Provider.Aws:
    case Provider.Azure:
    case Provider.Gcp:
    case Provider.Ibmcloud:
      return { provider: [provider], providerType: [CloudProviderType.Cloud] };
    case Provider.Kubernetes:
    case Provider.Openshift:
      return {
        provider: providerFilter
          ? [...providerFilter]
          : [
              Provider.Hybrid,
              Provider.Aws,
              Provider.Azure,
              Provider.Gcp,
              Provider.Ibmcloud,
            ],
        providerType: [provider],
      };
    case Provider.Vmware:
      return {
        provider: [Provider.Hybrid],
        providerType: [provider],
      };
    default:
      return {};
  }
};

export function getAccountStatusFilter(
  provider: Provider,
  queryStatus: string[]
) {
  const emptyFilter = { status: [] };

  if (isEmpty(queryStatus)) {
    return emptyFilter;
  }

  if (HYBRID_PROVIDERS.includes(provider)) {
    return { status: queryStatus.map(mapStatusFilter) };
  }

  const hasFullConnectivity = queryStatus.includes(
    StatusValues.FullConnectivity
  );
  const hasBillingDataOnly = queryStatus.includes(StatusValues.BillingDataOnly);

  if (hasFullConnectivity && hasBillingDataOnly) {
    return emptyFilter;
  }

  if (hasFullConnectivity) {
    return { activated: true };
  }

  if (hasBillingDataOnly) {
    return { activated: false };
  }

  return emptyFilter;
}

function mapStatusFilter(queryStatus: string) {
  switch (queryStatus) {
    case StatusValues.FullConnectivity:
      return CloudAccountStatus.Active;
    case StatusValues.BillingDataOnly:
      return CloudAccountStatus.Inactive;
    case StatusValues.NoAccess:
      return CloudAccountStatus.Discovered;
    default:
      return queryStatus;
  }
}

export function getSortFieldByChargeType(chargeType?: string) {
  switch (chargeType) {
    case undefined:
    case null:
    case "Total Cost":
      return CloudAccountsSortField.TotalCost;
    default:
      return CloudAccountsSortField.ChargeType;
  }
}

export function getAccountUrl(id: string, provider: Provider | string) {
  switch (provider) {
    case Provider.Aws:
      return `https://${id}.signin.aws.amazon.com/console/`;
    case Provider.Ibmcloud:
      return `https://cloud.ibm.com`;
    case Provider.Azure:
      return `https://portal.azure.com/#resource/subscriptions/${id}`;
    case Provider.Gcp:
      return `https://console.cloud.google.com/home/dashboard?organizationId=${id}`;
    default:
      return null;
  }
}

export function isAccountDiscovered(
  providerType: CloudProviderType | "",
  status: string
) {
  const providerTypes: (CloudProviderType | "")[] = [
    CloudProviderType.Kubernetes,
    CloudProviderType.Openshift,
    CloudProviderType.Vmware,
  ];

  return (
    providerTypes.includes(providerType) &&
    status === CloudAccountStatus.Discovered
  );
}

export function isPossibleToUpdateCredentials(
  providerType?: Maybe<CloudProviderType>,
  provider?: Provider
) {
  return Boolean(
    (provider &&
      [Provider.Aws, Provider.Azure, Provider.Gcp, Provider.Ibmcloud].includes(
        provider
      )) ||
      (providerType &&
        [
          CloudProviderType.Kubernetes,
          CloudProviderType.Openshift,
          CloudProviderType.Vmware,
        ].includes(providerType))
  );
}

// used in list, based on designs seen on 2023-05-30
export function isPossibleToUpdateCredentialsV2(
  providerType?: Maybe<CloudProviderType>,
  provider?: Provider
) {
  return Boolean(
    provider &&
      [Provider.Aws, Provider.Azure, Provider.Gcp, Provider.Ibmcloud].includes(
        provider
      ) &&
      providerType &&
      providerType === CloudProviderType.Cloud
  );
}

export function sortTabData(tabData: CloudSummaryTabData[]) {
  return tabData.sort((a, b) => {
    const aCost = a.cost;
    const bCost = b.cost;

    // N/A has to be in the very back
    if (aCost === "N/A" || bCost === "N/A") {
      return aCost === "N/A" ? 1 : -1;
    }

    if (isNil(aCost) || isNil(bCost)) {
      return isNil(aCost) ? 1 : -1;
    }

    return parseFloat(bCost) - parseFloat(aCost);
  });
}

export function extractMovedCostsForProvider(
  provider: string,
  movedCosts: MovedCloudAccountTotalCosts
) {
  switch (provider) {
    case Provider.Aws:
      return {
        movedToK8s: movedCosts.movedToK8sFromAwsTotalCost,
        movedToOc: movedCosts.movedToOcFromAwsTotalCost,
      };
    case Provider.Azure:
      return {
        movedToK8s: movedCosts.movedToK8sFromAzureTotalCost,
        movedToOc: movedCosts.movedToOcFromAzureTotalCost,
      };
    case Provider.Gcp:
      return {
        movedToK8s: movedCosts.movedToK8sFromGcpTotalCost,
        movedToOc: movedCosts.movedToOcFromGcpTotalCost,
      };
    case Provider.Ibmcloud:
      return {
        movedToK8s: movedCosts.movedToK8sFromIBMCloudTotalCost,
        movedToOc: movedCosts.movedToOcFromIBMCloudTotalCost,
      };
    default:
      return {};
  }
}

export function getTotalCost(
  costsByChargeType?: Maybe<
    {
      chargeType: string;
      cost: string;
    }[]
  >
) {
  return costsByChargeType
    ?.map(({ cost }) => cost)
    ?.reduce(
      (accumulator, currentValue) => accumulator + parseFloat(currentValue),
      0
    );
}
