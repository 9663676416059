/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { v4 as uuidv4 } from "uuid";
import {
  ReservationsAndCommitmentsCoverageOfferingType,
  ReservationsAndCommitmentsCoverageServiceUsageType,
  ReservationsAndCommitmentsLookbackPeriod,
  ReservationsProvider,
  useReservationsFiltersQuery,
} from "~/generated/graphql";
import { isNotNil } from "~/tools";

type Props = {
  offeringType: ReservationsAndCommitmentsCoverageOfferingType;
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType;
  timePeriod: ReservationsAndCommitmentsLookbackPeriod;
  provider: ReservationsProvider;
};
export function useReservationsSidebarFilters({
  offeringType,
  serviceUsageType,
  timePeriod,
  provider,
}: Props) {
  const { data, loading } = useReservationsFiltersQuery({
    variables: {
      offeringType,
      serviceUsageType,
      lookbackPeriod: timePeriod,
      provider,
    },
    fetchPolicy: "cache-and-network",
  });

  const filters = data?.reservationsFilters;
  const instanceFamilies = filters?.resourcesList
    ?.filter(isNotNil)
    .map((instance) => ({
      value: instance,
      label: instance,
    }));

  const regionOptions =
    filters?.regionsList?.filter(isNotNil).map((region) => ({
      value: region,
      label: region,
    })) ?? [];

  const operatingSystems = filters?.platformsList
    ?.filter(isNotNil)
    .map((operatingSystem) => ({
      id: uuidv4(),
      value: operatingSystem,
      label: operatingSystem,
    }));

  return {
    instanceFamilies,
    regionOptions,
    operatingSystems,
    loading,
  };
}
