/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  ReservationsProvider,
  ReservationsAndCommitmentsCoverageOfferingType,
  ReservationsAndCommitmentsCoverageServiceUsageType,
} from "~/generated/graphql";
import { useFeature } from "~/hooks";
import { useCheckActiveAccount } from "../../hooks";

export function useAzureReservationsFeatureFlag(activeTab: number) {
  const showComputeFeatureFlag = useFeature("reservations-azure-compute");
  const showDatabaseFeatureFlag = useFeature("reservations-azure-database");
  const { tabsSelection, loading } = useCheckActiveAccount(
    ReservationsProvider.Azure
  );

  const tabs = tabsSelection.map((tab) => {
    switch (tab.idText) {
      case "computeAzure":
        return { ...tab, showTab: showComputeFeatureFlag };
      case "databaseAzure":
        return { ...tab, showTab: showDatabaseFeatureFlag };
      case "storage":
        return { ...tab, showTab: true };
      default:
        return { ...tab, showTab: true };
    }
  });
  const allActiveTabs = tabs.filter((tab) => tab.showTab);

  return {
    tabsSelection: allActiveTabs,
    loading,
    // Optional chaining is needed since `activeTab` can be bigger than `allActiveTabs` length
    serviceUsageType:
      allActiveTabs[activeTab]?.serviceType ??
      ReservationsAndCommitmentsCoverageServiceUsageType.Storage,
    reservationOfferingType:
      allActiveTabs[activeTab]?.offeringType ??
      ReservationsAndCommitmentsCoverageOfferingType.All,
  };
}
