/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Dispatch } from "react";
import {
  Checkbox,
  CheckboxGroup,
  FlexContainer,
  SVGIcon,
  Spacer,
  Text,
  theme,
} from "@nordcloud/gnui";
import {
  Provider,
  ProviderStatus,
  StatusFilterCheckboxProps,
  StatusValues,
} from "../types";
import { accountStatusIconProps } from "./constants";

type CheckboxGroupProps = {
  provider: Provider;
  statusValues: ProviderStatus;
  updateStatusValues: Dispatch<
    StatusValues | "clearFilterCheckboxes" | "setValuesFromQuery"
  >;
};

export function AccountStatusCheckboxGroup({
  provider,
  statusValues,
  updateStatusValues,
}: CheckboxGroupProps) {
  const checkboxes = getCheckboxes(provider);

  return (
    <>
      <Spacer height={theme.spacing.spacing03} />
      <CheckboxGroup name="status-checkbox-group">
        {checkboxes.map((checkbox) => (
          <CheckItem
            key={checkbox.status}
            checkbox={checkbox}
            statusValues={statusValues}
            updateStatusValues={updateStatusValues}
          />
        ))}
      </CheckboxGroup>
    </>
  );
}

type CheckItemProps = {
  statusValues: ProviderStatus;
  updateStatusValues: Dispatch<
    StatusValues | "clearFilterCheckboxes" | "setValuesFromQuery"
  >;
  checkbox: StatusFilterCheckboxProps;
};

function CheckItem({
  checkbox,
  statusValues,
  updateStatusValues,
}: CheckItemProps) {
  const { status, icon, color, label, description } = checkbox;

  return (
    <FlexContainer>
      <div>
        <Checkbox
          id={status}
          checked={statusValues[status]}
          onChange={() => updateStatusValues(status)}
        />
      </div>
      <FlexContainer
        columnGap={theme.spacing.spacing02}
        mb={theme.spacing.spacing04}
      >
        <SVGIcon name={icon} color={color} />
        <div>
          <Text mb={0}>{label}</Text>
          <Text size="sm" color={theme.color.text.text02} mb={0}>
            {description}
          </Text>
        </div>
      </FlexContainer>
    </FlexContainer>
  );
}

function getCheckboxes(provider: Provider) {
  switch (provider) {
    case Provider.Ibmcloud:
      return [StatusValues.NoAccess, StatusValues.FullConnectivity].map(
        (status) => accountStatusIconProps[status]
      );
    case Provider.Kubernetes:
    case Provider.Openshift:
      return [
        StatusValues.NoAccess,
        StatusValues.NoData,
        StatusValues.FullConnectivity,
      ].map((status) => accountStatusIconProps[status]);
    case Provider.Vmware:
      return [StatusValues.NoData, StatusValues.FullConnectivity].map(
        (status) => accountStatusIconProps[status]
      );
    default:
      return [
        StatusValues.NoAccess,
        StatusValues.BillingDataOnly,
        StatusValues.FullConnectivity,
      ].map((status) => accountStatusIconProps[status]);
  }
}
