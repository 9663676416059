/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Else, If, Then, When } from "react-if";
import { Text, Button } from "@nordcloud/gnui";
import {
  ReservationsAndCommitmentsCoverageOfferingType,
  ReservationsAndCommitmentsCoverageServiceUsageType,
} from "~/generated/graphql";
import { StyledRow } from "~/components/Tables/styles";
import { isNotEmpty } from "~/tools";
import { AZURE_DATABASE_PLATFORM_MAP, NESTING_LEVEL } from "../../constants";
import { useGetCoverageInformation, useGetCurrentTab } from "../../hooks";
import { UsageCoverageData } from "../../types";
import { checkIsMultiRegion } from "../../utils";
import { CoverageAndCostColumn } from "./CoverageAndCostColumn";
import { CoverageTableExpandable } from "./CoverageTableExpandable";
import { RegionText } from "./RegionText";
import { StyledTd } from "./styles";

type Props = {
  resources: UsageCoverageData;
  isParent: boolean;
  isExpanded?: boolean;
  offeringType?: ReservationsAndCommitmentsCoverageOfferingType;
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType;
  iconClick?: () => void;
  openSidebar: (rowDetails: UsageCoverageData) => void;
};

export function AzureCoverageTable({
  resources,
  isParent,
  isExpanded,
  offeringType,
  serviceUsageType,
  iconClick,
  openSidebar,
}: Props) {
  const { isReservedInstances, rowData } = useGetCoverageInformation({
    resources,
    offeringType,
    serviceUsageType,
  });
  const {
    platform,
    resource,
    potentialSavings,
    hasRecommendations,
    instanceType,
    region,
    hasCoverageResourceList,
    riCostPercent,
    spCostPercent,
    onDemandCostPercent,
  } = resources;
  const { isComputeTab, isDatabaseTab } = useGetCurrentTab(serviceUsageType);

  const instance = isComputeTab ? `${platform} - ${resource}` : resource;
  const showRecommendationButton = isReservedInstances;
  const isMultiRegionParent = checkIsMultiRegion(
    platform,
    resource,
    NESTING_LEVEL.PARENT
  );
  const isMultiRegionChild = checkIsMultiRegion(
    platform,
    resource,
    NESTING_LEVEL.CHILD
  );

  const isCheckRecommendation =
    (potentialSavings !== "0.00" &&
      !isMultiRegionParent &&
      isMultiRegionChild) ||
    (hasRecommendations && !isMultiRegionParent);
  const potentialSavingsPlaceholder =
    (!hasRecommendations && isParent) || isMultiRegionChild ? "-" : "";
  const totalOnDemandCost = isMultiRegionChild
    ? ""
    : resources.totalOnDemandCost;
  const coverageSavedCost = isMultiRegionChild
    ? ""
    : resources.coverageSavedCost;

  return (
    <StyledRow>
      <CoverageTableExpandable
        hasCoverageResourceList={hasCoverageResourceList}
        isExpanded={isExpanded}
        iconClick={iconClick}
      />

      <StyledTd hasRightBorder withPadding>
        <If condition={isParent}>
          <Then>
            <Text>
              <Text tag="span" weight="medium">
                {instance}
              </Text>{" "}
              <When condition={isDatabaseTab && isNotEmpty(platform)}>
                running{" "}
                <Text tag="span" weight="medium">
                  {AZURE_DATABASE_PLATFORM_MAP[platform]}
                </Text>{" "}
              </When>
              in{" "}
              <RegionText
                region={region}
                isMultiRegionParent={isMultiRegionParent}
                isDatabaseTab={isDatabaseTab}
              />
            </Text>
          </Then>
          <Else>
            <Text>{instanceType ?? ""}</Text>
          </Else>
        </If>
      </StyledTd>
      <CoverageAndCostColumn
        riCostPercent={riCostPercent}
        spCostPercent={spCostPercent}
        hasBorder={showRecommendationButton}
        isReservedInstances={showRecommendationButton}
        columnSize={showRecommendationButton ? 3 : 2}
        potentialSavings={potentialSavings}
        potentialSavingsPlaceholder={potentialSavingsPlaceholder}
        totalOnDemandCost={totalOnDemandCost}
        coverageSavedCost={coverageSavedCost}
        onDemandCostPercent={onDemandCostPercent}
      />
      <When condition={showRecommendationButton}>
        <StyledTd withPadding hasLeftBorder>
          <When condition={isCheckRecommendation}>
            <Button
              severity="low"
              size="md"
              css={{ width: "100%", justifyContent: "center" }}
              onClick={() => openSidebar(rowData)}
            >
              Check Recommendation
            </Button>
          </When>
        </StyledTd>
      </When>
    </StyledRow>
  );
}
