/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Case, Switch } from "react-if";
import { Sidebar } from "@nordcloud/gnui";
import { Provider } from "../types";
import { AwsBillingDataContent } from "./aws/AwsBillingDataContent";

type Props = {
  isOpen: boolean;
  close: () => void;
  provider: Provider;
};

export function BillingDataSidebar({ isOpen, close, provider }: Props) {
  return (
    <Sidebar width="45rem" title="Billing Data" isOpen={isOpen} onClick={close}>
      <SidebarContentSwitch provider={provider} />
    </Sidebar>
  );
}

function SidebarContentSwitch({ provider }: { provider: Provider }) {
  return (
    <Switch>
      <Case condition={provider === Provider.Aws}>
        <AwsBillingDataContent />
      </Case>
    </Switch>
  );
}
