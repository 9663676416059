/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { DateRange, EstatePeriod as Period } from "~/components";

type Props = {
  onChange: (selectedPeriod?: Period, selectedRange?: DateRange) => void;
};

export function usePeriodSelector({ onChange }: Props) {
  const defaultRangeLabel = "Date Range";

  const [rangeLabel, setRangeLabel] = useState(defaultRangeLabel);

  const setRange = (selectedRange: DateRange | undefined) => {
    onChange(Period.RANGE, selectedRange);
  };

  const handleClear = () => {
    setRangeLabel(defaultRangeLabel);
    onChange();
  };

  const onPeriodChange = (selectedPeriod: Period) => {
    onChange(selectedPeriod);
  };

  return {
    rangeLabel,
    setRangeLabel,
    setRange,
    handleClear,
    onPeriodChange,
  };
}
