/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useParams } from "react-router-dom";
import { Provider } from "~/generated/graphql";
import { logger } from "~/services/datadog";
import {
  getFirstItem,
  isNotEmpty,
  isNotNil,
  ResourceLifecycleDates,
  Maybe,
} from "~/tools";
import { mapMetadata, Metadata } from "../../components";
import { useEstateNidsByProviderDetails } from "../../hooks/useEstateNidsByProviderDetails";
import { useResourceLifeSpanDates } from "../../hooks/useResouceLifeSpanDates";
import { ProviderSpecificDetails } from "../../types";

const enum Tags {
  AUTO_SCALING_GROUP_NAME = "aws:autoscaling:groupName",
}

type LifeSpanDates = {
  firstSeenDate: string;
  lastSeenDate: string;
};

export function useEc2InstanceDetails(
  metadata: Metadata[],
  metatags: Metadata[],
  resourceLifecycleDates: ResourceLifecycleDates | undefined
) {
  const mapped = mapMetadata<MappedMetadata & MappedNetworking & MappedStorage>(
    metadata
  );

  const lifeSpanDates = useResourceLifeSpanDates({
    lastUpdateDate: mapped.lastUpdateDate ?? "",
    resourceLifecycleDates,
  });

  const mappedTags = Object.fromEntries(
    Object.entries(mapMetadata<MappedTags>(metatags)).map(([label, value]) =>
      label === Tags.AUTO_SCALING_GROUP_NAME
        ? ["autoScalingGroupName", value]
        : [label, value]
    )
  );

  const { id } = useParams<Params>();
  const resultWithoutNids = {
    instance: toInstanceDetails(mapped, mappedTags, lifeSpanDates),
    networking: toNetworkingDetails(mapped),
    storage: toStorageDetails(mapped, id),
  };

  const nidQueries: ProviderSpecificDetails[] = [
    {
      providerId: resultWithoutNids.instance.vpcId,
      typeSubtype: "ec2/vpc",
    },
    {
      providerId: resultWithoutNids.instance.subnetId,
      typeSubtype: "ec2/subnet",
    },
    ...resultWithoutNids.networking.networkInterfaces.flatMap(
      ({ networkInterfaceId, vpcId, groupId, subnetId }) => {
        return [
          {
            providerId: networkInterfaceId,
            typeSubtype: "ec2/network-interface",
          },
          {
            providerId: vpcId,
            typeSubtype: "ec2/vpc",
          },
          {
            providerId: groupId,
            typeSubtype: "ec2/security-group",
          },
          {
            providerId: subnetId,
            typeSubtype: "ec2/subnet",
          },
        ];
      }
    ),
    ...resultWithoutNids.storage.deviceMappings.map(({ volumeId }) => ({
      providerId: volumeId,
      typeSubtype: "ec2/volume",
    })),
  ].filter(({ providerId }) => isNotEmpty(providerId));

  const providerIdsToNids = useEstateNidsByProviderDetails(
    Provider.Aws,
    nidQueries
  );

  return {
    instance: {
      ...resultWithoutNids.instance,
      vpcNid: providerIdsToNids[resultWithoutNids.instance.vpcId] ?? "",
      subnetNid: providerIdsToNids[resultWithoutNids.instance.subnetId] ?? "",
    },
    networking: {
      ...resultWithoutNids.networking,
      networkInterfaces: resultWithoutNids.networking.networkInterfaces.map(
        (netInterface) => ({
          ...netInterface,
          networkInterfaceNid:
            providerIdsToNids[netInterface.networkInterfaceId] ?? "",
          vpcNid: providerIdsToNids[netInterface.vpcId] ?? "",
          groupNid: providerIdsToNids[netInterface.groupId] ?? "",
          subnetNid: providerIdsToNids[netInterface.subnetId] ?? "",
        })
      ),
    },
    storage: {
      ...resultWithoutNids.storage,
      deviceMappings: resultWithoutNids.storage.deviceMappings.map(
        (mapping) => ({
          ...mapping,
          volumeNid: providerIdsToNids[mapping.volumeId] ?? "",
        })
      ),
    },
  };
}

function toInstanceDetails(
  mapped: Partial<MappedMetadata>,
  mappedTags: MappingObject,
  lifeSpanDates: LifeSpanDates
) {
  const resourceType = mapped.instanceType || mapped.savingPlanUsageType;

  return {
    platform: mapped.image?.value?.platform ?? "",
    platformDetails: mapped.platformDetails ?? "",
    instanceType: resourceType ?? "",
    availabilityZone: mapped.placement?.availabilityZone ?? "",
    vpcId: mapped.vpcId ?? "",
    subnetId: mapped.subnetId ?? "",
    publicIPv4: mapped.publicIpAddress ?? "",
    publicElasticIPAddress:
      mapped.elasticIps?.map((item) => item.publicIp) ?? [],
    privateElasticIPAddress:
      mapped.elasticIps?.map((item) => item.privateIpAddress) ?? [],
    autoScalingGroupName: mappedTags.autoScalingGroupName ?? "",
    firstSeen: lifeSpanDates.firstSeenDate,
    lastSeen: lifeSpanDates.lastSeenDate,
  };
}

function toNetworkingDetails(mapped: Partial<MappedNetworking>) {
  const networkInterfaces = Array.isArray(mapped.networkInterfaces)
    ? mapped.networkInterfaces
    : [];

  return {
    publicIPv4: mapped.publicIpAddress ?? "",
    publicIPv4DNS: mapped.publicDnsName ?? "",
    privateIPv4: mapped.privateIpAddress ?? "",
    privateIPv4DNS: mapped.privateDnsName ?? "",
    ipv6Addresses: getIPv6Addresses(networkInterfaces),
    ipv4Addresses: getIPv4Addresses(networkInterfaces),
    networkInterfaces: getNetworkInterfaces(networkInterfaces),
  };
}

function toStorageDetails(mapped: Partial<MappedStorage>, id: string) {
  const deviceMappings = getBlockDeviceMappings(
    mapped.blockDeviceMappings,
    mapped.image,
    () =>
      logger?.warn("Using non-enhanced metadata in estate record", {
        id,
      })
  );

  return {
    rootDeviceType: mapped.rootDeviceType ?? "",
    rootDeviceName: mapped.rootDeviceName ?? "",
    deviceMappings,
  };
}

function getNetworkInterfaces(networkInterfaces: NetworkInterface[]) {
  return networkInterfaces.map((networkInterface) => ({
    networkInterfaceId: networkInterface.networkInterfaceId ?? "",
    publicIp: networkInterface.association?.publicIp ?? "",
    privateIpAddress: networkInterface.privateIpAddress ?? "",
    status: networkInterface.attachment?.status ?? "",
    vpcId: getFirstItem(networkInterface.securityGroups ?? [])?.vpcId ?? "",
    groupId: getFirstItem(networkInterface.securityGroups ?? [])?.groupId ?? "",
    subnetId: networkInterface.subnetId ?? "",
  }));
}

function getIPv6Addresses(networkInterfaces: NetworkInterface[]) {
  return (
    networkInterfaces.flatMap(
      (networkInterface) =>
        networkInterface?.ipv6Addresses?.map(
          ({ ipv6Address }) => ipv6Address
        ) ?? []
    ) ?? []
  ).filter(isNotNil);
}

function getIPv4Addresses(networkInterfaces: NetworkInterface[]) {
  return (
    networkInterfaces.flatMap(
      (networkInterface) =>
        networkInterface?.privateIpAddresses?.map(
          ({ privateIpAddress }) => privateIpAddress
        ) ?? []
    ) ?? []
  ).filter(isNotNil);
}

function mapEnhancedDeviceMapping(
  device: EnhancedDeviceMapping,
  image: Image | undefined
) {
  return (
    device.attachments?.map((attachment) => ({
      deviceName: attachment.device,
      volumeSize:
        image?.blockDeviceMappings.find(
          ({ deviceName }) => deviceName === attachment.device
        )?.ebs?.volumeSize ?? null,
      status: attachment.state,
      volumeId: attachment.volumeId,
      attachTime: attachment.attachTime,
      deleteOnTermination: attachment.deleteOnTermination,
    })) ?? []
  );
}

function mapDeviceMapping(device: DeviceMapping, image: Image | undefined) {
  const name = device.deviceName;
  const size =
    image?.blockDeviceMappings.find(({ deviceName }) => deviceName === name)
      ?.ebs?.volumeSize ?? null;

  const { ebs } = device;

  return {
    deviceName: name,
    ...ebs,
    volumeSize: size,
  };
}

function getBlockDeviceMappings(
  blockDeviceMappings: MappedStorage["blockDeviceMappings"] | undefined,
  image: Image | undefined,
  missingAttachmentsCallback: () => void
) {
  return (
    blockDeviceMappings?.map((device) => {
      if ("attachments" in device) {
        return mapEnhancedDeviceMapping(device, image);
      }

      missingAttachmentsCallback();
      return mapDeviceMapping(device, image);
    }) ?? []
  )
    .flat(1)
    .filter((item) => isNotNil(item.volumeId));
}

type MappedMetadata = {
  platform: string;
  platformDetails: string;
  instanceType: string;
  savingPlanUsageType: string;
  vpcId: string;
  subnetId: string;
  publicIpAddress: string;
  elasticIps: {
    publicIp: string;
    privateIpAddress: string;
  }[];
  launchTime: string;
  lastUpdateDate: string;
  placement: {
    availabilityZone: string;
  };
  image: {
    value: {
      platform: string | null;
    };
  };
};

type MappedTags = {
  autoScalingGroupName: string;
};

type MappedNetworking = {
  publicIpAddress: string;
  publicDnsName: string;
  privateIpAddress: string;
  privateDnsName: string;
  ipv6Address: string;
  // can take "-" value from metadata parser if the value is "null" src/views/estate/EstateDetailsPage/utils.ts
  networkInterfaces: NetworkInterface[] | string;
};

type NetworkInterface = {
  networkInterfaceId: string | undefined;
  association:
    | {
        publicIp: string | undefined;
      }
    | undefined;
  privateIpAddress: string | undefined;
  attachment:
    | {
        status: string;
      }
    | undefined;
  securityGroups:
    | {
        vpcId: string | undefined;
        groupId: string | undefined;
      }[]
    | undefined;
  subnetId: string | undefined;
  ipv6Addresses:
    | {
        ipv6Address: string | undefined;
      }[]
    | undefined;
  privateIpAddresses:
    | {
        privateIpAddress: string | undefined;
      }[]
    | undefined;
};

type Params = {
  id: string;
};

type MappedStorage = {
  rootDeviceName: string;
  rootDeviceType: string;
  blockDeviceMappings: DeviceMapping[] | EnhancedDeviceMapping[];
  image: Image;
};

type DeviceMapping = {
  ebs: {
    status: string;
    volumeId: string;
    attachTime: string;
    deleteOnTermination: boolean;
  };
  deviceName: string;
};

type EnhancedDeviceMapping = {
  attachments:
    | {
        device: string;
        state: string;
        attachTime: string;
        volumeId: string;
        deleteOnTermination: boolean;
      }[]
    | null;
};

type Image = {
  blockDeviceMappings: {
    deviceName: string;
    ebs: Maybe<{
      volumeSize?: number;
    }>;
  }[];
};

type MappingObject = {
  [k: string]: string;
};
