/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import { isApolloError } from "@apollo/client";
import { UnpackNestedValue } from "react-hook-form";
import {
  useExecuteWorkflowMutation,
  useActivateWorkflowMutation,
  useRemoveWorkflowMutation,
  useCloneWorkflowMutation,
  CloneWorkflowInput,
  useGetWorkflowHistoryQuery,
  useGetWorkflowHistoryEventLazyQuery,
  WorkflowSortingFields,
  WorkflowSortingOrder,
  WorkflowEventInListSortingFields,
  useHistoryEventsQuery,
  WorkflowEventStatus,
  GetWorkflowListQuery,
  GetWorkflowListQueryVariables,
  GetWorkflowListDocument,
} from "~/generated/graphql";
import { ERROR_TEXT } from "~/constants";
import { useConfirmAction, useQueryState, useDisclosure } from "~/hooks";
import { showError, showSuccess } from "~/services/toast";
import {
  generateActionSuccessText,
  removeFromApolloCache,
  isNotNil,
} from "~/tools";
import {
  getCloneWorkflowUserErrors,
  getActivateWorkflowUserError,
} from "./errors";
import { WorkflowCloneProps, WorkflowInfo } from "./types";
import {
  getActivateConfirmContentText,
  getForceRunConfirmContentText,
  getDeleteConfirmContentText,
} from "./utils";
import { FormData } from "./WorkflowDetails/formConfig";

const showToastError = (err: unknown) => {
  if (err instanceof Error) {
    showError(err.message);
  }
};

export function useExecuteWorkflowAction() {
  const [executeWorkflow] = useExecuteWorkflowMutation({
    refetchQueries: ["getWorkflowHistory"],
  });

  return useConfirmAction<WorkflowInfo>({
    name: "force run",
    action: async ({ id, name }) => {
      try {
        await executeWorkflow({ variables: { id } });
        showSuccess(
          generateActionSuccessText(`The ${name}`)()("started successfully")()
        );
      } catch (err) {
        showToastError(err);
      }
    },
    contentText: (_, workflow) => getForceRunConfirmContentText(workflow),
    actionLabel: "Force Run",
    contentLabel: "Force Run Workflow",
  });
}

export function useActivateWorkflowAction(
  setHeaderError: (err?: JSX.Element) => void
) {
  const [activateWorkflow] = useActivateWorkflowMutation();

  return useConfirmAction<WorkflowInfo>({
    name: "activate",
    action: async ({ id, name, active }) => {
      try {
        const response = await activateWorkflow({
          variables: {
            id,
            active: !active,
          },
          update: (cache) => {
            cache.modify({
              id: `WorkflowListItem:${id}`,
              fields: {
                active() {
                  return !active;
                },
              },
            });
          },
        });

        if (response.data?.activateWorkflowV2) {
          const { userErrors } = response.data.activateWorkflowV2;
          if (userErrors) {
            setHeaderError(getActivateWorkflowUserError(userErrors));
          } else {
            showSuccess(
              generateActionSuccessText(name)()(
                active ? "deactivated" : "activated"
              )()
            );
          }
        }
      } catch (err) {
        showToastError(err);
      }
    },
    contentText: (_, workflow) => getActivateConfirmContentText(workflow),
    actionLabel: (_, workflow) =>
      workflow?.active ? "Deactivate" : "Activate",
    contentLabel: (_, workflow) =>
      `${workflow?.active ? "Deactivate" : "Activate"} Workflow`,
  });
}

export function useRemoveWorkflowAction(onComplete?: () => void) {
  const [removeWorkflowMutation] = useRemoveWorkflowMutation();

  return useConfirmAction<WorkflowInfo>({
    name: "delete workflow",
    action: async ({ id, name }) => {
      try {
        await removeWorkflowMutation({
          variables: { id },
          update: (cache) =>
            removeFromApolloCache(cache, { item: `WorkflowListItem:${id}` }),
        });
        showSuccess(generateActionSuccessText(`${name}`)()("deleted")());
        onComplete?.();
      } catch (err) {
        if (err instanceof Error) {
          showError(err.message);
        }
      }
    },
    contentText: (_, workflow) => getDeleteConfirmContentText(workflow),
    actionLabel: "Delete",
    contentLabel: "Delete Workflow",
  });
}

export function useCloneWorkflowAction(
  setHeaderError: (err?: JSX.Element) => void,
  onComplete?: (id?: string | null) => void
) {
  const [loading, setLoading] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [data, setData] = React.useState<WorkflowCloneProps>();
  const { sortField, sortOrder } = useWorkflowsListSort();

  const onClick = ({
    id,
    name,
    description,
    contactPersons,
    active,
  }: WorkflowCloneProps) => {
    setIsOpen(true);
    setData({ id, name, description, contactPersons, active });
  };

  const [cloneWorkflowMutation] = useCloneWorkflowMutation({
    update: (cache, { data: mutationResponse }) => {
      try {
        const clonedWorkflow = mutationResponse?.cloneWorkflow;
        const newWorkflows = clonedWorkflow
          ? [
              {
                id: clonedWorkflow.id,
                name: clonedWorkflow.name,
                description: clonedWorkflow.description,
                active: clonedWorkflow.active,
                contactPersons: clonedWorkflow.contactPersons,
                createdAt: clonedWorkflow.createdAt,
                lastRunDate: clonedWorkflow.lastRunDate,
                lastRunStatus: clonedWorkflow.lastRunStatus,
                triggers: clonedWorkflow.triggers,
              },
            ]
          : [];

        cache.updateQuery<GetWorkflowListQuery, GetWorkflowListQueryVariables>(
          {
            query: GetWorkflowListDocument,
            variables: {
              field: sortField,
              order: sortOrder,
            },
          },
          (getWorkflowList) => ({
            workflows: [...newWorkflows, ...(getWorkflowList?.workflows ?? [])],
          })
        );
      } catch {
        showError(ERROR_TEXT.default);
      }
    },
  });
  const getInput = (
    input: UnpackNestedValue<FormData>
  ): CloneWorkflowInput => ({
    name: input.name,
    description: input.description,
    contactPersonIds: input.contactPersonIds.map(({ value }) => value),
    active: input.active,
  });

  const onSubmit = async (input: UnpackNestedValue<FormData>) => {
    try {
      if (data != null) {
        setIsOpen(false);
        setLoading(true);
        const cloneWorkflowResult = await cloneWorkflowMutation({
          variables: {
            input: getInput(input),
            id: data.id,
          },
        });

        showSuccess(generateActionSuccessText(`${input.name}`)()("cloned")());
        onComplete?.(cloneWorkflowResult.data?.cloneWorkflow?.id);
        setLoading(false);
      }
    } catch (error) {
      if (isApolloError(error)) {
        setHeaderError(getCloneWorkflowUserErrors(error));
        showError(error.message ?? ERROR_TEXT.default);
        setLoading(false);
        return;
      }

      if (error instanceof Error) {
        showError(error);
      }

      setLoading(false);
    }
  };

  const modalProps = {
    isOpen,
    setIsOpen,
    onSubmit,
    data,
  };

  return {
    modalProps,
    loading,
    onClick,
  };
}

type WorkflowHistoryPaginationProps = {
  workflowId?: string;
  sortField?: WorkflowEventInListSortingFields;
  sortOrder?: WorkflowSortingOrder;
  status?: WorkflowEventStatus;
};

export function useWorkflowHistoryPagination({
  workflowId,
  sortField,
  sortOrder,
  status,
}: WorkflowHistoryPaginationProps) {
  const firstPage = -1;
  const pageLimits = [10, 20, 50];

  const {
    state: { limit: queryLimit },
    set: setQueryState,
  } = useQueryState();

  const [currentCursor, setCurrentCursor] = React.useState(firstPage);
  const [cursorList, setCursorList] = React.useState<string[]>([]);
  const [historyItemsPerPage, setHistoryItemsPerPage] = React.useState(
    queryLimit ?? pageLimits[1]
  );
  const cursorForQuery =
    currentCursor > firstPage ? cursorList[currentCursor] : null;

  const {
    data: detailsListData,
    loading: detailsListLoading,
    error: detailsListError,
  } = useGetWorkflowHistoryQuery({
    variables: {
      limit: historyItemsPerPage,
      workflowId,
      cursor: cursorForQuery,
      status,
    },
    onCompleted: ({ workflowHistoryEvents }) => {
      const newCursor = workflowHistoryEvents?.cursor;
      if (newCursor != null && !cursorList.includes(newCursor)) {
        setCursorList([...cursorList, newCursor]);
      }
    },
    skip: workflowId === undefined,
    fetchPolicy: "no-cache",
  });

  const {
    data: allWorkflowsData,
    loading: allWorkflowsLoading,
    error: allWorkflowsError,
  } = useHistoryEventsQuery({
    variables: {
      limit: historyItemsPerPage,
      cursor: cursorForQuery,
      field: sortField,
      order: sortOrder,
    },
    onCompleted: ({ historyEvents }) => {
      const newCursor = historyEvents?.cursor;
      if (newCursor != null && !cursorList.includes(newCursor)) {
        setCursorList([...cursorList, newCursor]);
      }
    },
    skip: workflowId !== undefined,
    fetchPolicy: "no-cache",
  });

  const events =
    detailsListData?.workflowHistoryEvents?.events ??
    allWorkflowsData?.historyEvents?.events;
  const cursor =
    detailsListData?.workflowHistoryEvents?.cursor ??
    allWorkflowsData?.historyEvents?.cursor;

  const setHistoryItemsLimit = (limit: number) => {
    setHistoryItemsPerPage(limit);
    setQueryState("limit")(limit);
    setCursorList([]);
    setCurrentCursor(firstPage);
  };

  return {
    setHistoryItemsLimit,
    prev: () => {
      setCurrentCursor((prev) => prev - 1);
    },
    next: () => {
      setCurrentCursor((prev) => prev + 1);
    },
    disablePrev: currentCursor <= firstPage,
    disableNext: cursor == null,
    selectedLimit: historyItemsPerPage,
    data: events ?? [],
    loading: detailsListLoading || allWorkflowsLoading,
    error: detailsListError ?? allWorkflowsError,
    pageLimits,
  };
}

export function useWorkflowEventDetails() {
  const { isOpen, close, open } = useDisclosure();

  const {
    state: { eventId },
    set,
    removeFields,
  } = useQueryState();

  const parameterName = "eventId";

  const [getHistoryQuery, { data, loading, error }] =
    useGetWorkflowHistoryEventLazyQuery();

  const setCurrentEvent = (eventIdToSet: string) => {
    set(parameterName)(eventIdToSet);
    open();

    getHistoryQuery({
      variables: {
        id: eventIdToSet,
      },
    });
  };

  const handleClose = () => {
    close();
    removeFields([parameterName]);
  };

  React.useEffect(() => {
    if (isNotNil(eventId) && isOpen === false) {
      setCurrentEvent(eventId.toString());
    }
  });

  return {
    isOpen,
    setCurrentEvent,
    handleClose,
    data,
    loading,
    error,
  };
}

type WorkflowsListQueryState = {
  workflowsField?: WorkflowSortingFields;
  workflowsOrder?: WorkflowSortingOrder;
};

export function useWorkflowsListSort() {
  const {
    state: { workflowsField, workflowsOrder },
    set: setQueryState,
  } = useQueryState<WorkflowsListQueryState>();
  const [sortField, setSortField] = React.useState<WorkflowSortingFields>(
    workflowsField ?? WorkflowSortingFields.LastRunDate
  );
  const [sortOrder, setSortOrder] = React.useState<WorkflowSortingOrder>(
    workflowsOrder ?? WorkflowSortingOrder.Desc
  );

  const handleSortFieldChange = (field: WorkflowSortingFields) => {
    setQueryState("workflowsField")(field);
    setSortField(field);
  };

  const handleSortOrderChange = (order: WorkflowSortingOrder) => {
    setQueryState("workflowsOrder")(order);
    setSortOrder(order);
  };

  return {
    sortField,
    sortOrder,
    handleSortFieldChange,
    handleSortOrderChange,
  };
}

type WorkflowHistoryListQueryState = {
  historyField?: WorkflowEventInListSortingFields;
  historyOrder?: WorkflowSortingOrder;
};

export function useWorkflowHistoryListSort() {
  const {
    state: { historyField, historyOrder },
    set: setQueryState,
  } = useQueryState<WorkflowHistoryListQueryState>();
  const [sortField, setSortField] =
    React.useState<WorkflowEventInListSortingFields>(
      historyField ?? WorkflowEventInListSortingFields.StartTime
    );
  const [sortOrder, setSortOrder] = React.useState<WorkflowSortingOrder>(
    historyOrder ?? WorkflowSortingOrder.Desc
  );

  const handleSortFieldChange = (field: WorkflowEventInListSortingFields) => {
    setQueryState("historyField")(field);
    setSortField(field);
  };

  const handleSortOrderChange = (order: WorkflowSortingOrder) => {
    setQueryState("historyOrder")(order);
    setSortOrder(order);
  };

  return {
    sortField,
    sortOrder,
    handleSortFieldChange,
    handleSortOrderChange,
  };
}

type StatusQueryState = {
  status?: WorkflowEventStatus;
};

export type WorkflowEventStatusExtended = WorkflowEventStatus | "ALL";

export function useFilterStatus() {
  const {
    state: { status },
    set: setQueryState,
    removeFields,
  } = useQueryState<StatusQueryState>();
  const [currentStatus, setCurrentStatus] =
    React.useState<WorkflowEventStatusExtended>(status ?? "ALL");

  const handleStatusChange = (newStatus: string) => {
    newStatus === "ALL"
      ? removeFields(["status"])
      : setQueryState("status")(newStatus);
    setCurrentStatus(newStatus as WorkflowEventStatusExtended);
  };

  return {
    currentStatus,
    handleStatusChange,
  };
}
