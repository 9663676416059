/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FlexContainer, Checkbox, Text } from "@nordcloud/gnui";
import { SidebarItemWrapper, Separator } from "~/components";
import { Item, SwitchWrap } from "~/components/SidebarWithMultiSelect";
import { useSelectableListFilter } from "../hooks";
import { EstateFilterHeader } from "./EstateFilterHeader";

type Props = {
  selectableItems: Item[];
  selectedOptions: string[];
  onMultiSelect: (ids: string[]) => void;
};

export function SelectableListWithSearch({
  selectedOptions = [],
  selectableItems,
  onMultiSelect,
}: Props) {
  const { selectedItems, filteredOptions, setSearch, onApply } =
    useSelectableListFilter({
      selectableItems,
      selectedOptions,
      onMultiSelect,
    });

  return (
    <>
      <EstateFilterHeader showSearchBar onSetSearch={setSearch} />
      <FlexContainer
        direction="column"
        css={{ maxHeight: "20rem", overflowY: "scroll", overflowX: "hidden" }}
      >
        <Separator />
        {filteredOptions.map(({ label, value }) => (
          <SidebarItemWrapper
            key={`${label}${value}`}
            hideBottomBorder
            title={value ?? ""}
            data-testid="sidebar-item"
            onClick={() => onApply(value ?? "")}
          >
            <SwitchWrap>
              <Checkbox
                withoutLabel
                readOnly
                checked={selectedItems?.includes(value ?? "")}
              />
            </SwitchWrap>
            <Text marginBottom="0">{label}</Text>
          </SidebarItemWrapper>
        ))}
      </FlexContainer>
    </>
  );
}
