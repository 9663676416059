/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import { ApolloError, ApolloQueryResult } from "@apollo/client";
import { Else, If, Then, When } from "react-if";
import {
  Box,
  Col,
  FlexContainer,
  Row,
  Spacer,
  SVGIcon,
  Text,
  theme,
} from "@nordcloud/gnui";
import {
  Exact,
  Provider,
  ResourceQuery,
  useReservationDetailsQuery,
} from "~/generated/graphql";
import {
  BreadcrumbsBox,
  CollapsibleBox,
  Details,
  ErrorMessage,
  IconBox,
  LoaderWrap,
} from "~/components";
import {
  CostBox,
  CustomComponents,
  getOriginalCost,
} from "~/components/CostBox";
import { ERROR_TEXT } from "~/constants";
import { showError } from "~/services/toast";
import {
  Currency,
  formatMoney,
  getExchangeRate,
  getProviderIconName,
  isCloudResource,
  isCostResource,
  isNotEmpty,
  noop,
} from "~/tools";
import { Application } from "../Application";
import { ApplicationsList } from "./ApplicationsList";
import { ReservedInstanceCharts, SavingSuggestions } from "./components";
import {
  emptyPlanObj as emptyPlanObject,
  NOT_AVAILABLE_TEXT,
} from "./constants";
import { CostResourceSplitPerEnvironments } from "./CostResourceSplitPerEnvironments";
import { EstateDetailsTabs } from "./EstateDetailsTabs";
import { LinkedResources } from "./EstateDetailsTabs/components";
import {
  Detail,
  useDetails,
  useEstateDetails,
  useReservationsAzureDetails,
} from "./hooks";
import { InstanceDetails } from "./InstanceDetails";
import { KubernetesOpenshiftDetails } from "./KubernetesOpenshiftDetails";
import { ApplicationProps, Costs } from "./types";
import { Utilization } from "./Utilization";
import {
  getProvider,
  isNodeInstance,
  isPodInstance,
  textWithFallback,
} from "./utils";

type Props = {
  loading: boolean;
  error: ApolloError | undefined;
  data: ReturnType<typeof useEstateDetails>["data"];
  currency: Currency;
  nordcloudId: string;
  refetch: (
    variables?: Partial<Exact<{ id: string }>> | undefined
  ) => Promise<ApolloQueryResult<ResourceQuery>>;
  cloudResourceData: {
    name: string;
    providerId: string;
    provider: Provider;
    providerType: string | null | undefined;
  } | null;
  costs: Costs;
  applications: ApplicationProps;
};

// TODO: Refactor to reduce size and complexity
// eslint-disable-next-line max-lines-per-function
export function DefaultEstateDetailsPage({
  loading,
  error,
  data,
  costs,
  currency,
  applications,
  nordcloudId,
  refetch,
  cloudResourceData,
}: Props) {
  const [rate, setRate] = React.useState(1);

  React.useEffect(() => {
    getExchangeRate(currency, Currency.Usd)
      .then(setRate)
      .catch(() => {
        showError(ERROR_TEXT.default);
      });
  }, [currency]);

  const estateRecord = data.estateRecord ?? {
    __typename: "CostResource",
    providerType: "",
    id: "",
    provider: undefined,
  };

  const provider = getProvider(estateRecord?.provider);

  const showCostResourceSplit = isCostResource(estateRecord);
  const showProvider = isNotEmpty(provider);

  const cloudAccountId = React.useMemo(() => {
    if (estateRecord && isCloudResource(estateRecord)) {
      return estateRecord.cloudAccountId?.toString();
    }
  }, [estateRecord]);

  const kubernetesDetails = React.useMemo(
    () =>
      isCloudResource(estateRecord)
        ? {
            nordcloudId,
            cloudAccountId,
            providerType: estateRecord.providerType,
            linkedResources: data?.links,
          }
        : { nordcloudId },
    [estateRecord]
  );
  const isReservedInstance = data.details
    .map(({ value }: Detail) => value.search("reserved-instances") !== -1)
    .includes(true);

  const isReservationsAzure = data.details.some(
    ({ value }: Detail) =>
      value.search("microsoft.capacity/reservations") !== -1
  );
  const { data: reservationDetails } = useReservationDetailsQuery({
    variables: { reservationId: nordcloudId },
    skip: !isReservedInstance,
  });

  const { azureReservationDetails, billingPlan, skuName } =
    useReservationsAzureDetails(nordcloudId);

  const { getDetails, showInstanceDetails } = useDetails({
    reservationDetails: reservationDetails?.reservationDetails,
    savingsPlanData: data.savingsPlanData,
    rate,
    currency,
    details: data.details,
    isReservedInstance,
    isReservationsAzure,
    azureRIDetails: azureReservationDetails,
  });

  const showDetails = getDetails(isReservedInstance, isReservationsAzure);

  const upfrontCost = (
    Number(reservationDetails?.reservationDetails?.upfrontCost) * rate
  ).toFixed(2);

  const hasOriginalCost = getOriginalCost(data.estateRecord?.cost);

  const costSavings = isCloudResource(estateRecord)
    ? estateRecord.costSavings ?? []
    : [];

  const hasSavingSuggestions = isNotEmpty(costSavings);

  return (
    <Row>
      <Col>
        <LoaderWrap loading={loading} data-testid="estate-resource-loader">
          <ErrorMessage error={error} />
          <BreadcrumbsBox
            tags={data.tags}
            labels={data.breadcrumbLabels}
            title={
              (isCloudResource(estateRecord) && estateRecord.name) ||
              nordcloudId
            }
          />
          <Box spacing="spacing04">
            <Row>
              <Col xs={8} sm={8} md={5} lg={8}>
                <FlexContainer
                  columnGap={theme.spacing.spacing05}
                  css={{ height: "100%" }}
                >
                  <When condition={showProvider}>
                    <IconBox
                      icon={
                        <SVGIcon
                          size="xxl"
                          name={getProviderIconName(
                            provider || Provider.External
                          )}
                        />
                      }
                    />
                  </When>
                  <FlexContainer
                    direction="column"
                    alignItems="start"
                    data-testid="estate-record-details"
                  >
                    <Text isTitle weight="medium">
                      Estate Record Details
                    </Text>
                    <Details details={data.details} />
                    <LinkedResources
                      isCloudResource={isCloudResource(estateRecord)}
                      provider={provider}
                      nordcloudId={nordcloudId}
                      linkedResources={data?.links}
                      cloudResourceData={cloudResourceData}
                      refetch={refetch}
                    />
                    <When
                      condition={showKubernetesOpenshiftDetails(
                        provider,
                        isCloudResource(estateRecord)
                      )}
                    >
                      <KubernetesOpenshiftDetails
                        onChange={async () => {
                          await refetch();
                        }}
                        {...kubernetesDetails}
                      />
                    </When>
                  </FlexContainer>
                </FlexContainer>
              </Col>
              <Col sm={8} md={3} lg={4}>
                <If condition={showInstanceDetails || isReservationsAzure}>
                  <Then>
                    <InstanceDetails
                      isReservedInstance={isReservedInstance}
                      isReservationsAzure={isReservationsAzure}
                      details={showDetails}
                      billingPlan={billingPlan}
                      skuName={skuName}
                      nordcloudId={nordcloudId}
                      savingsPlansData={
                        data?.savingsPlanData ?? emptyPlanObject
                      }
                      instanceType={textWithFallback(
                        reservationDetails?.reservationDetails?.instanceType,
                        NOT_AVAILABLE_TEXT
                      )}
                      paymentOption={textWithFallback(
                        reservationDetails?.reservationDetails?.paymentOption,
                        NOT_AVAILABLE_TEXT
                      )}
                      upfront={
                        isNaN(Number(upfrontCost))
                          ? NOT_AVAILABLE_TEXT
                          : formatMoney(upfrontCost, currency, false)
                      }
                      monthly={textWithFallback(
                        formatMoney(
                          reservationDetails?.reservationDetails?.monthlyCost,
                          currency
                        ),
                        NOT_AVAILABLE_TEXT
                      )}
                    />
                  </Then>
                  <Else>
                    <CostBox
                      numericalValues={costs}
                      linkedRecords={data.links}
                      provider={data.estateRecord?.provider}
                      customComponent={
                        hasOriginalCost
                          ? CustomComponents.ORIGINAL_COST
                          : undefined
                      }
                    />
                  </Else>
                </If>
              </Col>
            </Row>
          </Box>
          <When condition={showUtilPanel(getResourceType(estateRecord))}>
            <Utilization
              nordcloudId={nordcloudId}
              k8sResourceType={getResourceType(estateRecord)}
            />
          </When>
          <When condition={showInstanceDetails}>
            <ReservedInstanceCharts nordcloudId={nordcloudId} />
          </When>
          <When condition={hasSavingSuggestions}>
            <CollapsibleBox title="Savings Suggestions">
              <SavingSuggestions
                costSavings={costSavings}
                provider={estateRecord?.provider ?? ""}
                onSuggestionCountChanged={noop}
              />
            </CollapsibleBox>
            <Spacer height={theme.spacing.spacing04} />
          </When>
          <LoaderWrap loading={applications.loading}>
            <If condition={applications.showApplicationsList}>
              <Then>
                <ApplicationsList
                  recordNid={applications.id ?? ""}
                  refetch={applications.onApplicationUpdate}
                  resourceProvider={applications.provider}
                />
                <Spacer height={theme.spacing.spacing04} />
              </Then>
              <Else>
                <Application
                  refetch={applications.onApplicationUpdate}
                  estateRecord={applications.resource}
                />
                <Spacer height={theme.spacing.spacing04} />
              </Else>
            </If>
          </LoaderWrap>
          <When condition={showCostResourceSplit}>
            <CostResourceSplitPerEnvironments
              estateRecordId={data?.estateRecord?.id ?? ""}
              showCostResourceSplit={showCostResourceSplit}
            />
            <Spacer height={theme.spacing.spacing04} />
          </When>
          <EstateDetailsTabs
            data={data}
            isReservedInstance={showInstanceDetails}
          />
        </LoaderWrap>
      </Col>
    </Row>
  );
}

function showKubernetesOpenshiftDetails(
  provider: Provider | "",
  isCloudRecord: boolean
) {
  return (
    (provider === Provider.Kubernetes || provider === Provider.Openshift) &&
    isCloudRecord
  );
}

function showUtilPanel(providerType: string) {
  return isPodInstance(providerType) || isNodeInstance(providerType);
}

function getResourceType<T extends { providerType?: string | null }>(
  resource: T
) {
  return resource.providerType ?? "";
}
