/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect } from "react";
import { User } from "@auth0/auth0-react";
import { NODE_ENV } from "~/config";
import { isNotNil } from "~/tools";
import { useCustomers } from "./customers";
import { useDatadogRUM, datadogLogs } from "./datadog";
import { useUserflow } from "./userflow";

type SelectedCustomer = ReturnType<typeof useCustomers>["selectedCustomer"];

type UserData =
  | (User & {
      selectedCustomer?: SelectedCustomer;
    })
  | undefined;

export function useSetupUserTracking(userData: UserData) {
  useSetupDatadogUser(userData);
  useSetupUserflow(userData);
}

function useSetupDatadogUser(userData: UserData) {
  const ddRum = useDatadogRUM();

  useEffect(() => {
    if (isNotNil(ddRum) && isNotNil(userData)) {
      ddRum.setUser({
        id: userData.email,
        email: userData.email,
        customerId: userData.selectedCustomer?.id,
        customer: userData.selectedCustomer?.name,
      });
      datadogLogs.setUser({
        id: userData.email,
        email: userData.email,
        customerId: userData.selectedCustomer?.id,
        customer: userData.selectedCustomer?.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- `userData?.email` and `userData.selectedCustomer.id` assert uniqueness
  }, [ddRum, userData?.email, userData?.selectedCustomer?.id]);
}

function useSetupUserflow(userData: UserData) {
  const { initialize } = useUserflow();

  useEffect(() => {
    if (NODE_ENV === "production" && isNotNil(userData)) {
      initialize(userData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- `initialize` can be ignored, `userData.email` asserts uniqueness
  }, [userData?.email]);
}
