/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Provider } from "./types";

export const CLOUD_FORMATION_URL =
  "https://console.aws.amazon.com/cloudformation/home";

export const CLOUD_FORMATION_TEMPLATE_URL =
  "https://drive.google.com/file/d/1--HX4Ik4Rejm-YuGVzGjeoceS6Ad9vo1/view?pli=1";

export const MORE_INFO_EXTERNAL_IDS_URL =
  "https://aws.amazon.com/blogs/security/how-to-use-external-id-when-granting-access-to-your-aws-resources/";

export const HYBRID_PROVIDERS = [
  Provider.Kubernetes,
  Provider.Openshift,
  Provider.Hybrid,
];
