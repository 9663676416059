/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { DateRange, EstatePeriod, Granularity } from "~/components";

export function useEstateParameters() {
  const [period, setPeriod] = useState<EstatePeriod>(
    EstatePeriod.CURRENT_MONTH
  );

  const [search, setSearch] = useState("");

  const [range, setRange] = useState<DateRange | undefined>(undefined);

  const [granularity, setGranularity] = useState<Granularity>(
    Granularity.MONTHS
  );

  const handlePeriodChange = (
    selectedPeriod?: EstatePeriod,
    selectedRange?: DateRange
  ) => {
    setPeriod(selectedPeriod ?? EstatePeriod.CURRENT_MONTH);
    setRange(selectedRange);
  };

  return {
    period,
    granularity,
    range,
    setGranularity,
    handlePeriodChange,
    search,
    setSearch,
  };
}
