/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FlexContainer, Select, Text, theme } from "@nordcloud/gnui";
import { getFirstItem, noop } from "~/tools";
import { estateGroupByOptions } from "./types";

export function GroupingSelector() {
  return (
    <FlexContainer marginRight={theme.spacing.spacing04}>
      <Text
        size="sm"
        mb="0"
        mr={theme.spacing.spacing04}
        weight="medium"
        color={theme.color.text.text02}
      >
        Group By
      </Text>
      <Select
        name="GroupBy"
        options={estateGroupByOptions}
        value={getFirstItem(estateGroupByOptions)}
        styles={{
          control: (base) => ({
            ...base,
            minWidth: "9rem",
          }),
        }}
        onChange={noop}
      />
    </FlexContainer>
  );
}
