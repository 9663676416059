import { v4 as uuidv4 } from "uuid";
import { ReservationsAndCommitmentsCoverageServiceUsageType } from "~/generated/graphql";
import { REGIONS_AWS } from "~/constants/cloudProviderConst";
import {
  INSTANCE_FAMILY,
  OPERATING_SYSTEM,
  DATABASE_OPERATING_SYSTEMS,
} from "../constants";

export function useFiltersData(
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType
) {
  const instanceFamilies = INSTANCE_FAMILY.map((instance) => ({
    value: instance,
    label: instance,
  }));

  const regionOptions =
    REGIONS_AWS.map((region) => ({
      value: region,
      label: region,
    })) ?? [];

  const platform =
    serviceUsageType ===
    ReservationsAndCommitmentsCoverageServiceUsageType.Compute
      ? OPERATING_SYSTEM
      : DATABASE_OPERATING_SYSTEMS;
  const operatingSystems = platform.map((operatingSystem) => ({
    id: uuidv4(),
    value: operatingSystem,
    label: operatingSystem,
  }));

  return {
    instanceFamilies,
    regionOptions,
    operatingSystems,
  };
}
