/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useUpdateAwsBillingConfigMutation } from "~/generated/graphql";
import { FormData } from "../aws/validators";

export function useUpdateAwsBillingData() {
  const [updateBillingDataItem, { ...mutationState }] =
    useUpdateAwsBillingConfigMutation();

  const updateAwsBillingData = async (data: FormData) => {
    await updateBillingDataItem({
      variables: { input: data },
    });
  };

  return [updateAwsBillingData, mutationState] as const;
}
