/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Case, Switch } from "react-if";
import { Sidebar } from "@nordcloud/gnui";
import { useQueryState } from "~/hooks";
import { CloudAccountsQueryState, Provider } from "../../types";
import { AwsSidebarContent } from "./AwsSidebarContent/AwsSidebarContent";

type Props = {
  isOpen: boolean;
  close: () => void;
};

export function AddNewAccountSidebar({ isOpen, close }: Props) {
  const {
    state: { provider },
  } = useQueryState<CloudAccountsQueryState>();

  return (
    <Sidebar title="Add Cloud Accounts" isOpen={isOpen} onClick={close}>
      <Switch>
        <Case condition={provider === Provider.Aws}>
          <AwsSidebarContent closeSidebar={close} />
        </Case>
      </Switch>
    </Sidebar>
  );
}
