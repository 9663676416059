/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { If, Else, Then } from "react-if";
import { Button, Sidebar } from "@nordcloud/gnui";
import {
  ReservationsAndCommitmentsCoverageServiceUsageType as UsageType,
  ReservationsAndCommitmentsCoverageOfferingType as OfferingType,
  ReservationsAndCommitmentsLookbackPeriod as LookbackPeriod,
  ReservationsAndCommitmentsFilters as Filters,
  ReservationsProvider,
} from "~/generated/graphql";
import { useQueryState } from "~/hooks";
import { AwsReservationsSidebarFilters } from "./AwsSidebarFilters";
import { AzureReservationsSidebarFilters } from "./AzureSidebarFilters";

type Props = {
  onApply: () => void;
  allowSavingFilters: boolean;
  serviceUsageType: UsageType;
  provider: ReservationsProvider;
  offeringType?: OfferingType;
  timePeriod?: LookbackPeriod;
};

type QueryState = Filters & {
  page?: number;
  limit?: number;
  query?: string;
};

export function ReservationsCommitmentsFilters({
  onApply,
  serviceUsageType,
  provider,
  offeringType,
  timePeriod,
}: Props) {
  const [isFiltersSidebarOpen, setIsFiltersSidebarOpen] = useState(false);

  const {
    state: {
      limit: ignoredLimit,
      page: ignoredPage,
      query: ignoredQuery,
      ...restFilters
    },
  } = useQueryState<QueryState>();

  const [filters, setFilters] = useState<Filters>(restFilters);

  const toggleFilters = (openFilters: boolean) => {
    setFilters(restFilters);
    setIsFiltersSidebarOpen(openFilters);
  };

  const onFilterApply = () => {
    toggleFilters(false);
    onApply();
  };

  const sideBarFilters = {
    region: filters?.region,
    instanceFamily: filters?.instanceFamily,
    platform: filters?.platform,
  };

  return (
    <>
      <Button severity="low" icon="filter" onClick={() => toggleFilters(true)}>
        Filters
      </Button>

      <Sidebar
        title="Filters"
        isOpen={isFiltersSidebarOpen}
        onClick={() => toggleFilters(false)}
      >
        <If condition={provider === ReservationsProvider.Aws}>
          <Then>
            <AwsReservationsSidebarFilters
              setFilters={setFilters}
              allowSavingFilters={false}
              serviceUsageType={serviceUsageType}
              filters={sideBarFilters}
              onApply={onFilterApply}
            />
          </Then>
          <Else>
            <AzureReservationsSidebarFilters
              setFilters={setFilters}
              allowSavingFilters={false}
              filters={sideBarFilters}
              serviceUsageType={serviceUsageType}
              timePeriod={timePeriod ?? LookbackPeriod.ThirtyDays}
              offeringType={offeringType ?? OfferingType.ReservedInstances}
              onApply={onFilterApply}
            />
          </Else>
        </If>
      </Sidebar>
    </>
  );
}
