/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When, If, Then, Else } from "react-if";
import { Text } from "@nordcloud/gnui";
import { GridDisplay } from "~/components";
import { useCurrency } from "~/hooks";
import { formatMoney } from "~/tools";
import { CoverageBar } from "./CoverageBar";
import { StyledTd } from "./styles";

type Props = {
  riCostPercent: string;
  spCostPercent: string;
  onDemandCostPercent: string;
  hasBorder: boolean;
  totalOnDemandCost: string;
  coverageSavedCost: string;
  columnSize: number;
  isReservedInstances: boolean;
  potentialSavings?: string;
  potentialSavingsPlaceholder?: string;
};

export function CoverageAndCostColumn({
  riCostPercent,
  spCostPercent,
  onDemandCostPercent,
  hasBorder,
  totalOnDemandCost,
  coverageSavedCost,
  columnSize,
  isReservedInstances,
  potentialSavings,
  potentialSavingsPlaceholder,
}: Props) {
  const { currency } = useCurrency();

  return (
    <>
      <StyledTd hasRightBorder withPadding style={{ textAlign: "center" }}>
        <CoverageBar
          riCostPercent={riCostPercent}
          spCostPercent={spCostPercent}
          onDemandCostPercent={onDemandCostPercent}
        />
      </StyledTd>
      <StyledTd withPadding hasRightBorder={hasBorder}>
        <GridDisplay
          columnNo={columnSize}
          css={{ marginRight: "0rem", padding: "0rem" }}
        >
          <Text align="right" mb={0}>
            {formatMoney(totalOnDemandCost, currency)}
          </Text>
          <Text align="right" mb="0">
            {formatMoney(coverageSavedCost, currency)}
          </Text>
          <When condition={isReservedInstances}>
            <If condition={potentialSavings === "0.00"}>
              <Then>
                <Text align="right">{potentialSavingsPlaceholder}</Text>
              </Then>
              <Else>
                <Text
                  align="right"
                  color={
                    potentialSavings?.includes("N/A") ? "black" : "success"
                  }
                >
                  {formatMoney(potentialSavings, currency)}
                </Text>
              </Else>
            </If>
          </When>
        </GridDisplay>
      </StyledTd>
    </>
  );
}
