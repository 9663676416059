/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import { Else, If, Then } from "react-if";
import { BrickLoader, Text } from "@nordcloud/gnui";
import {
  SearchSortOrder,
  SearchSortField,
  EstateV2Filter,
  useResourcesNewQuery,
} from "~/generated/graphql";
import { Pagination, ReactTable, NoData, LoaderWrap } from "~/components";
import { ForecastColumn } from "~/components/Tables/Columns";
import {
  useFilteredQueryState,
  estateV2FilterFields,
  useCurrency,
} from "~/hooks";
import { useBillingPeriod } from "~/services/customers";
import { isNotEmpty } from "~/tools";
import { generateRowData } from "~/views/estate/helpers";
import { RelatedResourcesColumns } from "./Columns/RelatedResourcesColumns";

type SortingProp = {
  field?: SearchSortField;
  order?: SearchSortOrder;
};

type QueryState = EstateV2Filter &
  SortingProp & {
    page: number;
    limit: number;
    tags: string[];
    tab: number;
    query: string;
  };

type Props = {
  details: {
    label: string;
    value: string;
  }[];
};

export function RelatedResourcesTabContents({ details }: Props) {
  const { currency } = useCurrency();

  const { state } = useFilteredQueryState(estateV2FilterFields);

  // @ts-expect-error ignored temporary after migration to TypeScript 4.9
  const { page, limit }: QueryState = state;

  const { isCurrentBillingPeriod } = useBillingPeriod();

  const columns = useMemo(() => {
    return isCurrentBillingPeriod
      ? [...RelatedResourcesColumns, ...ForecastColumn]
      : RelatedResourcesColumns;
  }, [isCurrentBillingPeriod]);

  const phrase =
    details.find((detail) =>
      ["Record Name", "Resource Name"].includes(detail.label)
    )?.value ?? "";

  const { data: searchData, loading } = useResourcesNewQuery({
    variables: {
      query: phrase,
      filter: {
        onlyRelated: true,
      },
      limit,
      page,
    },
  });

  const resources = useMemo(() => {
    const results = searchData?.estateV2?.resources ?? [];

    return results.map((result) => ({
      ...generateRowData(result, currency), // Use type assertion to avoid overfetching data in gql
    }));
  }, [searchData, currency]);

  const count = searchData?.estateV2?.count ?? 0;
  const showRelatedResources =
    (isNotEmpty(resources) && searchData?.estateV2?.count) || false;

  return (
    <LoaderWrap loading={loading} Component={BrickLoader}>
      <If condition={showRelatedResources}>
        <Then>
          <ReactTable striped columns={columns} data={resources} />
          <Pagination count={count} />
        </Then>
        <Else>
          <NoData message={<Text>There are no related resources.</Text>} />
        </Else>
      </If>
    </LoaderWrap>
  );
}
