/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { PropsWithChildren } from "react";
import { FlexContainer, theme, Box, Text } from "@nordcloud/gnui";

type Props = {
  stepNumber: number;
};

export function FormHint({ stepNumber, children }: PropsWithChildren<Props>) {
  return (
    <FlexContainer
      alignItems="flex-start"
      pb={theme.spacing.spacing03}
      gap={theme.spacing.spacing03}
    >
      <Box css={{ width: "1.5rem", height: "1.5rem" }} p={0} boxStyle="grey">
        <FlexContainer
          css={{ height: "100%", width: "100%" }}
          alignItems="center"
          justifyContent="center"
        >
          <Text weight="medium" size="sm">
            {stepNumber}
          </Text>
        </FlexContainer>
      </Box>
      {children}
    </FlexContainer>
  );
}
