/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useRef, useState } from "react";
import { When } from "react-if";
import styled from "styled-components";
import { FlexContainer, Loader } from "@nordcloud/gnui";
import { noop } from "~/tools";
import { ApplicationOption } from "../AppEnvSelectorPaginated/types";
import { useAppEnvFilterSelector, useInfiniteApplications } from "../hooks";
import { EnvironmentOption, FiltersProp } from "../types";
import { EstateFilterHeader } from "./EstateFilterHeader";
import { SelectableApplicationRow } from "./SelectableApplicationRow";

type Props = {
  selectedApplications: string[];
  selectedEnvironments: string[];
  onMultiSelect: (filter: FiltersProp) => void;
};
export function AppEnvAccordion({
  selectedApplications,
  selectedEnvironments,
  onMultiSelect,
}: Props) {
  const [selectedApps, setSelectedApps] = useState<ApplicationOption[]>([]);
  const [selectedEnvs, setSelectedEnvs] = useState<EnvironmentOption[]>([]);
  const [applicationName, setApplicationName] = useState("");

  const elementRef = useRef(null);

  const { isAppsLoading, applications } = useInfiniteApplications({
    elementRef,
    selectedApplications,
    selectedEnvironments,
    applicationName,
    setSelectedApps,
    setSelectedEnvs,
  });
  const { selectApp, selectEnv } = useAppEnvFilterSelector({
    apps: applications,
    selectedApps,
    setSelectedApps,
    selectedEnvs,
    setSelectedEnvs,
  });

  useEffect(() => {
    onMultiSelect({
      application: selectedApps.map(({ nid }) => nid),
      environment: selectedEnvs.map(({ nid }) => nid),
    });
  }, [selectedApps, selectedEnvs]);

  return (
    <>
      <EstateFilterHeader showSearchBar onSetSearch={setApplicationName} />
      <SelectableApplicationRowWrapper>
        {applications.map((application) => (
          <SelectableApplicationRow
            key={application.nid}
            application={application}
            selectedApps={selectedApps}
            selectedEnvs={selectedEnvs}
            disabled={false}
            onAppCheck={selectApp}
            onEnvCheck={selectEnv}
            onMultiSelect={noop}
          />
        ))}
        <When condition={isAppsLoading}>
          <Loader inContent />
        </When>
        <div ref={elementRef} />
      </SelectableApplicationRowWrapper>
    </>
  );
}

const SelectableApplicationRowWrapper = styled(FlexContainer)`
  align-items: flex-start;
  flex-direction: column;
  max-height: 20rem;
  overflow-y: scroll;
  overflow-x: hidden;
`;
