/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import { useBusinessContextsQuery } from "~/generated/graphql";
import {
  notificationLimit,
  useNotificationsAmount,
} from "~/context/Notifications";
import { getItems } from "./config";
import { useGetProviderAccountsCount } from "./submenus";

export function useGetMenuItems() {
  const { unread } = useNotificationsAmount();
  const { hasReservationsMenu } = useGetProviderAccountsCount();
  const notificationsConfig = useMemo(
    () => ({
      unread,
      limit: notificationLimit,
    }),
    [unread]
  );

  const { data } = useBusinessContextsQuery({
    variables: { limit: 100, page: 0 },
  });

  const businessContext = useMemo(
    () =>
      (data?.businessContexts?.businessContexts ?? [])
        .map(({ id, name, color, isPrimary }) => ({
          id: id ?? "",
          name: name ?? "",
          color: color ?? "",
          isPrimary: isPrimary ?? false,
        }))
        .filter(({ id }) => id),
    [data]
  );

  const items = useMemo(
    () => getItems(businessContext, notificationsConfig, hasReservationsMenu),
    [businessContext, notificationsConfig, hasReservationsMenu]
  );

  return { items };
}
