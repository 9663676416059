/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FlexContainer, Tag, Text, theme } from "@nordcloud/gnui";
import { useQueryState } from "~/hooks";
import { isEmpty } from "~/tools";
import { CloudAccountsQueryState, Provider, StatusValues } from "../types";

const activeFilterTagProps = {
  [StatusValues.NoAccess]: {
    id: StatusValues.NoAccess,
    label: "No Access",
    color: "danger" as const,
  },
  [StatusValues.NoData]: {
    id: StatusValues.NoData,
    label: "No Data",
    color: undefined,
  },
  [StatusValues.BillingDataOnly]: {
    id: StatusValues.BillingDataOnly,
    label: "Limited access",
    color: "warning" as const,
  },
  [StatusValues.FullConnectivity]: {
    id: StatusValues.FullConnectivity,
    label: "Full access",
    color: "success" as const,
  },
  [Provider.Aws]: {
    id: Provider.Aws,
    label: Provider.Aws,
    color: undefined,
  },
  [Provider.Azure]: {
    id: Provider.Azure,
    label: Provider.Azure,
    color: undefined,
  },
  [Provider.Gcp]: {
    id: Provider.Gcp,
    label: Provider.Gcp,
    color: undefined,
  },
};

export function ActiveFilters() {
  const { state, updateQueryState } = useQueryState<CloudAccountsQueryState>();
  const { status, providerFilter } = state;

  const selectedFilters = [...(status || []), ...(providerFilter || [])];

  if (isEmpty(selectedFilters)) {
    return null;
  }

  const onRemoveFilterValue = (valueToRemove: string) => {
    updateQueryState({
      ...state,
      status: status
        ? status.filter((value) => value !== valueToRemove)
        : status,
      providerFilter: providerFilter
        ? providerFilter.filter((value) => value !== valueToRemove)
        : providerFilter,
    });
  };

  return (
    <FlexContainer
      alignItems="center"
      columnGap={theme.spacing.spacing02}
      mt={theme.spacing.spacing04}
    >
      <Text mb={theme.spacing.spacing01}>Applied filters:</Text>
      <div>
        {selectedFilters.map((filter) => {
          const tagProps = getTagProps(filter);
          return tagProps ? (
            <Tag
              key={filter}
              showClose
              text={tagProps.label}
              color={tagProps.color}
              onClick={() => {
                onRemoveFilterValue(tagProps.id);
              }}
            />
          ) : null;
        })}
      </div>
    </FlexContainer>
  );
}

const getTagProps = (filter: string) => {
  const tagProps = Object.entries(activeFilterTagProps).find(
    ([key]) => key === filter
  );
  return tagProps ? tagProps[1] : null;
};
