/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ChangeEvent, SetStateAction, useState } from "react";
import { noop } from "@tanstack/react-table";
import { When } from "react-if";
import { useDebounce } from "react-use";
import {
  FlexContainer,
  Input,
  MultipleSelect,
  SelectButton,
  theme,
} from "@nordcloud/gnui";
import { Separator } from "~/components";
import { DEBOUNCE_TIMEOUT_MS } from "../constants";

type Props = {
  showSearchBar?: boolean;
  onSetSearch: (value: SetStateAction<string>) => void;
};

export function EstateFilterHeader({
  showSearchBar = false,
  onSetSearch,
}: Props) {
  const [input, setInput] = useState("");

  useDebounce(
    () => {
      if (input.length !== 1) {
        onSetSearch(input);
      }
    },
    DEBOUNCE_TIMEOUT_MS,
    [input]
  );

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value.toLowerCase());
  };

  return (
    <>
      <FlexContainer pb={theme.spacing.spacing03} justifyContent="flex-start">
        <MultipleSelect
          size="small"
          css={{ alignContent: "right", marginRight: theme.spacing.spacing03 }}
        >
          <SelectButton
            isActive
            name="include"
            value="1"
            labelText="Include"
            style={{ width: "5rem" }}
            onClick={noop}
          />
          <SelectButton
            disabled
            name="exclude"
            value="0"
            labelText="Exclude"
            isActive={false}
            style={{ width: "5rem" }}
            onClick={noop}
          />
        </MultipleSelect>
        <When condition={showSearchBar}>
          <span css={{ flexGrow: 1 }}>
            <Input
              small
              type="text"
              placeholder="Search"
              onChange={onSearchChange}
            />
          </span>
        </When>
      </FlexContainer>
      <Separator css={{ marginBottom: theme.spacing.spacing04 }} />
    </>
  );
}
