/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { Label } from "@nordcloud/gnui";
import { ReservationsAndCommitmentsFilters } from "~/generated/graphql";
import { Fieldset, SidebarWithMultiSelect } from "~/components";
import { ResourceLabel } from "../../constants";

type regionOptions = {
  value: string;
  label: string;
};
type instanceFamilies = {
  value: string;
  label: string;
};
type Props = {
  instanceFamilies: instanceFamilies[];
  regionOptions: regionOptions[];
  filters: ReservationsAndCommitmentsFilters;
  setFilters: (filter: ReservationsAndCommitmentsFilters) => void;
  showInstanceFamilyFilter: boolean;
  familyLabel: ResourceLabel;
};

export function CommonFilters({
  instanceFamilies,
  regionOptions,
  filters,
  setFilters,
  showInstanceFamilyFilter,
  familyLabel,
}: Props) {
  return (
    <>
      <When condition={showInstanceFamilyFilter}>
        <Fieldset>
          <Label name={familyLabel} />
          <SidebarWithMultiSelect
            name={familyLabel}
            options={instanceFamilies}
            selectedOptions={filters?.instanceFamily ?? []}
            onClear={() =>
              setFilters({ ...filters, instanceFamily: undefined })
            }
            onMultiSelect={(instanceFamily) =>
              setFilters({
                ...filters,
                instanceFamily,
              })
            }
          />
        </Fieldset>
      </When>
      <Fieldset>
        <Label name="Region" />
        <SidebarWithMultiSelect
          name="Region"
          options={regionOptions}
          selectedOptions={filters?.region ?? []}
          showLoader={false}
          onClear={() => setFilters({ ...filters, region: undefined })}
          onMultiSelect={(region) =>
            setFilters({
              ...filters,
              region,
            })
          }
        />
      </Fieldset>
    </>
  );
}
