/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { theme } from "@nordcloud/gnui";
import {
  ReservationsAndCommitmentsCoverageServiceUsageType,
  ReservationsProvider,
} from "~/generated/graphql";
import { ReservationTooltip } from "../../constants";
import { useGetAzureServiceLabel } from "../../hooks";
import { columnProps } from "../../types";

const potentialCost = [
  {
    headerName: "Potential Savings",
    accessor: "potentialSavings",
    alignment: "right" as const,
    tooltipText: ReservationTooltip.POTENTIAL_SAVINGS,
  },
];
const onDemandCost = [
  {
    headerName: "On-Demand Cost",
    accessor: "onDemandCost",
    alignment: "right" as const,
    tooltipText: ReservationTooltip.ON_DEMAND_COST,
  },
];

const costColumnsRIs = [
  {
    headerName: "Cost Savings",
    accessor: "costSavings",
    alignment: "right" as const,
    tooltipText: ReservationTooltip.COST_SAVINGS_RI,
  },
];

const costColumnsSPs = [
  {
    headerName: "Cost Savings",
    accessor: "costSavings",
    alignment: "right" as const,
    tooltipText: ReservationTooltip.COST_SAVINGS_SP,
  },
];

const AwsCoverageColumns = (isReservedInstances: boolean): columnProps[] => {
  const childCostColumns = isReservedInstances
    ? [...onDemandCost, ...costColumnsRIs, ...potentialCost]
    : [...onDemandCost, ...costColumnsSPs];

  const coverageColumns = [
    {
      headerName: "Instance Details",
      accessor: "instanceDetails",
      hasRightBorder: true,
      hasColSpan: true,
      alignment: "left" as const,
      styleTd: {
        background: theme.color.background.ui03,
        padding: theme.spacing.spacing02,
      },
    },
    {
      headerName: "Coverage Details",
      accessor: "coverageDetails",
      hasRightBorder: true,
      hasColSpan: false,
      alignment: "center" as const,
      width: "25%",

      styleTd: {
        textAlign: "center",
        background: theme.color.background.ui03,
        padding: theme.spacing.spacing02,
      },
    },
    {
      headerName: "Cost",
      accessor: "cost",
      hasRightBorder: true,
      hasColSpan: false,
      alignment: "center" as const,
      styleTd: {
        background: theme.color.background.ui03,
        padding: theme.spacing.spacing02,
      },
      childHeaders: childCostColumns,
    },
  ];

  const recommendationColumn = [
    {
      headerName: "Recommendations",
      accessor: "recommendations",
      hasRightBorder: true,
      hasColSpan: false,
      alignment: "center" as const,
      width: "10%",
      styleTd: {
        textAlign: "center",
        background: theme.color.background.ui03,
        padding: theme.spacing.spacing02,
      },
    },
  ];

  return isReservedInstances
    ? [...coverageColumns, ...recommendationColumn]
    : coverageColumns;
};

const AzureCoverageColumns = (
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType
): columnProps[] => {
  const costColumns = [...onDemandCost, ...costColumnsRIs];
  const childColumns = [...costColumns, ...potentialCost];

  const recommendationColumn: columnProps = {
    headerName: "Recommendations",
    accessor: "recommendations",
    hasRightBorder: true,
    hasColSpan: false,
    alignment: "center",
    width: "10%",
    styleTd: {
      textAlign: "center",
      background: theme.color.background.ui03,
      padding: theme.spacing.spacing02,
    },
  };

  const coverageColumns: columnProps[] = [
    {
      headerName: useGetAzureServiceLabel(serviceUsageType),
      accessor: "instanceDetails",
      hasRightBorder: true,
      hasColSpan: true,
      alignment: "left",
      styleTd: {
        background: theme.color.background.ui03,
        padding: theme.spacing.spacing02,
      },
    },
    {
      headerName: "Coverage Details",
      accessor: "coverageDetails",
      hasRightBorder: true,
      hasColSpan: false,
      alignment: "center",
      width: "25%",
      styleTd: {
        background: theme.color.background.ui03,
        padding: theme.spacing.spacing02,
      },
    },
    {
      headerName: "Cost",
      accessor: "cost",
      hasRightBorder: true,
      hasColSpan: false,
      alignment: "center",
      styleTd: {
        background: theme.color.background.ui03,
        padding: theme.spacing.spacing02,
      },
      childHeaders: childColumns,
    },
  ];

  return [...coverageColumns, recommendationColumn];
};

type HeaderProps = {
  isReservedInstances: boolean;
  provider: ReservationsProvider;
  serviceUsageType?: ReservationsAndCommitmentsCoverageServiceUsageType;
};

export const getCoverageHeaderColumns = ({
  isReservedInstances,
  provider,
  serviceUsageType,
}: HeaderProps) => {
  return provider === ReservationsProvider.Aws
    ? AwsCoverageColumns(isReservedInstances)
    : AzureCoverageColumns(
        serviceUsageType ??
          ReservationsAndCommitmentsCoverageServiceUsageType.Compute
      );
};
