/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { If, Then, Else, When } from "react-if";
import { Link, generatePath } from "react-router-dom";
import styled from "styled-components";
import {
  Box,
  theme,
  FlexContainer,
  Text,
  Button,
  Modal,
  Switch,
} from "@nordcloud/gnui";
import { SUCCESS_TEXT, ERROR_TEXT } from "~/constants";
import { useDisclosure, useToggle } from "~/hooks";
import { ROUTES } from "~/routing/routes";
import { showSimpleInfo, showError } from "~/services/toast";
import { isNotEmpty } from "~/tools";
import { AwsAutoForm } from "../aws/AwsAutoForm";
import { AwsManualForm } from "../aws/AwsManualForm";
import { AwsFormFields, FormData } from "../aws/validators";
import { useUpdateAwsBillingData } from "../hooks/useUpdateAwsBillingData";

type Props<T> = {
  name: string;
  accountId: string;
  nid: string;
  interactionId: T;
  onDelete?: (interactionId: T) => void;
};

export function BillingDataListItem<T>({
  name,
  accountId,
  nid,
  interactionId,
  onDelete,
}: Props<T>) {
  const {
    close: closeDeleteModal,
    open: openDeleteModal,
    isOpen: isOpenDeleteModal,
  } = useDisclosure();

  const [showUpdateForm, toggleShowUpdateForm] = useToggle();

  const detailsLink = isNotEmpty(nid)
    ? generatePath(ROUTES.cloudAccounts.details, { nid })
    : "";

  return (
    <>
      <Box border="disabled" boxStyle="lightGrey" mb={theme.spacing.spacing03}>
        <FlexContainer
          justifyContent="space-between"
          alignItems="center"
          gap={theme.spacing.spacing03}
        >
          <div>
            <If condition={isNotEmpty(name)}>
              <Then>
                <Link to={detailsLink}>{name}</Link>
                <Text size="sm" color={theme.color.text.text03}>
                  {accountId}
                </Text>
              </Then>
              <Else>
                <Text tag="span" size="sm" color={theme.color.text.text03}>
                  Account ID
                </Text>
                <Text>{accountId}</Text>
              </Else>
            </If>
          </div>
          <FlexContainer gap={theme.spacing.spacing03}>
            <Button
              severity="low"
              icon="edit"
              size="md"
              onClick={toggleShowUpdateForm}
            />
            <Button
              severity="low"
              icon="trash"
              size="md"
              onClick={openDeleteModal}
            />
          </FlexContainer>
        </FlexContainer>
        <When condition={showUpdateForm}>
          <BillingDataListItemUpdateForm
            accountId={accountId}
            onClose={toggleShowUpdateForm}
          />
        </When>
      </Box>
      <Modal
        isOpen={isOpenDeleteModal}
        contentLabel="Delete Billing Data"
        actions={[
          {
            order: 0,
            onAction: closeDeleteModal,
            label: "Cancel",
            severity: "low",
          },
          {
            order: 1,
            onAction: () => {
              onDelete?.(interactionId);
              closeDeleteModal();
            },
            label: "Delete",
            severity: "high",
          },
        ]}
        onClose={closeDeleteModal}
      >
        <Text>Are you sure you want to delete:</Text>
        <Text weight="bold">
          {name ? `${name} ` : ""}
          {name ? `(${accountId})` : accountId}
        </Text>
      </Modal>
    </>
  );
}

type BillingDataListItemUpdateFormProps = {
  accountId: string;
  onClose: () => void;
};

function BillingDataListItemUpdateForm({
  accountId,
  onClose,
}: BillingDataListItemUpdateFormProps) {
  const [updateAwsBillingData] = useUpdateAwsBillingData();
  const [updateBillingDataFormType, toggleUpdateBillingDataFormType] =
    useToggle(true);

  const AwsForm = updateBillingDataFormType ? AwsAutoForm : AwsManualForm;

  const handleUpdate = async (data: FormData) => {
    try {
      await updateAwsBillingData(data);
      showSimpleInfo(SUCCESS_TEXT.billingDataUpdated);
      onClose();
    } catch {
      showError(ERROR_TEXT.default);
    }
  };

  return (
    <FlexContainer
      marginTop={theme.spacing.spacing04}
      direction="column"
      alignItems="flex-start"
      gap={theme.spacing.spacing01}
    >
      <Text weight="bold">Billing Data Settings</Text>
      <FlexContainer gap={theme.spacing.spacing04}>
        <Switch
          checked={updateBillingDataFormType}
          onChange={toggleUpdateBillingDataFormType}
        />
        <div>
          <Text weight="regular" m={0}>
            Automatically (Recommended)
          </Text>
          <Text size="sm" color={theme.color.text.text03}>
            CloudFormation stack will be deployed in the US-EAST-1 region.
          </Text>
        </div>
      </FlexContainer>
      <FlexContainer gap={theme.spacing.spacing04}>
        <Switch
          checked={!updateBillingDataFormType}
          onChange={toggleUpdateBillingDataFormType}
        />
        <div>
          <Text m={0}>Manually</Text>
          <Text size="sm" color={theme.color.text.text03}>
            CloudFormation stack will be deployed in a chosen region.
          </Text>
        </div>
      </FlexContainer>
      <Seperator />
      <AwsForm
        closeForm={close}
        defaultValues={{ accountId }}
        disabledFields={[AwsFormFields.ACCOUNT_ID]}
        onSubmit={handleUpdate}
      />
    </FlexContainer>
  );
}

const Seperator = styled.div`
  width: 100%;
  border-bottom: 1px solid ${theme.color.border.input};
  margin-bottom: ${theme.spacing.spacing02};
  margin-top: ${theme.spacing.spacing02};
`;
