/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Maybe } from "graphql-language-service";
import styled from "styled-components";
import {
  FlexContainer,
  theme,
  ExtendedTooltip,
  SVGIcon,
  Box,
  Text,
} from "@nordcloud/gnui";
import { CloudProviderType } from "~/generated/graphql";
import { Provider } from "~/tests/types";
import { isNotNil } from "~/tools";
import { getProviderIconName } from "../../CloudAccountList/utils";

type DetailsHeaderProps = {
  accountName: string;
  provider: Provider;
  providerType: Maybe<CloudProviderType>;
};

export function DetailsHeader({
  accountName,
  provider,
  providerType,
}: DetailsHeaderProps) {
  return (
    <FlexContainer
      pb={theme.spacing.spacing04}
      css={{ borderBottom: theme.borders.grey }}
      gap={theme.spacing.spacing03}
    >
      <IconWrap>
        <ExtendedTooltip
          position="start"
          caption="Automatically discovered accounts; only basic billing data available. Update Credentials to have all data"
        >
          <SVGIcon color="warning" size="lg" name="cloudWithDollar" />
        </ExtendedTooltip>
      </IconWrap>
      {isNotNil(provider) && (
        <IconWrap>
          <SVGIcon
            size="lg"
            name={getProviderIconName(provider, providerType ?? undefined)}
          />
        </IconWrap>
      )}
      <div>
        <Text size="sm" tag="div" color={theme.color.text.text03}>
          Account name
        </Text>
        <Text tag="div">{accountName}</Text>
      </div>
    </FlexContainer>
  );
}

const IconWrap = styled(Box)`
  padding: 0;
  background-color: ${theme.color.background.ui03};
  border: ${theme.borders.grey};
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
