/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ApolloCache } from "@apollo/client";
import { FlexContainer, theme } from "@nordcloud/gnui";
import {
  CloudAccountsDocument,
  CloudAccountsQuery,
  CloudAccountsQueryVariables,
  Person,
  usePeopleQuery,
  useUpdateAccountV2Mutation,
} from "~/generated/graphql";
import { ERROR_TEXT, SUCCESS_TEXT } from "~/constants/text";
import { showError, showSuccess } from "~/services/toast";
import { Maybe, generateActionSuccessText, isNotNil } from "~/tools";
import { AccountOwner } from "../../types";
import { ContactDetailsField } from "./ContactDetailsField";
import { TextDetailsField } from "./TextDetailsField";

type Props = {
  accountId: string;
  accountInternalId: string;
  accountOwner: Maybe<AccountOwner>;
  contactsNames: string[];
  description: string;
  cloudAccountCache: ApolloCache<CloudAccountsQuery>;
};

export function DetailsForm({
  accountId,
  accountInternalId,
  accountOwner,
  contactsNames,
  description,
  cloudAccountCache,
}: Omit<Props, "contactsNames"> & {
  contactsNames: string;
}) {
  const [updateAccount] = useUpdateAccountV2Mutation();
  const { data } = usePeopleQuery();

  const handleOwnerEdit = async (id: string) => {
    try {
      await updateAccount({
        variables: {
          id: accountInternalId,
          ownerId: id,
        },
      });
      handleCache({ owner: data?.people?.find((person) => person?.id === id) });
      showSuccess(SUCCESS_TEXT.ownerUpdated);
    } catch {
      showError(ERROR_TEXT);
    }
  };

  const handleContactsEdit = async (id?: string) => {
    try {
      await updateAccount({
        variables: {
          id: accountInternalId,
          contactIds: isNotNil(id) ? [id] : null,
        },
      });

      const newContact =
        data?.people?.find((person) => person?.id === id) ?? null;

      const newContacts = isNotNil(newContact)
        ? [data?.people?.find((person) => person?.id === id)]
        : [];

      handleCache({
        contacts: newContacts as Person[],
      });
      showSuccess(SUCCESS_TEXT.contactPersonUpdated);
    } catch {
      showError(ERROR_TEXT);
    }
  };

  const handleDescriptionEdit = async (newDescription: string) => {
    try {
      await updateAccount({
        variables: {
          id: accountInternalId,
          description: newDescription,
        },
      });
      handleCache({ description: newDescription });
      showSuccess(generateActionSuccessText("Description")()("updated")());
    } catch {
      showError(ERROR_TEXT);
    }
  };

  const handleCache = (
    update: Partial<{
      contacts: Person[];
      description: string;
      owner: AccountOwner;
    }>
  ) => {
    cloudAccountCache.updateQuery<
      CloudAccountsQuery,
      CloudAccountsQueryVariables
    >(
      {
        query: CloudAccountsDocument,
        variables: {
          limit: 1,
          filter: {
            cloudProviderId: [accountId],
          },
        },
      },
      (cloudAccounts) => {
        return {
          cloudAccounts: {
            count: 1,
            pages: 1,
            accounts: [
              {
                ...(cloudAccounts?.cloudAccounts?.accounts ?? [])[0],
                ...update,
              },
            ],
          },
        };
      }
    );
  };

  return (
    <>
      <FlexContainer
        alignItems="flex-start"
        direction="column"
        pt={theme.spacing.spacing03}
        gap={theme.spacing.spacing03}
      >
        <TextDetailsField label="Account ID" value={accountId} />
        <ContactDetailsField
          label="Account Owner"
          value={accountOwner?.name ?? ""}
          onEdit={handleOwnerEdit}
        />
        <ContactDetailsField
          label="Contact Person"
          value={contactsNames}
          onEdit={handleContactsEdit}
          onDelete={handleContactsEdit}
        />
        <TextDetailsField
          label="Description"
          value={description}
          onEdit={handleDescriptionEdit}
        />
      </FlexContainer>
    </>
  );
}
