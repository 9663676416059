/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import dayjs from "dayjs";
import {
  TimePoint,
  useAccumulatedCostQuery,
  Granularity as APIGranularity,
  AccumulatedCostQuery,
} from "~/generated/graphql";
import {
  mapGranularity,
  CostAnalysisFields,
  Granularity,
  TableData,
} from "~/components";
import { isNotNil } from "~/tools";

type Props = {
  startDate: string;
  endDate: string;
  granularity: Granularity;
};

export function useApplicationsAccumulatedCost({
  startDate,
  endDate,
  granularity,
}: Props) {
  const { data, loading, error } = useAccumulatedCostQuery({
    variables: {
      startDate,
      endDate,
      granularity: mapGranularity(granularity),
      tableGranularity: APIGranularity.Monthly,
    },
  });

  const accumulatedCostChartData = useMemo(
    () => generateAccumulatedCostGroups(data),
    [data]
  );

  const accumulatedCostTimePoints = useMemo(
    () =>
      (data?.accumulatedCostTable?.timePoints ?? [])
        .filter(isNotNil)
        .map(mapTimePointValue),
    [data]
  );

  const accumulatedCostTotal = Number(data?.accumulatedCostTable?.total ?? 0);

  const accumulatedCostTableData: TableData[] = useMemo(
    () => [
      {
        field: CostAnalysisFields.TOTAL_COST,
        total: accumulatedCostTotal,
        ...accumulatedCostTimePoints?.reduce(
          (acc, { date, value }) => ({ ...acc, [date]: Number(value ?? 0) }),
          {}
        ),
      },
    ],
    [accumulatedCostTotal, accumulatedCostTimePoints]
  );

  return {
    accumulatedCostChartData,
    accumulatedCostTimePoints,
    accumulatedCostTableData,
    accumulatedCostTotal,
    loading,
    error,
  };
}

function mapTimePointValue(timePoint: TimePoint) {
  return {
    ...timePoint,
    value: Number(timePoint.value),
  };
}

function generateAccumulatedCostGroups(data: AccumulatedCostQuery | undefined) {
  const costCorrections = (
    data?.customerCorrectionCost.timePoints ?? []
  ).filter(isNotNil);

  return (data?.accumulatedCost?.timePoints ?? [])
    .filter(isNotNil)
    .map((timepoint) => {
      if (dayjs(timepoint.date).isAfter(dayjs(), "day")) {
        return {
          ...timepoint,
          groups: [
            {
              id: "savings",
              value: timepoint.value,
              name: CostAnalysisFields.SAVINGS_PLANS,
            },
          ],
        };
      }

      const correctionCost =
        costCorrections?.find(
          (correction) => correction.date === timepoint.date
        )?.value ?? 0;

      const isCorrections = correctionCost !== 0;
      const costCorrectionGroups = isCorrections
        ? [
            {
              id: "correctionsId",
              value: correctionCost,
              name: CostAnalysisFields.CORRECTION_COST,
            },
          ]
        : [];

      return {
        ...timepoint,
        groups: [
          {
            id: "accumulatedDailyCost",
            value: (
              Number(timepoint.value) - Number(correctionCost)
            ).toString(),
            name: CostAnalysisFields.DAILY_COST,
          },
          ...costCorrectionGroups,
        ],
      };
    });
}
