/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const CostAnalysisPolicies = {
  GroupTimePoint: {
    keyFields: ["date", "value", "groups", ["id", "name", "value"]],
  },
  EnvGroup: {
    keyFields: ["id", "name", "value"],
  },
  CustomerServiceGroupTimePoint: {
    keyFields: ["date", "value", "groups", ["id", "name", "value"]],
  },
  CustomerServiceGroup: {
    keyFields: ["id", "name", "value"],
  },
};
