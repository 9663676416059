/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { SVGIconProps } from "@nordcloud/gnui";
import {
  CloudProviderType,
  Provider,
  ProviderFilterValues,
  ProviderStatus,
  StatusValues,
} from "../types";

export function getChargeTypeLabel(
  chargeType: string,
  provider?: Provider
): string {
  const chargeTypeMap = new Map<string, string>();
  chargeTypeMap.set("RIFee", "RI Fee");
  chargeTypeMap.set("AZURE", "AKS Cost");
  chargeTypeMap.set("AWS", "EKS Cost");
  chargeTypeMap.set("GCP", "GKE Cost");
  if (provider === Provider.Openshift) {
    chargeTypeMap.set("AZURE", "ARO Cost");
    chargeTypeMap.set("AWS", "OpenShift Cost");
    chargeTypeMap.set("GCP", "OpenShift Cost");
  }
  chargeTypeMap.set("KUBERNETES", "Kubernetes Cost");
  chargeTypeMap.set("OPENSHIFT", "Openshift Cost");

  const found = chargeTypeMap.get(chargeType);
  if (found !== undefined) {
    return found;
  }

  return toTitleCase(chargeType);
}

function toTitleCase(rawString: string): string {
  const parts = rawString.includes(" ")
    ? rawString.split(" ")
    : rawString.split(/(?=[A-Z])/);

  return parts
    .map((value) => value.charAt(0).toUpperCase() + value.slice(1))
    .join(" ");
}

type ProviderLowercase = Lowercase<(typeof Provider)[keyof typeof Provider]>;
type IconName = SVGIconProps["name"];
type ProviderIconName =
  | "hybridCloud"
  | "ibmCloud"
  | "info"
  | (IconName & ProviderLowercase);

export function getProviderIconName(
  provider: Provider,
  cloudProviderType?: CloudProviderType
): ProviderIconName {
  const SPECIAL_PROVIDER_ICONS: { [key: string]: ProviderIconName } = {
    [`${Provider.Ibmcloud}`]: "ibmCloud",
    [`${Provider.Hybrid}`]: "hybridCloud",
    [`${Provider.Hybrid}:${CloudProviderType.Kubernetes}`]: "kubernetes",
    [`${Provider.Hybrid}:${CloudProviderType.Openshift}`]: "openshift",
  };

  if ([Provider.Services, Provider.Nordcloud].includes(provider)) {
    return "info";
  }
  const icon =
    SPECIAL_PROVIDER_ICONS[`${provider}:${cloudProviderType ?? ""}`] ??
    SPECIAL_PROVIDER_ICONS[`${provider}`];

  return icon ?? (provider.toLocaleLowerCase() as ProviderIconName);
}

export const getStatusFilterValues = (status: string[]) => {
  return {
    [StatusValues.FullConnectivity]: status?.includes(
      StatusValues.FullConnectivity
    ),
    [StatusValues.BillingDataOnly]: status?.includes(
      StatusValues.BillingDataOnly
    ),
    [StatusValues.NoAccess]: status?.includes(StatusValues.NoAccess),
    [StatusValues.NoData]: status?.includes(StatusValues.NoData),
  };
};

export const getProviderFilterValues = (providerFilter: string[]) => {
  return {
    [Provider.Aws]: providerFilter?.includes(Provider.Aws),
    [Provider.Azure]: providerFilter?.includes(Provider.Azure),
    [Provider.Gcp]: providerFilter?.includes(Provider.Gcp),
  };
};

export const getStatusReducer = (status: string[]) => {
  return (
    state: ProviderStatus,
    action:
      | keyof ProviderStatus
      | "clearFilterCheckboxes"
      | "setValuesFromQuery"
  ) => {
    if (action === "setValuesFromQuery") {
      return getStatusFilterValues(status);
    }
    if (action === "clearFilterCheckboxes") {
      return {
        [StatusValues.FullConnectivity]: false,
        [StatusValues.BillingDataOnly]: false,
        [StatusValues.NoAccess]: false,
        [StatusValues.NoData]: false,
      };
    }
    return {
      ...state,
      [action]: !state[action],
    };
  };
};

export const getProviderReducer =
  (providerFilter: string[]) =>
  (
    state: ProviderFilterValues,
    action:
      | keyof ProviderFilterValues
      | "clearFilterCheckboxes"
      | "setValuesFromQuery"
  ) => {
    if (action === "setValuesFromQuery") {
      return getProviderFilterValues(providerFilter);
    }
    if (action === "clearFilterCheckboxes") {
      return {
        [Provider.Aws]: false,
        [Provider.Azure]: false,
        [Provider.Gcp]: false,
      };
    }
    return {
      ...state,
      [action]: !state[action],
    };
  };
