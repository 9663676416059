/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo, useEffect } from "react";
import {
  useApplicationsCostAnalysisQuery,
  Granularity as APIGranularity,
} from "~/generated/graphql";
import {
  mapGranularity,
  ChartType,
  Granularity,
  TableData,
  generateApplicationGroupData,
} from "~/components";
import { useQueryState } from "~/hooks";
import { isNotEmpty, isNotNil } from "~/tools";
import { getCategoriesList } from "~/views/applications/ApplicationCostTabs/hooks/utils";
import { generateDataWithCorrections } from "./helpers";

type Props = {
  startDate: string;
  endDate: string;
  granularity: Granularity;
  showOtherApplicationsCost: boolean;
  handleOtherCostsSwitch: (enable: boolean) => void;
};

type Pagination = {
  page: number;
  limit: number;
};
export function useApplicationsCostPerApplication({
  startDate,
  endDate,
  granularity,
  showOtherApplicationsCost,
  handleOtherCostsSwitch,
}: Props) {
  const { state } = useQueryState<Pagination>();

  const { data, loading, error } = useApplicationsCostAnalysisQuery({
    variables: {
      limit: 10,
      page: state.page,
      startDate,
      endDate,
      granularity: mapGranularity(granularity),
      tableGranularity: APIGranularity.Monthly,
    },
  });

  useEffect(() => {
    if (!loading) {
      const pages = Number(data?.customerApplicationsCost.pages ?? 0);
      handleOtherCostsSwitch(pages > 1);
    }
  }, [data, loading, handleOtherCostsSwitch]);

  const applicationsCostTableTimePoints = useMemo(
    () =>
      (data?.customerApplicationsTableCost.timePoints ?? []).filter(isNotNil),
    [data]
  );

  const costPerApplicationTableData: TableData[] = useMemo(
    () =>
      generateApplicationGroupData(
        applicationsCostTableTimePoints ?? [],
        showOtherApplicationsCost,
        ChartType.COST_PER_APPLICATION
      ),
    [applicationsCostTableTimePoints, showOtherApplicationsCost]
  );

  const costPerApplicationChartData = useMemo(
    () =>
      generateDataWithCorrections(
        (data?.customerApplicationsCost.timePoints ?? []).filter(isNotNil),
        (data?.customerCorrectionCost.timePoints ?? []).filter(isNotNil)
      ),
    [data]
  );

  const isCorrections = useMemo(
    () =>
      isNotEmpty(
        (data?.customerCorrectionCost.timePoints ?? []).filter(isNotNil)
      ),
    [data]
  );

  const costPerApplicationTotal = Number(
    data?.customerApplicationsTableCost ?? "0"
  );

  const applications = getCategoriesList(costPerApplicationTableData);

  return {
    costPerApplicationChartData,
    costPerApplicationTableData,
    costPerApplicationTotal,
    loading,
    error,
    count: data?.customerApplicationsCost.count,
    applications,
    isCorrections,
  };
}
