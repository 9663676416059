/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { buildChartTheme } from "@visx/xychart";
import dayjs, { Dayjs } from "dayjs";
import { isNil } from "lodash";
import { theme } from "@nordcloud/gnui";
import { CustomerGroupTimePoint, GroupTimePoint } from "~/generated/graphql";
import { dateFormat } from "~/constants";
import {
  formatMoneyWithoutSymbol,
  generateNumericArray,
  isNotNil,
  sort,
} from "~/tools";
import { CustomerServiceGroupTimePoint } from "~/views/applicationCostAnalysis/hooks/types";
import { getMonthDifference } from "~/views/applications/ApplicationCostTabs/hooks/utils";
import {
  accumulatedCostChartColors,
  costVsBudgetChartColors,
} from "./components";
import { savingsPlanColor } from "./components/consts";
import { getAllDayValues, mapSwitcherGroupLabel } from "./helpers";
import {
  BudgetYearly,
  ChartType,
  CostAnalysisFields,
  CostVsBudgetForecastChartTimePoint,
  Granularity,
  Period,
  PlaceholderCategory,
  TableData,
  TimePoint,
} from "./types";

export function generateTimeTicks(
  dateFrom: string,
  dateTo: string,
  granularity: Granularity
) {
  const startDate = dayjs(dateFrom);
  const endDate = dayjs(dateTo);
  const diffMonths = getMonthDifference(dateFrom, dateTo);

  // If startDate 01 and end date 31 the diff = 30, so we add 1
  const diffDays = endDate.diff(startDate, "day") + 1;
  const dates = generateNumericArray(diffDays);

  if (granularity === Granularity.MONTHS || dates.length > 100) {
    return generateMonthlyTimeTicks(startDate, diffMonths);
  }

  if (dates.length < 32) {
    return dates.map((dayNumber) =>
      startDate.add(dayNumber, "day").format(dateFormat.shortDate)
    );
  }

  const diffWeeks = endDate.diff(startDate, "week");
  const weeks = generateNumericArray(diffWeeks + 1);
  return weeks.map((weekNumber) =>
    startDate.add(weekNumber, "week").format(dateFormat.shortDate)
  );
}

function generateMonthlyTimeTicks(startDate: Dayjs, diffMonths: number) {
  const dates = generateNumericArray(diffMonths);

  return dates.map((monthNumber) =>
    dayjs(dayjs(startDate).startOf("month"))
      .add(monthNumber, "month")
      .format(dateFormat.shortDate)
  );
}

export const chartTheme = buildChartTheme({
  backgroundColor: theme.color.interactive.primary,
  colors: ["", theme.color.text.text02],
  gridColor: theme.color.border.focus,
  gridColorDark: theme.color.border.focus,
  tickLength: 0,
});

export function getCostVsBudgetChartBarColor(
  data: CostVsBudgetForecastChartTimePoint,
  hasBudget: boolean
) {
  const { value, budget } = data;

  if (hasBudget) {
    return budget - value < 0
      ? costVsBudgetChartColors.costOverBudget
      : costVsBudgetChartColors.costInBudget;
  }

  return costVsBudgetChartColors.costWithoutBudget;
}

export function getCostVsBudgetChartForecastBarColor(
  data: CostVsBudgetForecastChartTimePoint,
  granularity: Granularity
) {
  const { forecast, budget } = data;

  if (granularity === Granularity.MONTHS) {
    return costVsBudgetChartColors.costForecastPositive;
  }

  if (budget === 0) {
    return costVsBudgetChartColors.costForecast;
  }

  return budget - forecast > 0
    ? costVsBudgetChartColors.costForecastPositive
    : costVsBudgetChartColors.costForecastNegative;
}

export function isCurrentMonth(date: string) {
  return dayjs(date).isSame(dayjs(), "month");
}

function generateDay(date: string, value: number, isMonthly: boolean) {
  return dayjs(date)
    .add(value, isMonthly ? "month" : "day")
    .format(dateFormat.shortDate);
}

export function mockDataForDisplay(
  data: CostVsBudgetForecastChartTimePoint,
  dateDiff: number,
  isMonthly: boolean
) {
  return {
    date: generateDay(data.date, dateDiff, isMonthly),
    value: 0,
    budget: data.budget,
    forecast: data.forecast,
  };
}

type ForecastValues = {
  dailyForecastIncrease: number;
  totalForecast: number;
};

export function getForecastValue(
  isAfterToday: boolean,
  previous: CostVsBudgetForecastChartTimePoint,
  forecastValues: ForecastValues
) {
  const { dailyForecastIncrease, totalForecast } = forecastValues;
  const { forecast, value, date } = previous;

  const endOfMonth = dayjs().endOf("month");
  const isLastMonthDay = dayjs(dayjs(date).add(1, "day")).isSame(
    endOfMonth,
    "date"
  );

  if (isLastMonthDay) {
    return totalForecast;
  }

  if (isAfterToday) {
    return forecast + dailyForecastIncrease;
  }

  if (forecast > 0) {
    return forecast + dailyForecastIncrease;
  }
  return value + dailyForecastIncrease;
}

export function getForecastValueIncreaseByDay(
  budgetLeft: number,
  daysToForecast: number,
  lastCostValue: TimePoint
) {
  if (daysToForecast === 0) {
    return 0;
  }

  // Day number will always be in range 1-31
  const lastCostDayNumber = dayjs(lastCostValue.date).date();

  // If cost haven't reached set budget, split difference between cost and budget by days without cost number
  // If cost is over the budget we split cost by days with cost number

  return Math.floor(
    budgetLeft > 0
      ? budgetLeft / daysToForecast
      : lastCostValue.value / lastCostDayNumber
  );
}

export function getMonthStart(date: string) {
  return dayjs(date).startOf("month").format(dateFormat.shortDate);
}

export function mapPeriodForCostChartData(
  period: Period,
  startDate: string,
  endDate: string
): Period {
  if (period !== Period.RANGE) {
    return period;
  }

  const start = dayjs(startDate);
  const end = dayjs(endDate);

  if (start.isSame(end, "month") && start.isSame(end, "year")) {
    return Period.MONTH;
  }

  if (isMoreThanYearDiff(startDate, endDate)) {
    return Period.YEAR;
  }

  return Period.QUARTER;
}

export function mapCostChartData(
  period: Period,
  accumulatedCostChartData: TimePoint[]
) {
  if (period === Period.MONTH) {
    return getMonthCumulativeCostData(
      mapValuesToNumber(accumulatedCostChartData?.filter(isNotNil))
    );
  }

  if (period === Period.QUARTER) {
    const allValues = mapValuesToNumber(accumulatedCostChartData);

    return allValues
      ?.map((_, index) =>
        allValues.slice(0, index + 1).reduce((previousValue, currentValue) => {
          const isSameMonth = dayjs(previousValue.date).isSame(
            currentValue.date,
            "month"
          );
          return {
            date: currentValue.date,
            value: isSameMonth
              ? previousValue.value + currentValue.value
              : currentValue.value,
          };
        })
      )
      .filter(({ value }) => value > 0.01);
  }

  return accumulatedCostChartData.map((item) => {
    return {
      date: item.date,
      value: Number(item.value),
    };
  });
}

function getMonthCumulativeCostData(values: TimePoint[]) {
  return values?.map((item, index) =>
    values.slice(0, index + 1).reduce((previousValue, currentValue) => {
      return {
        date: item.date,
        value: previousValue.value + currentValue.value,
      };
    })
  );
}

function mapValuesToNumber(data: TimePoint[]) {
  return data.map((item) => {
    return {
      ...item,
      value: Number(item.value),
    };
  });
}

export function calculateTotalBudget(
  startDate: string,
  endDate: string,
  budgetYearly: BudgetYearly | undefined
): number {
  if (isNil(budgetYearly)) {
    return 0;
  }

  const yearlyBudget = Number(budgetYearly.yearlySum ?? 0);

  return dayjs(startDate).isSame(dayjs(endDate), "month")
    ? yearlyBudget
    : budgetYearly.budgetByMonth.reduce(
        (a, b, index) =>
          isMonthIncluded(index, startDate, endDate) ? a + Number(b) : a,
        0
      );
}

function isMonthIncluded(
  monthIndex: number,
  startDate: string,
  endDate: string
) {
  const date = dayjs().startOf("year").add(monthIndex, "month");

  return date.isBetween(startDate, endDate, "month", "[]");
}

type CommonTimePoints =
  | CustomerGroupTimePoint[]
  | CustomerServiceGroupTimePoint[]
  | GroupTimePoint[];

type CommonTimePoint =
  | CustomerGroupTimePoint
  | CustomerServiceGroupTimePoint
  | GroupTimePoint;

export function generateKeys(timePoints: CommonTimePoints): string[] {
  return [
    ...new Set(
      timePoints
        .flatMap(
          (item: CommonTimePoint) =>
            item.groups?.map((group) => group?.name ?? "") ?? []
        )
        .sort((groupA, groupB) => {
          if (groupA === PlaceholderCategory.OTHER.name) {
            return 1;
          }
          if (groupB === PlaceholderCategory.OTHER.name) {
            return -1;
          }
          return 0;
        })
    ),
  ];
}

export function generateTopTableData(
  timePoints: GroupTimePoint[]
): TableData[] {
  const groups = generateGroups(timePoints ?? []);

  return groups.map((group) => ({
    id: group.id,
    field: group.name,
    total: timePoints
      .flatMap((timePoint) => timePoint.groups)
      .map((item) => (item?.name === group.name ? Number(item?.value) : 0))
      .reduce((a, b) => (a ?? 0) + Number(b), 0),
    ...Object.fromEntries(
      timePoints.map(({ date }) => [
        date,
        Number(
          timePoints
            .find((item) => item?.date === date)
            ?.groups?.find((item) => item?.name === group.name)?.value ?? 0
        ),
      ])
    ),
  }));
}

export function generateApplicationTableData(
  timePoints: CustomerServiceGroupTimePoint[],
  showOthers: boolean,
  chartType: ChartType
): TableData[] {
  const keys = generateKeys(timePoints ?? []);

  const totalCostPerPeriod = Object.fromEntries(
    timePoints.map(({ date, totalCost }) => [date, Number(totalCost)])
  );

  const generatedData = keys.filter(isNotNil).map((key) => ({
    field: key,
    types:
      timePoints
        .flatMap((timePoint) => timePoint.groups)
        .find((item) => item?.name === key)?.types ?? [],
    total: timePoints
      .flatMap((timePoint) => timePoint.groups)
      .map((item) => (item?.name === key ? Number(item?.value) : 0))
      .reduce((a, b) => a + Number(b), 0),
    ...Object.fromEntries(
      timePoints.map(({ date }) => [
        date,
        Number(
          timePoints
            .find((item) => item?.date === date)
            ?.groups?.find((item) => item?.name === key)?.value ?? 0
        ),
      ])
    ),
  }));

  const sortedData = sortData(generatedData);

  const total = timePoints.reduce((a, b) => a + Number(b.totalCost), 0);

  const tableData = [
    { field: CostAnalysisFields.TOTAL_COST, ...totalCostPerPeriod, total },
    ...sortedData,
  ];

  if (!showOthers) {
    return tableData;
  }

  const otherCostPerPeriod = Object.fromEntries(
    timePoints.map(({ date, value, totalCost }) => {
      const otherCost = Number(totalCost) - Number(value);

      return [date, otherCost.toFixed(2)];
    })
  );

  const otherCostTotal = Object.values(otherCostPerPeriod).reduce(
    (a, b) => a + Number(b),
    0
  );

  return [
    ...tableData,
    {
      field: mapSwitcherGroupLabel(chartType),
      ...otherCostPerPeriod,
      total: otherCostTotal.toFixed(2),
    },
  ];
}

function sortData(
  data: {
    field: string;
    total: number;
  }[]
) {
  return data.sort((a, b) => (a.total > b.total ? -1 : 1));
}

function generateGroups(timePoints: CommonTimePoints) {
  const keys = generateKeys(timePoints);

  return keys.map((key) => {
    const groupId =
      timePoints
        .flatMap((timePoint) => timePoint.groups)
        .find((group) => group?.name === key)?.id ?? "";

    return {
      id: groupId,
      name: key,
    };
  });
}

export function generateTopGroupData(timePoints: GroupTimePoint[]) {
  const data = generateTopTableData(timePoints);
  const sortedData = sort(data, (a, b) => Number(b.total) - Number(a.total));
  const groups = generateGroups(timePoints);

  return sortedData.map((key) => ({
    ...key,
    id: groups.find((group) => group.name === key.field)?.id ?? "",
  }));
}

export function isMoreThanYearDiff(start?: Date | string, end?: Date | string) {
  return Math.abs(dayjs(end).diff(start, "year", true)) > 1;
}

export function generateApplicationGroupData(
  timePoints: CustomerGroupTimePoint[] | CustomerServiceGroupTimePoint[],
  showOthers: boolean,
  chartType: ChartType
) {
  const data = generateApplicationTableData(timePoints, showOthers, chartType);
  const groups = generateGroups(timePoints);

  return data.map((key) => ({
    ...key,
    id: groups.find((group) => group.name === key.field)?.id ?? "",
  }));
}

export function mapAccumulatedCostChartColors(
  costAnalysisFields: CostAnalysisFields[]
) {
  return Object.fromEntries(
    costAnalysisFields.map((value, index) => [value, getColor(value, index)])
  );
}

export function getTotalValues(
  data: {
    date: string;
  }[]
) {
  return data
    ?.flatMap((item) => {
      const dayValues = getAllDayValues(item);

      const dayNegativeTotal = dayValues
        .filter((value) => value < 0)
        .reduce((a, b) => a + Number(b), 0);

      const dayPositiveTotal = dayValues
        .filter((value) => value > 0)
        .reduce((a, b) => a + Number(b), 0);

      return [dayNegativeTotal, dayPositiveTotal];
    })
    .map((value) => Number(formatMoneyWithoutSymbol(Number(value).toFixed(2))));
}

function getColor(value: string, index: number) {
  if (value === CostAnalysisFields.SAVINGS_PLANS) {
    return savingsPlanColor;
  }

  return accumulatedCostChartColors[index];
}
