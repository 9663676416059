/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled, { CSSProperties } from "styled-components";
import { theme } from "@nordcloud/gnui";

export const Wrapper = styled.div`
  width: "100%";
`;

export const StyledTotal = styled.span<{ width: string; align?: string }>`
  display: inline-block;
  color: ${theme.color.text.text04};
  width: ${({ width }) => width};
  text-align: ${({ align }) => align};
  font-size: ${theme.fontSizes.sm};
`;

export const StyledItem = styled.span<{ width: string; align?: string }>`
  display: inline-block;
  color: ${theme.color.text.text04};
  width: ${({ width }) => width};
  text-align: ${({ align }) => align};
  font-size: ${theme.fontSizes.sm};
`;

export const StyledTooltipItem = styled.span<{
  align?: string;
  marginLeft?: string;
}>`
  display: inline-block;
  color: ${theme.color.text.text04};
  width: 47%;
  text-align: ${({ align }) => align};
  font-size: ${theme.fontSizes.sm};
  white-space: nowrap;
  margin-left: ${({ marginLeft }) => marginLeft};
`;

export const Line = styled.div`
  grid-area: line;
  border-top: solid 1px ${theme.color.border.border02};
`;

export const tooltipStyles: CSSProperties = {
  backgroundColor: theme.color.background.ui05,
  color: theme.color.text.text04,
  position: "fixed",
  padding: `${theme.spacing.spacing01} ${theme.spacing.spacing02}`,
  fontSize: theme.fontSizes.sm,
  borderRadius: theme.radius.sm,
  zIndex: theme.zindex.dropdown,
};
