/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Provider, StatusValues } from "../types";

export const accountStatusIconProps = {
  [StatusValues.NoAccess]: {
    label: "No Access",
    description: "No access to the account",
    status: StatusValues.NoAccess,
    icon: "cloudOffline" as const,
    color: "danger" as const,
  },
  [StatusValues.NoData]: {
    label: "No Data",
    description: "No data has been received recently",
    status: StatusValues.BillingDataOnly,
    icon: "cloudQuestionMark" as const,
    color: undefined,
  },
  [StatusValues.BillingDataOnly]: {
    label: "Limited access",
    description: "Only basic billing data access",
    status: StatusValues.BillingDataOnly,
    icon: "cloudWithDollar" as const,
    color: "warning" as const,
  },
  [StatusValues.FullConnectivity]: {
    label: "Full access",
    description: "Complete account access",
    status: StatusValues.FullConnectivity,
    icon: "cloudOnline" as const,
    color: "success" as const,
  },
};

export const providerFilterIconProps = [
  {
    label: "AWS",
    provider: Provider.Aws as const,
    icon: "aws" as const,
  },
  {
    label: "Azure",
    provider: Provider.Azure as const,
    icon: "azure" as const,
  },
  {
    label: "Google Cloud",
    provider: Provider.Gcp as const,
    icon: "gcp" as const,
  },
];

export const staticChargeTypeOptions = [
  {
    value: "Total Cost",
    label: "Total Cost",
  },
];

export const tabDefinitions = [
  {
    name: Provider.Aws,
    count: null,
    cost: null,
  },
  {
    name: Provider.Azure,
    count: null,
    cost: null,
  },
  {
    name: Provider.Gcp,
    count: null,
    cost: null,
  },
  {
    name: Provider.Ibmcloud,
    count: null,
    cost: null,
  },
  {
    name: Provider.Kubernetes,
    count: null,
    cost: null,
  },
  {
    name: Provider.Openshift,
    count: null,
    cost: null,
  },
  {
    name: Provider.Vmware,
    count: null,
    // VMWare does not have costs
    cost: "N/A",
  },
];
