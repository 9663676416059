/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { dateFormat } from "~/constants";
import { generateNumericArray, getLastItem } from "~/tools";
import { ChartDates, Granularity, Period } from "../types";

type FormatDateArguments = {
  startDate: string;
  endDate: string;
  date: string;
  index: number;
  headers: string[];
  period: Period;
};

function formatDate({
  startDate,
  endDate,
  date,
  index,
  headers,
  period,
}: FormatDateArguments) {
  if (period !== Period.RANGE) {
    return `${dayjs(date).format(dateFormat.monthYearShortNoComma)}`;
  }

  const isMultipleMonths = headers.length > 1;
  const lastMonth = getLastItem(headers);
  const isDateMonthStart = isDateMonthStartDay(startDate);
  const isDateMonthEnd = isDateMonthEndDay(endDate);

  if (!isMultipleMonths) {
    return `${
      isDateMonthStart && isDateMonthEnd
        ? dayjs(date).format(dateFormat.monthYearShortNoComma)
        : formatSameDates(startDate, endDate)
    }
      `;
  }

  if (index === 0 && !isDateMonthStart) {
    return `${dayjs(startDate).format(dateFormat.dayShort)}-${dayjs(date)
      .endOf("month")
      .format(dateFormat.dayMonthYearShortNoComma)}`;
  }

  if (lastMonth === date && !isDateMonthEnd) {
    return `${dayjs(endDate)
      .startOf("month")
      .format(dateFormat.dayShort)}-${dayjs(endDate).format(
      dateFormat.dayMonthYearShortNoComma
    )}`;
  }

  return `${dayjs(date).format(dateFormat.monthYearShortNoComma)}`;
}

function formatSameDates(startDate: string, endDate: string) {
  return `${dayjs(startDate).format(dateFormat.dayShort)}
    -${dayjs(endDate).format(dateFormat.dayMonthYearShortNoComma)}`;
}

function isDateMonthStartDay(date: string) {
  return dayjs(date).isSame(dayjs(date).startOf("month"), "day");
}

function isDateMonthEndDay(date: string) {
  return dayjs(date).isSame(dayjs(date).endOf("month"), "day");
}

export function formatChartDates(
  startDate: string,
  endDate: string,
  period: Period
) {
  const diff = Math.ceil(
    dayjs(endDate)
      .endOf("month")
      .diff(dayjs(startDate).startOf("month"), "months", true)
  );

  const headers = generateNumericArray(diff).map((index) =>
    dayjs(startDate)
      .startOf("month")
      .add(index, "month")
      .format(dateFormat.shortDate)
  );

  return headers.map((date, index) => {
    return {
      date,
      displayDate: formatDate({
        startDate,
        endDate,
        date,
        index,
        headers,
        period,
      }),
    };
  });
}

export function formatDisplayDate(date: string, granularity: Granularity) {
  return dayjs(date).format(
    granularity === "days"
      ? dateFormat.dayMonthYearShortNoComma
      : dateFormat.monthYearShortNoComma
  );
}

export function getRangeDate(rangeDates: ChartDates[], date: string) {
  return rangeDates
    ?.filter(({ date: rangeDate }) => {
      return date === rangeDate;
    })
    .map((d) => d.displayDate);
}
