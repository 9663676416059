/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme } from "@nordcloud/gnui";

export const CostSectionTooltipWrapper = styled.div`
  > * {
    display: block !important;
    text-align: right;
    > div {
      text-align: center;
    }
  }
  position: relative !important;
  top: ${theme.spacing.spacing00} !important;
`;
