/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ChartType } from "~/components";

export const applicationsChartTypeOptions = [
  {
    value: ChartType.COST_PER_APPLICATION,
    label: ChartType.COST_PER_APPLICATION,
  },
  { value: ChartType.ACCUMULATED_COST, label: ChartType.ACCUMULATED_COST },

  { value: ChartType.COST_PER_CATEGORY, label: ChartType.COST_PER_CATEGORY },
  { value: ChartType.COST_PER_SERVICES, label: ChartType.COST_PER_SERVICES },
];
