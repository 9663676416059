/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as yup from "yup";
import { FormFields } from "../../types";

export const addAwsSchema = yup.object().shape({
  [FormFields.ACCOUNT_IDS]: yup
    .array()
    .of(yup.string().min(1, "ID must not be an empty string"))
    .min(1, "At least one ID must be provided")
    .required("This field is required"),
  [FormFields.ACCOUNT_OWNER]: yup.string().required("This field is required"),
  [FormFields.ROLE_EXTERNAL_ID]: yup
    .string()
    .required("This field is required"),
  [FormFields.ROLE_NAME]: yup.string().required("This field is required"),
});
