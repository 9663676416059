/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { If, Then, Else } from "react-if";
import { SVGIcon, Text, Tooltip, theme } from "@nordcloud/gnui";
import { CostSectionTooltipWrapper, GridDisplay } from "~/components";
import { childColumnProps } from "../../types";

type Props = {
  childHeaders?: childColumnProps[];
};

export function CoverageTableChildHeader({ childHeaders }: Props) {
  return (
    <GridDisplay
      columnNo={childHeaders?.length}
      css={{ marginRight: "0rem", padding: "0rem" }}
    >
      {childHeaders?.map((childElement: childColumnProps) => (
        <CostSectionTooltipWrapper key={childElement.accessor}>
          <If condition={childElement.tooltipText}>
            <Then>
              <Tooltip
                caption={childElement.tooltipText}
                css={{ display: "block!important" }}
              >
                <Text
                  align={childElement.alignment}
                  size="sm"
                  color={theme.color.text.text02}
                  mb={0}
                  tag="span"
                >
                  {childElement.headerName}
                </Text>
                <SVGIcon
                  name="info"
                  size="sm"
                  color="black"
                  css={{
                    marginLeft: theme.spacing.spacing01,
                    marginRight: theme.spacing.spacing01,
                  }}
                />
              </Tooltip>
            </Then>
            <Else>
              <Text
                align={childElement.alignment}
                size="sm"
                color={theme.color.text.text02}
                mb={0}
              >
                {childElement.headerName}
              </Text>
            </Else>
          </If>
        </CostSectionTooltipWrapper>
      ))}
    </GridDisplay>
  );
}
