/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ObservableQuery } from "@apollo/client";
import {
  CloudAccountCostsByProviderResponse,
  CloudAccountsDocument,
  useAddAccountV2Mutation,
} from "~/generated/graphql";
import { useQueryState } from "~/hooks";
import { useCloudAccountSort } from "../../hooks";
import { CloudAccountsQueryState, Provider } from "../../types";
import { getAccountFilters, getAccountStatusFilter } from "../../utils";

export function useAddAccount(provider: Provider) {
  const {
    state: { status, query, page, limit, order, chargeType, providerFilter },
  } = useQueryState<CloudAccountsQueryState>();

  const { sortDirection, sortField } = useCloudAccountSort({
    chargeType,
    order,
  });

  const [addAccount, { loading }] = useAddAccountV2Mutation({
    onQueryUpdated: delayRefetchedQuery,
    update: (cache, mutationResult) => {
      if (mutationResult.data?.addAccountV2?.id) {
        cache.modify({
          fields: {
            cloudAccountCostsByProvider(
              value: CloudAccountCostsByProviderResponse
            ) {
              const costsByProvider = value?.costsByProvider?.map((cost) => {
                if (cost.provider === provider) {
                  return {
                    ...cost,
                    count: cost.count + 1,
                  };
                }

                return cost;
              });

              return {
                ...value,
                costsByProvider,
              };
            },
          },
        });
      }
    },
    refetchQueries: [
      {
        query: CloudAccountsDocument,
        variables: {
          limit,
          page,
          order: {
            order: sortDirection,
            field: sortField,
            chargeType,
          },
          filter: {
            ...getAccountFilters(provider, providerFilter),
            ...getAccountStatusFilter(provider, status ?? []),
          },
          query: typeof query === "number" ? String(query) : query,
        },
      },
    ],
  });

  return { addAccount, loading };
}

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

// This delay is needed because after adding the account, refetching data right
// away does not get the latest account from DB
const delayRefetchedQuery = async (observableQuery: ObservableQuery) => {
  await wait(1500);
  await observableQuery.refetch();
};
