/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import {
  useChargeTypesQuery,
  useCloudAccountCostsByProviderQuery,
  useCloudAccountsQuery,
} from "~/generated/graphql";
import { SortDirection } from "~/components";
import { useQueryState } from "~/hooks";
import { isNil, isNotEmpty, isNotNil } from "~/tools";
import {
  staticChargeTypeOptions,
  tabDefinitions,
} from "./CloudAccountList/constants";
import {
  CloudAccountsQueryState,
  CloudAccountsResponse,
  MovedCloudAccountTotalCosts,
  Provider,
  SearchSortOrder,
} from "./types";
import {
  extractMovedCostsForProvider,
  getAccountFilters,
  getAccountStatusFilter,
  getSortFieldByChargeType,
  sortTabData,
} from "./utils";

export function useCostsByProvider(movedCosts: MovedCloudAccountTotalCosts) {
  const { data, loading, error } = useCloudAccountCostsByProviderQuery({
    skip: Object.values(movedCosts).some(isNil),
  });

  const tabs = useMemo(() => {
    const costsByProvider =
      data?.cloudAccountCostsByProvider?.costsByProvider ?? [];

    const result = tabDefinitions.map((tabDefinition) => {
      const tabData = costsByProvider.find(
        (costByProvider) => costByProvider.provider === tabDefinition.name
      );

      const isVMWare = tabData?.provider === Provider.Vmware;
      const cost = isVMWare
        ? tabDefinition.cost
        : tabData?.cost || tabDefinition.cost;

      return tabData
        ? {
            name: tabData.provider,
            count: tabData.count,
            cost,
            ...extractMovedCostsForProvider(tabData.provider, movedCosts),
          }
        : tabDefinition;
    });

    return sortTabData(result);
  }, [data, movedCosts]);

  return {
    loading,
    error,
    tabs,
  };
}

export function useCloudAccountsFetch() {
  const { state } = useQueryState<CloudAccountsQueryState>();

  const {
    provider,
    status,
    query,
    page,
    limit,
    order,
    chargeType,
    providerFilter,
  } = state;

  const { sortDirection, sortField } = useCloudAccountSort({
    chargeType,
    order,
  });
  const { data, error, loading } = useCloudAccountsQuery({
    skip: !provider,
    variables: {
      limit,
      page,
      order: {
        order: sortDirection,
        field: sortField,
        chargeType,
      },
      filter: {
        ...getAccountFilters(provider, providerFilter),
        ...getAccountStatusFilter(provider, status ?? []),
      },
      query: typeof query === "number" ? String(query) : query,
    },
  });

  return {
    data: data?.cloudAccounts as CloudAccountsResponse,
    error,
    loading,
  };
}

type CloudAccountSortProps = {
  chargeType?: string;
  order?: SearchSortOrder;
};

export function useCloudAccountSort({
  chargeType,
  order,
}: CloudAccountSortProps) {
  const { state, updateQueryState } = useQueryState<CloudAccountsQueryState>();

  const sortParams = useMemo(() => {
    return {
      field: getSortFieldByChargeType(chargeType),
      order: order || SearchSortOrder.Dsc,
      chargeType: chargeType ?? staticChargeTypeOptions[0].value,
    };
  }, [order, chargeType]);

  const onSortSelect = (filter: string) => {
    updateQueryState({
      ...state,
      chargeType: filter,
      order: SearchSortOrder.Dsc,
      page: 0,
    });
  };

  const onSortOrderChange = (direction: SortDirection) => {
    updateQueryState({
      ...state,
      chargeType,
      order:
        direction === SortDirection.Ascending
          ? SearchSortOrder.Asc
          : SearchSortOrder.Dsc,
      page: 0,
    });
  };

  return {
    sortField: sortParams.field,
    sortDirection: sortParams.order,
    chargeType: sortParams.chargeType,
    onSortSelect,
    onSortOrderChange,
  };
}

export function useChargeTypes(provider: Provider) {
  const {
    data: chargeTypes,
    loading: isLoadingChargeTypes,
    error: chargeTypesError,
  } = useChargeTypesQuery({
    skip: !provider,
    variables: {
      provider,
    },
  });

  const chargeTypesResult = chargeTypes?.chargeTypes?.chargeTypes
    ?.map((chargeType) => chargeType.name)
    .filter((chargeType) => isNotNil(chargeType) && isNotEmpty(chargeType));

  return {
    chargeTypes: chargeTypesResult ?? [],
    isLoadingChargeTypes,
    chargeTypesError,
  };
}
