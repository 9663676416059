/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import { Box, theme } from "@nordcloud/gnui";
import {
  WorkflowEventInListSortingFields,
  WorkflowEventStatus,
  WorkflowSortingOrder,
} from "~/generated/graphql";
import {
  ReactTableResizeable,
  UniversalWrap,
  DataDisplayWrapper,
  TableLoader,
} from "~/components";
import { isNotEmpty } from "~/tools";
import {
  useWorkflowEventDetails,
  useWorkflowHistoryPagination,
} from "../hooks";
import { WorkflowsHistoryColumns } from "./WorkflowHistoryColumns";
import { WorkflowHistoryPagination } from "./WorkflowHistoryPagination";
import { WorkflowHistorySidebar } from "./WorkflowHistorySidebar";

type Props = {
  workflowId?: string;
  sortField?: WorkflowEventInListSortingFields;
  sortOrder?: WorkflowSortingOrder;
  status?: WorkflowEventStatus;
};

export function WorkflowHistoryList({
  workflowId,
  sortField,
  sortOrder,
  status,
}: Props) {
  const {
    isOpen,
    handleClose,
    setCurrentEvent,
    data: eventData,
    loading: isWorkflowEventDetailsLoading,
    error: eventError,
  } = useWorkflowEventDetails();

  const columns = useMemo(
    () => WorkflowsHistoryColumns({ workflowId, setCurrentEvent }),
    [workflowId, setCurrentEvent]
  );

  const {
    setHistoryItemsLimit,
    selectedLimit,
    prev: onClickPrevious,
    next: onClickNext,
    disablePrev,
    disableNext,
    data,
    loading,
    error,
    pageLimits,
  } = useWorkflowHistoryPagination({
    workflowId,
    sortField,
    sortOrder,
    status,
  });

  return (
    <>
      <UniversalWrap
        loaderProps={{
          loading,
          Component: Loader,
        }}
        errorProps={{
          error,
        }}
      >
        <DataDisplayWrapper hasData={isNotEmpty(data)}>
          <ReactTableResizeable striped columns={columns} data={data} />
        </DataDisplayWrapper>
      </UniversalWrap>
      <Box boxStyle="lightGrey" spacing="spacing04">
        <WorkflowHistoryPagination
          limitValues={pageLimits}
          selectedLimit={selectedLimit}
          setLimit={setHistoryItemsLimit}
          disablePrev={disablePrev}
          disableNext={disableNext || loading}
          onClickPrevious={onClickPrevious}
          onClickNext={onClickNext}
        />
      </Box>
      <WorkflowHistorySidebar
        isOpen={isOpen}
        data={eventData}
        loading={isWorkflowEventDetailsLoading}
        error={eventError}
        onClose={handleClose}
      />
    </>
  );
}

function Loader() {
  return (
    <TableLoader
      gapY={10}
      gapX={6}
      css={{
        marginTop: theme.spacing.spacing08,
      }}
      rows={[
        {
          count: 8,
          height: 80,
          items: [
            {
              widthPercent: 25,
            },
            {
              widthPercent: 10,
            },
            {
              widthPercent: 10,
            },
            {
              widthPercent: 20,
            },
            {
              widthPercent: 35,
            },
          ],
        },
      ]}
    />
  );
}
