/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useApolloClient } from "@apollo/client";
import {
  AwsBillingDataDocument,
  AwsBillingDataQuery,
  AwsBillingDataQueryVariables,
  useAddAwsCapacityMutation,
} from "~/generated/graphql";
import { FormData } from "~/views/cloudAccounts/BillingData/aws/validators";

export function useAddAwsBillingData() {
  const client = useApolloClient();
  const [addAwsCapacity, { loading, error }] = useAddAwsCapacityMutation();

  const addAwsBillingData = async (data: FormData) => {
    const response = await addAwsCapacity({ variables: { input: data } });

    if (response) {
      client.cache.updateQuery<
        AwsBillingDataQuery,
        AwsBillingDataQueryVariables
      >(
        {
          query: AwsBillingDataDocument,
        },
        (billingData) => ({
          awsBillingData: [
            ...(billingData?.awsBillingData ?? []),
            { accountId: data.accountId },
          ],
        })
      );
    }
  };

  return { addAwsBillingData, loading, error };
}
