/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useApplicationsPaginatedByApplicationNameLazyQuery } from "~/generated/graphql";
import { getFirstItem } from "~/tools";
import { formatDataObject } from "~/utils";
import {
  generateSelectedApps,
  generateSelectedEnvs,
} from "../AppEnvSelectorPaginated/utils";
import { ApplicationOption, EnvironmentOption } from "../types";

type Props = {
  elementRef: MutableRefObject<null>;
  selectedApplications: string[];
  selectedEnvironments: string[];
  applicationName: string;
  setSelectedApps: Dispatch<SetStateAction<ApplicationOption[]>>;
  setSelectedEnvs: Dispatch<SetStateAction<EnvironmentOption[]>>;
};

export function useInfiniteApplications({
  elementRef,
  selectedApplications,
  selectedEnvironments,
  applicationName,
  setSelectedApps,
  setSelectedEnvs,
}: Props) {
  const pageSize = 20;
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [applications, setApplications] = useState<ApplicationOption[]>([]);

  const [getApplications, { data, loading: isAppsLoading }] =
    useApplicationsPaginatedByApplicationNameLazyQuery({
      variables: {
        limit: pageSize,
        page,
        applicationName,
      },
    });

  useEffect(() => {
    setSelectedApps(generateSelectedApps(selectedApplications));
    setSelectedEnvs(generateSelectedEnvs(selectedEnvironments));
  }, []);

  const fetchData = async () => {
    await getApplications();
    setPage((prevPage) => prevPage + 1);
    setHasMore(
      Number(data?.applicationsPaginated?.count) > applications.length
    );
  };

  useEffect(() => {
    setPage(0);
    setApplications([]);
  }, [applicationName]);

  function onIntersection(entries: IntersectionObserverEntry[]) {
    const firstEntry = getFirstItem(entries);
    if (firstEntry.isIntersecting && hasMore) {
      void fetchData();
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver(onIntersection);
    if (observer && elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [elementRef]);

  useEffect(() => {
    const applicationsPaginated = formatDataObject(data);
    setApplications((prevApplications) => {
      const newApplications =
        applicationsPaginated?.applicationsPaginated?.results ?? [];
      return [...prevApplications, ...newApplications];
    });
  }, [data]);

  return {
    isAppsLoading,
    applications,
  };
}
