/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { theme, Spacer } from "@nordcloud/gnui";
import {
  ReservationsAndCommitmentsCoverageOfferingType as CoverageOfferingType,
  ReservationsAndCommitmentsCoverageServiceUsageType,
  ReservationsAndCommitmentsFilters,
  ReservationsAndCommitmentsLookbackPeriod,
  ReservationsProvider,
  ReservationsRecommendationPaymentOption,
  ReservationsRecommendationTerm,
} from "~/generated/graphql";
import { isNotEmpty } from "~/tools";
import { RecommendationsSidebar, CoverageTable } from "./components";
import { PaymentOptionsFilter, UsageCoverageData } from "./types";

type Props = {
  idText: string;
  paymentOption: ReservationsRecommendationPaymentOption;
  termOption: ReservationsRecommendationTerm;
  coverageDetails: UsageCoverageData;
  serviceType: ReservationsAndCommitmentsCoverageServiceUsageType;
  currentOfferingType: CoverageOfferingType;
  timePeriod: ReservationsAndCommitmentsLookbackPeriod;
  selectedFilters: ReservationsAndCommitmentsFilters;
  provider: ReservationsProvider;
  isSidebarOpen: boolean;
  onOpenSidebar: (coverageData: UsageCoverageData) => void;
  onToggleSidebar: () => void;
  paymentOptionsFilter: PaymentOptionsFilter[];
  hasGroupByParameter: boolean;
};

export function ReservationsCoverageTable({
  paymentOption,
  termOption,
  coverageDetails,
  serviceType,
  idText,
  currentOfferingType,
  selectedFilters,
  provider,
  timePeriod,
  isSidebarOpen,
  paymentOptionsFilter,
  hasGroupByParameter,
  onToggleSidebar,
  onOpenSidebar,
}: Props) {
  return (
    <div id={`uf-${idText}-usage-details`}>
      <CoverageTable
        paymentOption={paymentOption}
        termOption={termOption}
        serviceUsageType={serviceType}
        offeringType={currentOfferingType}
        timePeriod={timePeriod}
        filters={selectedFilters}
        provider={provider}
        hasGroupByParameter={hasGroupByParameter}
        openSidebar={onOpenSidebar}
      />
      <Spacer height={theme.spacing.spacing07} />

      <When condition={isNotEmpty(coverageDetails.resource ?? "")}>
        <RecommendationsSidebar
          toggleSidebar={onToggleSidebar}
          isSidebarOpen={isSidebarOpen}
          sidebarData={coverageDetails}
          timePeriod={timePeriod}
          provider={provider}
          paymentOptionsFilter={paymentOptionsFilter}
          type={CoverageOfferingType.ReservedInstances}
        />
      </When>
    </div>
  );
}
