/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Text } from "@nordcloud/gnui";

export function BillingDataList({ children }: { children: JSX.Element }) {
  return (
    <>
      <Text weight="medium">
        List of Cloud Accounts where CUR files are located
      </Text>
      {children}
    </>
  );
}
