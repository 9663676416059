/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Maybe } from "graphql/jsutils/Maybe";
import { isNotNil } from "~/tools";
import { generateSelectedEnvs } from "../AppEnvSelectorPaginated/utils";
import { ApplicationOption, EnvironmentOption } from "../types";

type Props = {
  apps: Maybe<ApplicationOption[]>;
  selectedApps: ApplicationOption[];
  setSelectedApps: (value: React.SetStateAction<ApplicationOption[]>) => void;
  selectedEnvs: EnvironmentOption[];
  setSelectedEnvs: (value: React.SetStateAction<EnvironmentOption[]>) => void;
};

export function useAppEnvFilterSelector({
  apps = [],
  selectedApps,
  setSelectedApps,
  selectedEnvs,
  setSelectedEnvs,
}: Props) {
  function findParentApp(env: EnvironmentOption) {
    return apps?.find((app) =>
      app.environments.find(({ nid }) => nid === env.nid)
    );
  }
  function removeAppFromSelectedApps(appNid: string) {
    setSelectedApps((prevState) =>
      prevState.filter(({ nid }) => nid !== appNid)
    );
  }

  function addAppToSelectedApps(app: ApplicationOption) {
    setSelectedApps((prevState) => [...prevState, app]);
  }

  function addEnvToSelectedEnvs(env: EnvironmentOption) {
    setSelectedEnvs((prevState) => [...prevState, env]);
  }

  function removeEnvFromSelectedEnvs(envNid: string) {
    setSelectedEnvs((prevState) =>
      prevState.filter(({ nid }) => nid !== envNid)
    );
  }

  function removeChildEnvsFromSelectedEnvs(app: ApplicationOption) {
    setSelectedEnvs((prevState) => {
      return prevState.filter((prevEnv) =>
        app.environments.every(
          (parentEnv) => parentEnv && parentEnv.nid !== prevEnv.nid
        )
      );
    });
  }

  function toggleOnPeerEnvs(
    parentApp: ApplicationOption,
    env: EnvironmentOption
  ) {
    // Toggle on other envs belonging to parent app
    const selectedEnvNids =
      parentApp.environments
        .map((parentEnv) => {
          if (parentEnv && parentEnv.nid !== env.nid) {
            return parentEnv.nid;
          }

          return undefined;
        })
        .filter(isNotNil) ?? [];
    setSelectedEnvs(generateSelectedEnvs(selectedEnvNids));
  }

  function allEnvsSelected(
    parentApp: ApplicationOption,
    env: EnvironmentOption
  ) {
    return parentApp?.environments.every((parentEnv) =>
      [...selectedEnvs, env].some(
        (selectedEnv) => selectedEnv.nid === parentEnv?.nid
      )
    );
  }

  const selectApp = (appNid: string) => {
    // When app is toggled on/off, remove all of its envs from selected envs
    const app = findByNid(apps, appNid);
    if (app) {
      if (findByNid(selectedApps, appNid)) {
        removeAppFromSelectedApps(appNid);
      } else {
        addAppToSelectedApps(app);
      }

      if (app.environments) {
        removeChildEnvsFromSelectedEnvs(app);
      }
    }
  };

  const selectEnv = (env: EnvironmentOption) => {
    const parentApp = findParentApp(env);
    const isParentAppSelected =
      parentApp?.nid && findByNid(selectedApps, parentApp.nid);
    const isEnvSelected = findByNid(selectedEnvs, env.nid);

    if (isEnvSelected || isParentAppSelected) {
      if (parentApp && isParentAppSelected) {
        removeAppFromSelectedApps(parentApp?.nid ?? "");
        toggleOnPeerEnvs(parentApp, env);
      } else {
        removeEnvFromSelectedEnvs(env.nid);
      }
    } else if (parentApp && allEnvsSelected(parentApp, env)) {
      addAppToSelectedApps(parentApp);
      removeChildEnvsFromSelectedEnvs(parentApp);
    } else {
      addEnvToSelectedEnvs(env);
    }
  };

  return {
    selectApp,
    selectEnv,
  };
}

function findByNid<T extends { nid: string }>(
  collection: T[] | null,
  nid: string
) {
  return collection?.find((item) => item.nid === nid);
}
