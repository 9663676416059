/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCloudAccountCostsByChargeTypeQuery } from "~/generated/graphql";
import { Maybe, isNotNil } from "~/tools";
import { CloudProviderType, Provider, ProvidersForFiltering } from "../types";
import { getTotalCost } from "../utils";

type Props = {
  query: Maybe<string>;
  status: string[];
  provider: Provider;
  providerFilter: ProvidersForFiltering[];
};

export function useK8sOcTotalsByProvider({
  query,
  status,
  provider,
  providerFilter,
}: Props) {
  const shouldSkip = ![Provider.Kubernetes, Provider.Openshift].includes(
    provider
  );
  const providerType =
    provider === Provider.Kubernetes
      ? CloudProviderType.Kubernetes
      : CloudProviderType.Openshift;

  const { data: EKSCost } = useCloudAccountCostsByChargeTypeQuery({
    skip:
      shouldSkip ||
      (isNotNil(providerFilter) && !providerFilter.includes(Provider.Aws)),
    variables: {
      query,
      filter: {
        provider: [Provider.Aws],
        providerType: [providerType],
        status,
      },
    },
  });
  const { data: AKSCost } = useCloudAccountCostsByChargeTypeQuery({
    skip:
      shouldSkip ||
      (isNotNil(providerFilter) && !providerFilter.includes(Provider.Azure)),
    variables: {
      query,
      filter: {
        provider: [Provider.Azure],
        providerType: [providerType],
        status,
      },
    },
  });
  const { data: GKECost } = useCloudAccountCostsByChargeTypeQuery({
    skip:
      shouldSkip ||
      (isNotNil(providerFilter) && !providerFilter.includes(Provider.Gcp)),
    variables: {
      query,
      filter: {
        provider: [Provider.Gcp],
        providerType: [providerType],
        status,
      },
    },
  });
  const { data: K8sOcCost } = useCloudAccountCostsByChargeTypeQuery({
    skip: shouldSkip,
    variables: {
      query,
      filter: {
        provider: [Provider.Hybrid],
        providerType: [providerType],
        status,
      },
    },
  });

  const EKSTotalCost = getTotalCost(
    EKSCost?.cloudAccountCostsByChargeType?.costsByChargeType
  );
  const AKSTotalCost = getTotalCost(
    AKSCost?.cloudAccountCostsByChargeType?.costsByChargeType
  );
  const GKETotalCost = getTotalCost(
    GKECost?.cloudAccountCostsByChargeType?.costsByChargeType
  );
  const K8sOcTotalCost = getTotalCost(
    K8sOcCost?.cloudAccountCostsByChargeType?.costsByChargeType
  );

  return {
    eks: {
      cost: EKSTotalCost,
      icon: "aws",
      chargeType: Provider.Aws,
    },
    aks: {
      cost: AKSTotalCost,
      icon: "azure",
      chargeType: Provider.Azure,
    },
    gke: {
      cost: GKETotalCost,
      icon: "gcp",
      chargeType: Provider.Gcp,
    },
    k8sOc: {
      cost: K8sOcTotalCost,
      icon:
        providerType === CloudProviderType.Kubernetes
          ? "kubernetes"
          : "openshift",
      chargeType:
        providerType === CloudProviderType.Kubernetes
          ? Provider.Kubernetes
          : CloudProviderType.Openshift,
    },
  };
}
