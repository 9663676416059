/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Maybe } from "~/tools";

export type Person = {
  id: string;
  name: string;
  email: string;
};

export enum SearchSortOrder {
  Asc = "ASC",
  Dsc = "DSC",
}

export enum CloudProviderType {
  Cloud = "CLOUD",
  Kubernetes = "KUBERNETES",
  Openshift = "OPENSHIFT",
  Vmware = "VMWARE",
}

export enum CloudAccountsSortField {
  ChargeType = "CHARGE_TYPE",
  TotalCost = "TOTAL_COST",
}

export const enum CloudAccountStatus {
  Active = "active",
  Inactive = "inactive",
  Discovered = "discovered",
}

export enum Provider {
  Aws = "AWS",
  Azure = "AZURE",
  External = "EXTERNAL",
  Gcp = "GCP",
  Hybrid = "HYBRID",
  Ibmcloud = "IBMCLOUD",
  Kubernetes = "KUBERNETES",
  Nordcloud = "NORDCLOUD",
  Openshift = "OPENSHIFT",
  Services = "SERVICES",
  Vmware = "VMWARE",
}

export type CloudAccount = {
  id: string;
  nid: string;
  provider: Provider;
  providerId: string;
  name: string;
  displayName?: Maybe<string>;
  description?: Maybe<string>;
  activated: boolean;
  metadata?: Maybe<string>;
  chargeTypeCosts: ChargeTypeCost[];
  status?: Maybe<string>;
  providerType?: CloudProviderType;
  contacts?: Maybe<Maybe<Person>[]>;
  owner?: Maybe<Person>;
};

type ChargeTypeCost = {
  chargeType: string;
  cost: string;
};

export type CloudAccountsResponse = {
  accounts: CloudAccount[];
  count: number;
  posts: string;
  pages: number;
};

export type CloudSummaryTabData = {
  name: string;
  movedToK8s?: number;
  movedToOc?: number;
  count?: Maybe<number>;
  cost?: Maybe<string>;
};

export enum StatusValues {
  FullConnectivity = "fullConnectivity",
  BillingDataOnly = "billingDataOnly",
  NoAccess = "noAccess",
  NoData = "noData",
}

export type ProviderStatus = {
  [StatusValues.FullConnectivity]: boolean;
  [StatusValues.BillingDataOnly]: boolean;
  [StatusValues.NoAccess]: boolean;
  [StatusValues.NoData]: boolean;
};

export type ProviderFilterValues = {
  [Provider.Aws]: boolean;
  [Provider.Azure]: boolean;
  [Provider.Gcp]: boolean;
};

export type ProvidersForFiltering = Extract<
  Provider,
  Provider.Aws | Provider.Azure | Provider.Gcp
>;

export type StatusFilterCheckboxProps = {
  label: string;
  description: string;
  status: StatusValues;
  icon:
    | "cloudOffline"
    | "cloudOnline"
    | "cloudQuestionMark"
    | "cloudWithDollar";
  color: "danger" | "success" | "warning" | undefined;
};

export type CloudAccountsQueryState = {
  provider: Provider;
  status: string[];
  providerFilter: ProvidersForFiltering[];
  page: number;
  limit: number;
  query?: Maybe<string>;
  chargeType?: string;
  order?: SearchSortOrder;
};

export type AccountOwner = Maybe<{ id: string; name: string; email: string }>;

export type ContactPeople = { id: string; name: string; email: string }[];

export type MovedCloudAccountTotalCosts = {
  movedToK8sFromAwsTotalCost: number | undefined;
  movedToOcFromAwsTotalCost: number | undefined;
  movedToK8sFromGcpTotalCost: number | undefined;
  movedToOcFromGcpTotalCost: number | undefined;
  movedToK8sFromAzureTotalCost: number | undefined;
  movedToOcFromAzureTotalCost: number | undefined;
  movedToK8sFromIBMCloudTotalCost: number | undefined;
  movedToOcFromIBMCloudTotalCost: number | undefined;
};

export enum FormFields {
  ACCOUNT_IDS = "accountIds",
  ACCOUNT_OWNER = "accountOwner",
  ROLE_EXTERNAL_ID = "roleExternalID",
  ROLE_NAME = "roleName",
}
