/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ROUTES } from "~/routing/routes";
import { ACTION, ACTIONS } from "~/services/auth";
import { PathName } from "~/tools";
import { getNotificationConfig, NotificationsConfigProps } from "./helpers";
import {
  BusinessContextSubmenu,
  BusinessContextItem,
  EmployeesSubmenu,
  SettingsSubmenu,
  Submenu,
  ReservationsSubmenu,
} from "./submenus";

type Items = {
  caption: string;
  icon: PathName;
  route?: string;
  children?: React.ReactNode;
  protectedBy?: ACTION;
  featureFlag?: string;
  triggeredPaths?: string[];
  isMenuItemVisible?: boolean;
};

export const getItems = (
  bc: BusinessContextItem[],
  notificationsConfig: NotificationsConfigProps,
  isReservationsMenuItem: boolean
): Items[] => [
  {
    caption: "Dashboard",
    route: ROUTES.index,
    icon: "dashboard",
    protectedBy: ACTIONS.visitDashboard,
  },
  {
    caption: "Applications",
    route: ROUTES.applications.index,
    icon: "application",
  },
  {
    caption: "Business Context",
    icon: "flowchart",
    protectedBy: ACTIONS.visitBusinessContexts,
    triggeredPaths: [ROUTES.businessContexts.index],
    children: (
      <Submenu>
        <BusinessContextSubmenu businessContexts={bc} />
      </Submenu>
    ),
  },
  {
    caption: "Cost Analysis",
    route: ROUTES.costAnalysis.index,
    protectedBy: ACTIONS.visitCostAnalysis,
    icon: "costAnalysis",
  },
  {
    caption: "KPIs",
    route: ROUTES.finopsKpi.index,
    protectedBy: ACTIONS.visitKpi,
    icon: "chartAscending",
  },
  {
    caption: "Reservations And Commitments",
    icon: "chipWithDollar",
    protectedBy: ACTIONS.visitReservations,
    isMenuItemVisible: isReservationsMenuItem,
    triggeredPaths: [
      ROUTES.reservedInstances.index,
      ROUTES.reservedInstances.azureReservation,
    ],
    children: (
      <Submenu>
        <ReservationsSubmenu />
      </Submenu>
    ),
  },
  {
    caption: "Estate Records",
    route: ROUTES.estateRecords.index,
    icon: "resource",
    protectedBy: ACTIONS.visitEstateRecords,
  },
  {
    caption: "Estate Records v2",
    route: ROUTES.estateRecords.indexV2,
    icon: "resource",
    protectedBy: ACTIONS.visitEstateRecords,
    featureFlag: "estateRecordsV2",
  },
  {
    caption: "Cloud Accounts",
    route: ROUTES.accounts.index,
    icon: "cloud",
    protectedBy: ACTIONS.visitAccounts,
  },
  {
    caption: "Cloud Accounts V2",
    route: ROUTES.cloudAccounts.index,
    icon: "cloudOffline",
    protectedBy: ACTIONS.visitAccounts,
    featureFlag: "cloudAccounts",
  },
  {
    caption: "Settings",
    icon: "settings",
    triggeredPaths: [
      ROUTES.settings.cloudWaste,
      ROUTES.settings.costSplitting,
      ROUTES.settings.mapping,
      ROUTES.externalIntegrations.index,
      ROUTES.integrationPlugins.index,
      ROUTES.workflows.index,
    ],
    children: (
      <Submenu>
        <SettingsSubmenu />
      </Submenu>
    ),
  },
  {
    caption: "Employees",
    icon: "user",
    triggeredPaths: [ROUTES.settings.employees, ROUTES.settings.users],
    children: (
      <Submenu>
        <EmployeesSubmenu />
      </Submenu>
    ),
  },
  {
    caption: "Archive",
    route: ROUTES.archive,
    icon: "archive",
    protectedBy: ACTIONS.visitArchive,
  },
  {
    ...getNotificationConfig(notificationsConfig),
    route: ROUTES.notifications.index,
    protectedBy: ACTIONS.visitNotifications,
  },
];
