/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useResourceCostSplitRulesQuery } from "~/generated/graphql";
import { getFirstItem, Maybe } from "~/tools";
import { isGreaterThan } from "../../helpers";

export function useCostSplittingRules(estateRecordId: Maybe<string>) {
  const {
    data: costSplitRules,
    refetch,
    loading,
  } = useResourceCostSplitRulesQuery({
    variables: {
      limit: 1,
      nid: estateRecordId ?? "",
    },
    skip: estateRecordId === undefined,
  });

  const resourceCostSplitRulesExists = isGreaterThan(
    costSplitRules?.resourceCostSplitRules.count,
    0
  );

  const firstResult = getFirstItem(
    costSplitRules?.resourceCostSplitRules.result ?? []
  );
  const resourceCostSplitRuleId = firstResult?.id;

  return {
    resourceCostSplitRulesExists,
    resourceCostSplitRuleId,
    costSplitRules,
    refetch,
    loading,
  };
}
