/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { noop } from "@tanstack/react-table";
import { When } from "react-if";
import styled from "styled-components";
import { FlexContainer, Checkbox, Text, SVGIcon } from "@nordcloud/gnui";
import { SidebarItemWrapper } from "~/components";
import { useToggle } from "~/hooks";
import { SwitchWrap } from "../AppEnvSelector/styles";
import { ApplicationOption, EnvironmentOption } from "../types";
import { EnvironmentsList } from "./EnvironmentsList";

type Props = {
  application: ApplicationOption;
  selectedApps: ApplicationOption[];
  selectedEnvs: EnvironmentOption[];
  disabled: boolean;
  onMultiSelect: () => void;
  onAppCheck: (appNid: string) => void;
  onEnvCheck: (env: EnvironmentOption) => void;
};

export function SelectableApplicationRow({
  application,
  onAppCheck,
  onEnvCheck,
  selectedApps,
  selectedEnvs,
  disabled = false,
}: Props) {
  const [isRowOpen, toggleRowOpen] = useToggle(false);
  const { environments, name, nid } = application;
  const envCount = environments.length;
  const isAppChecked = selectedApps.some(
    (selectedApp) => selectedApp.nid === nid
  );

  return (
    <>
      <FlexContainer>
        <StyledSVGIcon
          isOpen={isRowOpen}
          disabled={envCount === 0}
          name="chevronDown"
          data-testid="app-expander"
          onClick={toggleRowOpen}
        />
        <FlexContainer direction="column" alignItems="flex-start">
          <SidebarItemWrapper
            hideBottomBorder
            title={name}
            disabled={disabled}
            onClick={noop}
          >
            <SwitchWrap>
              <Checkbox
                withoutLabel
                data-testid="app-filter-checkbox"
                checked={isAppChecked}
                onChange={() => onAppCheck(nid)}
              />
            </SwitchWrap>
            <Text marginBottom="0">{name}</Text>
          </SidebarItemWrapper>
        </FlexContainer>
      </FlexContainer>
      <When condition={isRowOpen}>
        <EnvironmentsList
          application={application}
          selectedEnvs={selectedEnvs}
          isAppChecked={isAppChecked}
          disabled={disabled}
          onEnvCheck={onEnvCheck}
        />
      </When>
    </>
  );
}

const StyledSVGIcon = styled(SVGIcon)<{ isOpen: boolean; disabled?: boolean }>`
  cursor: pointer;
  transition: transform 0.1s linear;
  transform: ${({ isOpen }) => (isOpen ? "rotate(0deg)" : "rotate(-90deg)")};
  visibility: ${({ disabled = false }) => (disabled ? "hidden" : "visible")};
`;
