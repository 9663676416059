/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { If, Then, Else } from "react-if";
import { Link, generatePath } from "react-router-dom";
import { Cell, Row } from "react-table";
import { FlexContainer, Text, Button, theme } from "@nordcloud/gnui";
import { WorkflowEvent } from "~/generated/graphql";
import { dateFormat } from "~/constants";
import { ROUTES } from "~/routing/routes";
import { EventStatus } from "../constants";
import { calculateRunDuration } from "../utils";
import { TriggerList } from "../WorkflowsList/TriggerList";
import { StatusIcon } from "./WorkflowHistorySidebar";

type WorkflowIdProp = {
  workflowId?: string;
  setCurrentEvent: (eventId: string) => void;
};

enum ColumnWidths {
  STATUS_NARROW = 13,
  STATUS_WIDE = 15,
  START_DATE = 8,
  DURATION = 5,
  TRIGGER = 14,
  RESULTS = 20,
  DETAILS = 1,
}

export function WorkflowsHistoryColumns({
  workflowId,
  setCurrentEvent,
}: WorkflowIdProp) {
  const statusColumnWidth = workflowId ? "STATUS_NARROW" : "STATUS_WIDE";

  function getWidth(columnName: keyof typeof ColumnWidths) {
    const width = ColumnWidths[columnName];

    return {
      width,
      maxWidth: width,
    };
  }

  return [
    {
      accessor: "statusNameDesc",
      Header: workflowId ? "Status" : "Status / Workflow Name / Description",
      ...getWidth(statusColumnWidth),
      Cell: ({ row }: Cell<WorkflowEvent>) => {
        const status = row.original.status;

        return (
          <FlexContainer css={{ height: "100%" }} alignContent="center">
            <StatusIcon status={status} />
            <StatusText workflowId={workflowId} row={row} />
          </FlexContainer>
        );
      },
    },
    {
      accessor: "startDate",
      Header: "Start Date",
      ...getWidth("START_DATE"),
      Cell: ({ row }: Cell<WorkflowEvent>) => (
        <FlexContainer
          css={{ height: "100%", width: "100%" }}
          alignContent="center"
        >
          <Text align="center">
            {dayjs(row.original.startTime).format(dateFormat.fullDateShort)}
          </Text>
        </FlexContainer>
      ),
    },
    {
      accessor: "duration",
      Header: "Duration",
      ...getWidth("DURATION"),
      Cell: ({ row }: Cell<WorkflowEvent>) => (
        <FlexContainer css={{ height: "100%" }} alignContent="center">
          <Text align="center">
            {calculateRunDuration(row.original.startTime, row.original.endTime)}
          </Text>
        </FlexContainer>
      ),
    },
    {
      accessor: "trigger",
      Header: "Trigger",
      ...getWidth("TRIGGER"),
      Cell: ({ row }: Cell<WorkflowEvent>) => (
        <TriggerList triggers={[row.original.trigger]} active />
      ),
    },
    {
      accessor: "results",
      Header: "Failure reason",
      ...getWidth("RESULTS"),
      Cell: ({ row }: Cell<WorkflowEvent>) => {
        const { result, status } = row.original;
        const failureReason =
          EventStatus[status] === EventStatus.FAILED ? result : "";

        return (
          <FlexContainer
            css={{ height: "100%", width: "100%" }}
            justifyContent="flex-start"
            alignContent="center"
          >
            <Text size="sm">{failureReason}</Text>
          </FlexContainer>
        );
      },
    },
    {
      accessor: "details",
      ...getWidth("DETAILS"),
      Cell: ({ row }: Cell<WorkflowEvent>) => {
        return (
          <FlexContainer css={{ height: "100%" }} alignContent="center">
            <Button
              severity="low"
              icon="search"
              size="md"
              onClick={() => {
                setCurrentEvent(row.original.id);
              }}
            />
          </FlexContainer>
        );
      },
    },
  ];
}

type StatusTextProps = {
  workflowId?: string;
  row: Row<WorkflowEvent>;
};

function StatusText({ workflowId, row }: StatusTextProps) {
  return (
    <FlexContainer
      direction="column"
      alignItems="flex-start"
      css={{ wordBreak: "break-all" }}
    >
      <If condition={workflowId}>
        <Then>
          <Text align="center" mb="0">
            {EventStatus[row.original.status] === EventStatus.COMPLETED
              ? "Success"
              : "Failure"}
          </Text>
        </Then>
        <Else>
          <StatusTextTitle row={row} />
          <Text size="sm" color={theme.color.text.text02} mb="0">
            {row.original?.workflowDescription ?? ""}
          </Text>
        </Else>
      </If>
    </FlexContainer>
  );
}

type StatusTextTitleProps = {
  row: Row<WorkflowEvent>;
};

function StatusTextTitle({ row }: StatusTextTitleProps) {
  if (row.original.workflowDeleted) {
    return (
      <Text color={theme.color.text.text01} mb="0">
        {row.original.workflowName}
      </Text>
    );
  }

  return (
    <Link
      to={generatePath(ROUTES.workflows.details, {
        workflowId: row.original.workflowId,
      })}
    >
      <Text color={theme.color.text.info} mb="0">
        {row.original.workflowName}
      </Text>
    </Link>
  );
}
