/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FlexContainer, Checkbox, Text } from "@nordcloud/gnui";
import { Provider } from "~/generated/graphql";
import { Separator, SidebarItemWrapper } from "~/components";
import { noop } from "~/tools";
import { SwitchWrap } from "../AppEnvSelector/styles";
import { useProvidersFilter } from "../hooks";
import { EstateFilterHeader } from "./EstateFilterHeader";

type Props = {
  selectedProviders: Provider[];
  onProviderSelect: (provider: Provider[]) => void;
};

export function ProvidersFilter({
  selectedProviders,
  onProviderSelect,
}: Props) {
  const { selectedItems, filteredProviders, setSearch, onApply } =
    useProvidersFilter({
      selectedProviders,
      onProviderSelect,
    });

  return (
    <>
      <EstateFilterHeader showSearchBar onSetSearch={setSearch} />
      <FlexContainer
        direction="column"
        css={{ maxHeight: "20rem", overflowY: "scroll", overflowX: "hidden" }}
      >
        <Separator />
        {filteredProviders.map((provider) => (
          <SidebarItemWrapper
            key={provider}
            hideBottomBorder
            title={provider ?? ""}
            onClick={noop}
          >
            <SwitchWrap>
              <Checkbox
                withoutLabel
                checked={selectedItems?.includes(provider ?? "")}
                onChange={() => onApply(provider)}
              />
            </SwitchWrap>
            <Text mb="0">{provider}</Text>
          </SidebarItemWrapper>
        ))}
      </FlexContainer>
    </>
  );
}
