/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback, useMemo, useReducer, useState } from "react";
import { When } from "react-if";
import { ModalConfirm, theme } from "@nordcloud/gnui";
import { AccountV2Query } from "~/generated/graphql";
import {
  UniversalWrap,
  TableLoader,
  ReactTable,
  Pagination,
} from "~/components";
import { ERROR_TEXT } from "~/constants";
import { useCurrency, useDisclosure, useQueryState } from "~/hooks";
import { useRemoveAccount } from "~/hooks/graphql";
import { showError, showSuccess } from "~/services/toast";
import {
  generateActionConfirmText,
  generateActionSuccessText,
  getFirstItem,
  isNotEmpty,
  isNotNil,
  Maybe,
} from "~/tools";
import { AccountCredentialsUpdateSidebar } from "~/views/accounts/AccountDetails/AccountCredentialsUpdateSidebar/AccountCredentialsUpdateSidebar";
import { ACTION, initialState, reducer } from "../../accounts/reducer";
import { BillingDataSidebar } from "../BillingData";
import { useChargeTypes } from "../hooks";
import {
  CloudAccount,
  CloudAccountsQueryState,
  CloudAccountsResponse,
  CloudProviderType,
  Provider,
} from "../types";
import { AccountListColumns } from "./AccountListColumns/AccountListColumns";
import { AddNewAccountSidebar } from "./AddNewAccountSidebar/AddNewAccountSidebar";
import { CloudAccountFilterBox } from "./CloudAccountFilterBox";
import { TotalCostsByChargeTypeBar } from "./TotalCostsByChargeTypeBar";

type Props = {
  openFiltersSidebar: () => void;
  searchInput?: Maybe<string>;
  isLoading: boolean;
  data?: Maybe<CloudAccountsResponse>;
  movedToK8s?: number;
  movedToOc?: number;
  onSetSearchInput: (input: string) => void;
};

export function CloudAccountTab({
  isLoading,
  searchInput,
  onSetSearchInput,
  openFiltersSidebar,
  movedToK8s,
  movedToOc,
  data,
}: Props) {
  const {
    open: openBillingDataSidebar,
    close: closeBillingDataSidebar,
    isOpen: isOpenBillingDataSidebar,
  } = useDisclosure();

  const {
    open: openAddNewAccountSidebar,
    close: closeAddNewAccountSidebar,
    isOpen: isAddNewAccountSidebarOpen,
  } = useDisclosure();

  const {
    open: openCredentialsUpdateSteps,
    close: closeCredentialsUpdateSteps,
    isOpen: showCredentialsUpdateSteps,
  } = useDisclosure();
  const [accountToUpdate, setAccountToUpdate] = useState<
    AccountV2Query["accountV2"] | undefined
  >();

  const { state } = useQueryState<CloudAccountsQueryState>();
  const { provider, order, chargeType: queryChargeType } = state;
  const providerType = getFirstItem(data?.accounts ?? [])?.providerType;

  const { chargeTypes, isLoadingChargeTypes, chargeTypesError } =
    useChargeTypes(provider);

  const [reducerState, dispatch] = useReducer(reducer, initialState);

  const onRemoveHandler = useCallback((id: string, name: string) => {
    dispatch({ type: ACTION.REMOVE, payload: { id, name } });
  }, []);

  const onUpdateHandler = (account: CloudAccount) => {
    setAccountToUpdate(account as AccountV2Query["accountV2"]);
    openCredentialsUpdateSteps();
  };

  const closeUpdateCredentialsSidebar = () => {
    setAccountToUpdate(undefined);
    closeCredentialsUpdateSteps();
  };

  const { currency } = useCurrency();

  const columns = useMemo(
    () =>
      AccountListColumns({
        onRemoveHandler,
        onUpdateHandler,
        chargeTypes,
        provider,
        currency,
      }),
    [chargeTypes, onRemoveHandler, provider, currency]
  );

  const accountCount = data?.count ?? 0;
  const showPagination = accountCount > 0;

  const showSort = provider !== Provider.Vmware || isNotEmpty(chargeTypes);
  const showBillingDataButton =
    provider !== Provider.Hybrid &&
    provider !== Provider.Ibmcloud &&
    providerType &&
    ![
      CloudProviderType.Vmware,
      CloudProviderType.Openshift,
      CloudProviderType.Kubernetes,
    ].includes(providerType);

  const [removeAccount, { client }] = useRemoveAccount();

  const onDelete = async () => {
    try {
      await removeAccount({ nid: reducerState.tempOrgUnitData.nid ?? "" });
      showSuccess(
        generateActionSuccessText(
          `The ${reducerState.tempOrgUnitData.name ?? "no name"}`
        )()("removed")()
      );
      await client.refetchQueries({
        updateCache(cache) {
          cache.modify({
            fields: {
              accountsV2(_value, cacheProps) {
                return cacheProps.INVALIDATE as string;
              },
            },
          });
        },
      });
      closeModal();
    } catch {
      showError(ERROR_TEXT.default);
    }
  };

  const closeModal = () => {
    dispatch({ type: ACTION.CLOSE_MODAL });
  };

  return (
    <>
      <UniversalWrap
        errorProps={{ error: chargeTypesError }}
        loaderProps={{
          loading: isLoading || isLoadingChargeTypes,
          Component: Loader,
        }}
      >
        <CloudAccountFilterBox
          showSort={showSort}
          queryChargeType={queryChargeType}
          order={order}
          chargeTypes={chargeTypes}
          searchInput={searchInput}
          showBillingDataButton={showBillingDataButton}
          openFiltersSidebar={openFiltersSidebar}
          openBillingDataSidebar={openBillingDataSidebar}
          openAddNewAccountSidebar={openAddNewAccountSidebar}
          onSetSearchInput={onSetSearchInput}
        />
        <When condition={provider !== Provider.Vmware}>
          <TotalCostsByChargeTypeBar
            movedToK8s={movedToK8s}
            movedToOc={movedToOc}
          />
        </When>
        <ReactTable
          striped
          sort={false}
          columns={columns}
          data={data?.accounts || []}
        />
        <When condition={showPagination}>
          <Pagination count={accountCount} />
        </When>
      </UniversalWrap>
      <ModalConfirm
        isOpen={reducerState.isModalConfirmOpen}
        confirm={onDelete}
        actionLabel="Delete"
        contentLabel="Delete Account"
        onClose={closeModal}
      >
        {generateActionConfirmText(reducerState.tempOrgUnitData.name ?? "")(
          "delete"
        )()}
      </ModalConfirm>
      <BillingDataSidebar
        isOpen={isOpenBillingDataSidebar}
        close={closeBillingDataSidebar}
        provider={provider}
      />
      <AddNewAccountSidebar
        isOpen={isAddNewAccountSidebarOpen}
        close={closeAddNewAccountSidebar}
      />
      <When condition={isNotNil(accountToUpdate)}>
        <AccountCredentialsUpdateSidebar
          account={accountToUpdate}
          isOpen={showCredentialsUpdateSteps}
          close={closeUpdateCredentialsSidebar}
        />
      </When>
    </>
  );
}

function Loader() {
  return (
    <TableLoader
      gapY={10}
      gapX={6}
      css={{
        marginTop: theme.spacing.spacing08,
      }}
      rows={[
        {
          count: 8,
          height: 60,
          items: [
            {
              widthPercent: 4,
            },
            {
              widthPercent: 4,
            },
            {
              widthPercent: 43,
            },
            {
              widthPercent: 43,
            },
            {
              widthPercent: 6,
            },
          ],
        },
      ]}
    />
  );
}
